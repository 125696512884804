<template>
  <div class="a-title text-500 text-333 text-20">
    {{ title }}
  </div>

</template>

<script>
export default {
  name: 'SubTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .a-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #F2F2F2;
  }
</style>
