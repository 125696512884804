<template>
  <div class="school_fourth_box">
    <div class="text_right">
      <el-button type="primary" style="height: 32px;" @click="editStatus = true">编辑资料</el-button>
    </div>
    <el-form :model="createParam" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="结业证书" prop="title">
        <p class="text_val" v-if="!editStatus">hhh</p>
        <el-upload class="display-i-b" :action="uploadUrl" :data="dataObj" :on-change="onBeforeUpload"
          :on-success="onSuccessUpload" :file-list="fileList" list-type="picture" drag>
          <el-image class="a-pic-preview absolute-center" :src="createParam.image" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-upload>
        <p class="text_remark">请上传带有学校红章的空白结业证书模板，中间区域保持空白，尺寸为1500px*2000px</p>
      </el-form-item>
      <el-form-item label="文案" prop="title">
        <p class="text_val" v-if="!editStatus">hhh</p>
        <el-input v-else type="textarea" :rows="6" maxlength="120" show-word-limit v-model="createParam.title"></el-input>
      </el-form-item>
    </el-form>
    <div class="bom_btn" v-if="editStatus">
      <el-button @click="editStatus = false">取 消</el-button>
      <el-button type="primary" @click="tapConfirm">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'
export default {
  data () {
    return {
      editStatus: true,
      createParam: {
        image: '',
        title: ''
      },
      rules: {},
      fileList: [],
      uploadUrl: getEnv()[1] + 'file_upload',
      dataObj: { token: getToken() },
    }
  },
  methods: {
    onBeforeUpload () {

    },
    onSuccessUpload () {

    },
    tapConfirm () {
      
    }
  }
}
</script>

<style lang="scss">
.school_fourth_box{
  width: 100%;
  padding-top: 40px;
  .text_right{
    text-align: right;
    margin-bottom: 10px;
  }
  .el-textarea{
    width: 500px;
  }
  .text_val{
    margin: 0;
  }
  .a-pic-preview {
    width: 100%;
    height: 100%;
    color: #cccccc;
    font-size: 25px;
    border-radius: 6px;
    background: #F7F7F7;
    border: 1px solid #E7E7E7;
  }
  .bom_btn{
    width: 620px;
    text-align: center;
  }
  .el-upload-dragger {
    border: none !important;
    text-align: left !important;
  }
  .text_remark{
    margin: 0;
    color: #666;
    line-height: 20px;
  }
}
</style>
