import { render, staticRenderFns } from "./CourseList.vue?vue&type=template&id=791c35d0&scoped=true&"
import script from "./CourseList.vue?vue&type=script&lang=js&"
export * from "./CourseList.vue?vue&type=script&lang=js&"
import style0 from "./CourseList.vue?vue&type=style&index=0&id=791c35d0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "791c35d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\src\\centerschool\\trunk\\university\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('791c35d0')) {
      api.createRecord('791c35d0', component.options)
    } else {
      api.reload('791c35d0', component.options)
    }
    module.hot.accept("./CourseList.vue?vue&type=template&id=791c35d0&scoped=true&", function () {
      api.rerender('791c35d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/course-manager/components/CourseList.vue"
export default component.exports