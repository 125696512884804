<template>
  <div class="notice_box">
    <el-button type="primary" style="height: 32px;" @click="tapCreate">新建管理人员</el-button>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        fixed
        prop="nickName"
        label="姓名"
      />
      <el-table-column
        prop="sex"
        label="性别"
      >
        <template slot-scope="scope">
          <span>{{scope.row.sex ? scope.row.sex == 'MALE' ? '男' : '女' : ''}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
      />
      <el-table-column
        prop="courseCount"
        label="课程数量"
      />
      <!-- <el-table-column
        prop="peopleId"
        label="身份证号"
      /> -->
      <el-table-column
        prop="courseNum"
        label="管理员"
      >
        <template slot-scope="scope">
          <span>{{scope.row.role == 'ADMIN' ? '管理员' : scope.row.role == 'CLASS_TEACHER' ? '班主任' : '学校管理员'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="bindWxOpenId"
        label="是否已绑定微信"
      >
        <template slot-scope="scope">
          <span>{{scope.row.bindWxOpenId ? '已绑定' : '未绑定'}}</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="230"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="tapBindWX(scope.row)">绑定</el-button>
            <template v-if="roles[0] !== 'CLASS_TEACHER'">
              <el-button type="text" size="small" @click="tapEditNotice(scope.row)">修改</el-button>
              <el-button type="text" size="small" @click="tapDelNotice(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" />

    <!-- 创建，编辑公告 -->
    <el-dialog :title="createParam.id ? '编辑管理人员' : '新建管理人员'" :visible.sync="showCreate" width="500px">
      <el-form :model="createParam" :rules="rules" ref="form" label-width="120px">
        <el-form-item label="姓名" prop="nickName">
          <el-input v-model="createParam.nickName"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select class="width-100" v-model="createParam.sex" placeholder="请选择">
            <el-option label="男" value="MALE"></el-option>
            <el-option label="女" value="FEMALE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input v-model="createParam.phone"></el-input>
        </el-form-item>
        <!-- <el-form-item label="身份证号码" prop="peopleId">
          <el-input v-model="createParam.peopleId"></el-input>
        </el-form-item> -->
        <el-form-item label="权限" prop="role">
          <el-select :disabled="!!createParam.id" class="width-100" v-model="createParam.role" placeholder="请选择">
            <el-option label="管理员" value="ADMIN"></el-option>
            <el-option label="班主任" value="CLASS_TEACHER"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="后台登录密码" prop="password">
          <el-input v-model="createParam.password" type="password" :placeholder="createParam.id ? '为空，则不修改' : ''"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreate = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 绑定微信 -->
    <el-dialog title="绑定微信" :visible.sync="showBind" :before-close="onCloseBind" width="400px">
      <div class="admin_bind_wx_dialog">
        <img class="wx_img" :src="wxImg" alt="">
        <p v-if="bindState && bindState !== 'UNSCAN'" class="status_text">绑定成功</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'
import Pagination from '@/components/Pagination'
import { getAdminList, createAdmin, editAdmin, getAdminDetail, delAdmin, getBindCodeApi, getBindStateApi } from '@/api/administrator'
export default {
  name: 'Notice',
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        pageSize: 10
      },
      total: 0,
      dataList: [],
      listLoading: true,
      showCreate: false,
      createParam: {
        id: '',
        nickName: '',
        sex: '',
        phone: '',
        role: '',
        peopleId: '',
        password: '',
      },
      rules: {
        nickName: [
          { required: true, trigger: 'submit', message: '请输入姓名' }
        ],
        phone: [
          { required: true, trigger: 'submit', message: '请输入手机号' }
        ],
        role: [
          { required: true, trigger: 'submit', message: '请选择权限' }
        ],
        password: [
          { required: true, trigger: 'submit', validator: (rule, value, cb) => {
            if(!this.createParam.id && !this.createParam.password) {
              return cb(new Error('请输入密码'))
            }
            cb()
          }}
        ],
        peopleId: [
          { required: true, trigger: 'submit', message: '请输入身份证号' }
        ]
      },
      showBind: false,
      getState: null,
      bindState: '',
      wxImg: '',
    }
  },
  computed: {
    ...mapGetters([
      'school_id',
      'roles'
    ])
  },
  mounted () {
    this.getData()
    this.listLoading = false;
    if(this.$route.query.create) this.showCreate = true;
  },
  methods: {
    getData () {
      this.listLoading = true;
      getAdminList({ ...this.param }).then(res => {
        this.listLoading = false;
        this.dataList = res.result.content;
        this.total = res.result.totalElements
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
    },
    tapCreate () {
      for(let k in this.createParam) {
        this.createParam[k] = '';
      }
      this.showCreate = true;
    },
    tapConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if(!this.createParam.sex) this.createParam.sex = null; 
          if(this.createParam.id) {
            editAdmin({ ...this.createParam }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(res => {
              this.$message.error(res.errorMsg)
            });
          } else {
            createAdmin({ ...this.createParam }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(res => {
              this.$message.error(res.errorMsg)
            });
          }
        }
      })
    },
    tapDelNotice (row) {
      this.$confirm('您确定要删除此管理人员吗？', '删除管理人员',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAdmin({ id: row.id }).then(res => {
          this.$message.success('删除成功')
          this.getData()
        }).catch(res => {
          this.$message.error(res.errorMsg)
        });
      }).catch(() => { });
    },
    tapEditNotice (item) {
      getAdminDetail({ id: item.id }).then(res => {
        for(let k in this.createParam) {
          this.createParam[k] = res.result[k]
        }
        this.showCreate = true;
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    tapBindWX (item) {
      this.showBind = true;
      this.bindState = '';
      this.wxImg = `${getEnv()[1]}/school/adminstor/getbindqrcode?adminId=${item.id}&token=${getToken()}`
      this.getState = setInterval(() => {
        this.getBindState(item)
      }, 1000)
    },
    getBindState (item) {
      getBindStateApi({ adminId: item.id }).then(res => {
        this.bindState = res.result;
        if(res.result !== 'UNSCAN') {
          clearInterval(this.getState)
          setTimeout(() => {
            this.showBind = false
          }, 2000)
        }
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    onCloseBind () {
      clearInterval(this.getState)
      this.showBind = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.notice_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
</style>
<style lang="scss">
.admin_bind_wx_dialog{
  width: 100%;
  img{
    width: 100%;
    display: block;
  }
  .status_text{
    color: #FE9905;
    margin-top: 10px;
    text-align: center;
  }
}
</style>