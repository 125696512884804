<template>
  <div class="a-course-container">
    <div class="flex align-center" style="padding: 24px 0;">
      <div class="margin-right-30">当前大学：{{ school_name }}</div>
      <!-- <div>当前学期：
        <el-select v-model="curTerm.id" placeholder="请选择" style="width: 150px;" @change="changeTerm">
          <el-option
            v-for="item in termList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div> -->
    </div>
    <course-list v-if="curTerm.id" ref="mychild" :term-id="curTerm.id" />
  </div>
</template>

<script>
import CourseList from './components/CourseList'
import { termList, termCurrent } from '@/api/course-manager'
import { mapGetters } from 'vuex'
export default {
  name: 'CourseManager',
  components: {
    CourseList
  },
  data() {
    return {
      listQuery: {
        page: 0,
        count: 10
      },
      termList: [],
      curTerm: {
        id: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'school_name',
      'school_id'
    ])
  },
  created() {
    this.getTermCurrent()
    // this.getTermList()
  },
  methods: {
    getTermList() {
      termList({ schoolId: this.school_id, ...this.listQuery }).then(res => {
        this.termList = res.result.items
      })
    },

    getTermCurrent() {
      termCurrent({ schoolId: this.school_id }).then(res => {
        this.curTerm = res.result || {}
      })
    },

    changeTerm(termId) {
      this.$refs.mychild.getList(termId)
    }
  }

}
</script>

<style scoped>
  .a-course-container {
    margin: 0 24px;
  }

</style>
