import request from '@/utils/request'

export function getClassListApi(data) {
  return request({
    url: '/school/classes/list',
    params: data,
    type: 'get'
  })
}

export function classRemoveApi(data) {
  return request({
    url: '/school/classes/remove',
    data: data,
  })
}


export function exportData(data) {
  return request({
    url: '/school/classes/list/export',
    responseType: 'blob',
    params: data,
    type: 'get'
  })
}




