var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vip_exchange_box" },
    [
      _c(
        "div",
        { staticClass: "flex align-center flex-wrap" },
        [
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("发放日期：\n      "),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd"
                },
                on: { change: _vm.onChangeDate },
                model: {
                  value: _vm.distDate,
                  callback: function($$v) {
                    _vm.distDate = $$v
                  },
                  expression: "distDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("客户名称：\n      "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "请输入姓名" },
                on: { change: _vm.tapSearch },
                model: {
                  value: _vm.param.name,
                  callback: function($$v) {
                    _vm.$set(_vm.param, "name", $$v)
                  },
                  expression: "param.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("状态：\n      "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.tapSearch },
                  model: {
                    value: _vm.param.status,
                    callback: function($$v) {
                      _vm.$set(_vm.param, "status", $$v)
                    },
                    expression: "param.status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "" } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "未激活", value: "UN_ACTIVATION" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "使用中", value: "ACTIVATED" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "已过期", value: "EXPIRE" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "已作废", value: "DISABLE" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("会员卡编号：\n      "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "请输入手机号" },
                on: { change: _vm.tapSearch },
                model: {
                  value: _vm.param.code,
                  callback: function($$v) {
                    _vm.$set(_vm.param, "code", $$v)
                  },
                  expression: "param.code"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("会员卡兑换码：\n      "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "请输入手机号" },
                on: { change: _vm.tapSearch },
                model: {
                  value: _vm.param.secret,
                  callback: function($$v) {
                    _vm.$set(_vm.param, "secret", $$v)
                  },
                  expression: "param.secret"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("用户信息：\n      "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "请输入手机号" },
                on: { change: _vm.tapSearch },
                model: {
                  value: _vm.param.studentInfo,
                  callback: function($$v) {
                    _vm.$set(_vm.param, "studentInfo", $$v)
                  },
                  expression: "param.studentInfo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              staticStyle: { width: "104px", height: "32px" },
              on: { click: _vm.tapResettingSearch }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              staticStyle: { width: "104px", height: "32px" },
              on: { click: _vm.tapSearch }
            },
            [_vm._v("查看")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "margin-top-20",
          staticStyle: { width: "1624px" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "distTime", label: "发放日期" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "groupName", label: "客户名称" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "code", label: "会员卡编号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "secret", label: "会员卡兑换码" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === "UN_ACTIVATION"
                      ? _c("span", [_vm._v("未激活")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === "ACTIVATED"
                      ? _c("span", [_vm._v("使用中")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === "EXPIRE"
                      ? _c("span", [_vm._v("已过期")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === "DISABLE"
                      ? _c("span", [_vm._v("已作废")])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "studentName", label: "用户姓名" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "studentPhone", label: "用户手机号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "activeTime", label: "兑换日期" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "vipDays", label: "有效期" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.tapItemRemove(scope.row)
                          }
                        }
                      },
                      [_vm._v("作废")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0 && !_vm.listLoading,
            expression: "total>0 && !listLoading"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.param.page,
          pagesize: _vm.param.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.param, "page", $event)
          },
          "update:pagesize": function($event) {
            return _vm.$set(_vm.param, "pageSize", $event)
          },
          pagination: _vm.getData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }