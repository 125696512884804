<template>
  <div class="pay_order_box">
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">学期：
        <el-select v-model="param.termId" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option
            v-for="item in termList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">用户名称：
        <el-input v-model="param.studentName" placeholder="请输入姓名" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">用户手机号：
        <el-input v-model="param.phone" placeholder="请输入手机号" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">支付时间：
        <el-date-picker
          v-model="times"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="onChangeTime"
        >
        </el-date-picker>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">课程名称：
        <el-input v-model="param.course" placeholder="请输入课程名称" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div>订单状态：
        <el-select v-model="param.orderStatus" placeholder="请选择" class="flex-center margin-right-30 margin-bottom-20" @change="tapSearch">
          <el-option label="全部" value="ALL" />
          <el-option label="已生效" value="SUCCESS" />
          <el-option label="已退款" value="REFUNDED" />
        </el-select>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResetting">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
      <el-button class="margin-right-20 margin-bottom-20" type="primary" style="width: 104px; height: 32px;" @click="tapReturn">退款</el-button>
    </div>
    <div class="order_amount">
      <p>数据汇总：</p>
      <p>订单数：{{ amount.totalOrderCount || 0 }}</p>
      <p>应付金额：{{ amount.priceCount || 0 }}元</p>
      <p>优惠金额：{{ amount.couponFeeCount || 0 }}元</p>
      <p>实付金额：{{ amount.totalFeeCount || 0 }}元</p>
    </div>
    <el-table v-loading="listLoading" :data="orderList" highlight-current-row @current-change="onChangeRow" border style="width: 1624px;" >
      <el-table-column prop="createTime" label="支付时间" />
      <el-table-column prop="phone" label="用户手机号" />
      <el-table-column prop="studentName" label="用户姓名" />
      <el-table-column v-if="roles.includes('VIP_PERMISSION')" prop="orderType" label="订单类型" />
      <el-table-column prop="termName" label="购买学期" />
      <el-table-column prop="courseName" label="课程名称" />
      <el-table-column prop="totalFee" label="应付金额" />
      <el-table-column prop="couponFee" label="优惠金额" />
      <el-table-column prop="cashFee" label="实付金额" />
      <el-table-column prop="id" label="订单号" />
      <el-table-column prop="status" label="订单状态"> 
        <template slot-scope="scope">
          <span v-if="scope.row.status == 'SUCCESS'">已生效</span>
          <span v-else-if="scope.row.status == 'REFUNDED'">已退款</span>
        </template>
      </el-table-column>
      <el-table-column prop="wxTransactionId" label="微信支付流水号" />
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" />

    <!-- 退款 -->
    <el-dialog title="退款" :visible.sync="showReturn" width="500px">
      <template v-if="selectRow">
        <div class="flex pay_order_row"><p>课程名称</p><p>{{selectRow.courseName}}</p></div> 
        <div class="flex pay_order_row"><p>用户姓名</p><p>{{selectRow.studentName}}</p></div> 
        <div class="flex pay_order_row"><p>用户手机号  </p><p>{{selectRow.phone}}</p></div> 
        <div class="flex pay_order_row"><p>实付金额</p><p>{{selectRow.cashFee}}</p></div> 
      </template>
      <el-form :model="returnParam" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="退款金额" prop="yuan">
          <el-input v-model="returnParam.yuan"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="reason">
          <el-input type="textarea" :rows="4" v-model="returnParam.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showReturn = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Pagination from '@/components/Pagination'
import { getOrderList, getTermList, refundOrder } from '@/api/pay-order'
export default {
  name: 'PayOrder',
  components: {
    Pagination
  },
  data() {
    return {
      param: {
        termId: '1',
        page: 0,
        count: 10,
        studentName: '',
        phone: '',
        course: '',
        timeBegin: '',
        timeEnd: '',
        orderStatus: 'ALL'
      },
      times: [],
      termList: [],

      total: 0,
      amount: {},
      orderList: [],
      listLoading: true,
      // 退款
      selectRow: {},
      showReturn: false,
      returnParam: {
        yuan: '',
        reason: ''
      },
      rules: {
        yuan: [
          { required: true, trigger: 'submit', message: '请输入退款金额' }
        ],
        reason: [
          { required: true, trigger: 'submit', message: '请输入备注' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'school_id',
      'roles'
    ])
  },
  created() {
    Promise.all([
      this.getOptions()
    ]).then(res => {
      if(this.termList.length) {
        this.param.termId = this.termList[0].id;
        this.getData()
      } else {
        this.listLoading = false;
      }
    })
  },
  methods: {
    getData() {
      this.listLoading = true
      let params = {}
      for (const k in this.param) {
        if (this.param[k] !== '') params[k] = this.param[k]
      }
      getOrderList({ schoolId: this.school_id, ...params }).then(res => {
        this.listLoading = false;
        this.amount = res.result.statistical
        this.orderList = res.result.items
        this.total = res.result.total
      })
    },
    getOptions() {
      return new Promise((resolve, reject) => {
        getTermList({ schoolId: this.school_id, page: 0, count: 100 }).then(res => {
          this.termList = res.result.items;
          resolve()
        })
      })
    },
    onChangeTime(val) {
      if (val) {
        this.param.timeBegin = val[0]
        this.param.timeEnd = val[1]
      }
      this.tapSearch()
    },
    tapSearch () {
      this.param.page = 0;
      this.getData()
    },
    tapResetting() {
      for (const k in this.param) {
        if (k !== 'page' && k !== 'count' && k!== 'termId') {
          this.param[k] = ''
        }
      }
      this.times = [];
      this.param.page = 0;
      this.param.orderStatus = 'ALL';
      this.getData()
    },
    onChangeRow (row) {
      this.selectRow = row;
    },
    tapReturn () {
      console.log(this.selectRow)
      if(!this.selectRow || !this.selectRow.id) {
        this.$message.warning('请选中要退款的订单')
        return false;
      }
      this.showReturn = true;
    },
    tapConfirm() {
      if(this.returnParam.yuan > this.selectRow.cashFee) {
        this.$message.error('退款金额大于实付金额')
        return false;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          let param = {};
          Object.assign(param, this.returnParam);
          // param.refundNo = this.selectRow.refundNo;
          param.wxTransactionId = this.selectRow.wxTransactionId;
          refundOrder(param).then(res => {
            if(res.code == 0) {
              this.showReturn = false;
              this.returnParam.yuan = '';
              this.returnParam.reason = '';
              this.$message.success('退款成功')
              this.getData()
            } else {
              this.$message.error(res.errorMsg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pay_order_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
.order_amount{
  width: 100%;
  height: 50px;
  display: flex;
  font-weight: 700;
  align-items: center;
  p{
    margin: 0 40px 0 0;
  }
}
.pay_order_row{
  width: 100%;
  p:first-child{
    width: 100px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    padding-right: 12px;
  }
}
</style>
