import request from '@/utils/request'

export function courseCreate(data) {
  return request({
    url: '/course_create',
    data: data
  })
}

export function courseList(data) {
  return request({
    url: '/course_list',
    params: data
  })
}

export function courseListNew(params) {
  return request({
    url: '/school/course/list',
    params,
    type: 'get'
  })
}

export function teacherList(data) {
  return request({
    url: '/teacher_list',
    params: data
  })
}

export function getCourse(data) {
  return request({
    url: '/course',
    type: 'get',
    params: data
  })
}

export function courseDel(data) {
  return request({
    url: '/course_del',
    params: data
  })
}

export function courseUpdate(data) {
  return request({
    url: '/course_update',
    data: data
  })
}

export function teacherCreate(data) {
  return request({
    url: '/teacher_create',
    params: data
  })
}

export function teacherGet(data) {
  return request({
    url: '/teacher',
    params: data
  })
}

export function teacherDel(data) {
  return request({
    url: '/teacher_del',
    params: data
  })
}

export function lessonDel(data) {
  return request({
    url: '/lesson_del',
    params: data
  })
}

export function teacherUpdate(data) {
  return request({
    url: '/teacher_update',
    params: data
  })
}

export function qrcodeTeacherBind(params) {
  return request({
    url: '/qrcode_teacher_bind',
    params
  })
}

export function termList(params) {
  return request({
    url: '/term_list',
    params
  })
}

export function termCurrent(params) {
  return request({
    url: '/term_current',
    params
  })
}

export function termCreate(params) {
  return request({
    url: '/term_create',
    params
  })
}

export function termUpdate(params) {
  return request({
    url: '/term_update',
    params
  })
}

export function getTerm(params) {
  return request({
    url: '/term',
    params
  })
}

export function termDel(params) {
  return request({
    url: '/term_del',
    params
  })
}

export function coursePriceList(params) {
  return request({
    url: '/course_custom_price',
    type: 'get',
    params
  })
}

export function getVideoConfigApi(params) {
  return request({
    url: '/school/course/videocredential',
    type: 'get',
    params
  })
}


export function getCopyrightSchoolList(params) {
  return request({
    url: '/school/course/copyrightschoollist',
    type: 'get',
    params
  })
}