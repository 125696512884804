<template>
  <div class="a-container">
    <!-- <sub-title title="新建学期" /> -->
    <!-- <div class="margin-top-30"> -->
      <el-form ref="form" :model="form" :rules="rules" label-width="110px" label-position="left">
        <el-form-item label="学期名称" prop="name">
          <el-input
            ref="name"
            v-model="form.name"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="开始结束时间" prop="time">
          <el-date-picker
            class="a-input"
            v-model="form.time"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="是否启用" prop="enable">
          <el-radio-group v-model="form.enable">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="招生简章" prop="enrollmentIntro" style="z-index:888;">
          <div id="editor"></div>
        </el-form-item>
        <el-button v-if="!edit" type="primary" class="a-button" style="margin-left: 80px;" @click="termCreate">创建</el-button>
        <el-button v-else type="primary" class="a-button" style="margin-left: 80px;" @click="termUpdate">编辑</el-button>
        <el-button class="a-button" @click="backPage(false)">取消</el-button>
      </el-form>
    <!-- </div> -->
  </div>
</template>

<script>
import E from "wangeditor"
import { getEnv } from '@/utils/index'
import { getToken } from '@/utils/auth'
import SubTitle from '@/components/SubTitle/index'
import { termCreate, termUpdate, getTerm } from '@/api/course-manager'
import { validNotEmpty } from '@/utils/validate'
export default {
  name: 'TermCreate',
  components: {
    SubTitle
  },
  props: {
    show: Boolean,
    termId: Number || Object
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!validNotEmpty(value)) {
        callback(new Error('请输入大学名称'))
      } else {
        callback()
      }
    }
    const validateTime = (rule, value, callback) => {
      if (!validNotEmpty(value)) {
        callback(new Error('请选择时间'))
      } else {
        callback()
      }
    }
    return {
      form: {
        enable: false
      },
      rules: {
        name: [
          { required: true, trigger: 'submit', validator: validateName }
        ],
        time: [
          { required: true, trigger: 'submit', validator: validateTime }
        ]
      },
      edit: false,
      editor: null,
    }
  },
  watch: {
    termId (val) {
      if(val) {
        this.edit = true
        this.getTerm()
      } else {
        for(let k in this.form) {
          this.$set(this.form, k, '')
        }
        this.form.enable = false;
        this.editor.txt.html('')
      }
    },
    show (val) {
      if(val && !this.termId) {
        for(let k in this.form) {
          this.$set(this.form, k, '')
        }
        this.form.enable = false;
        this.editor.txt.html('')
      }
    }
  },
  mounted() {
    if (this.termId) {
      this.edit = true
      this.getTerm()
    }
    this.renderEdit()
  },
  methods: {
    termCreate() {
      this.$refs.form.validate(valid => {
        if (valid) {
          termCreate(this.handleForm(this.form)).then(() => {
            this.$message.success('设置成功')
            this.backPage(true)
          })
        }
      })
    },

    handleForm(form) {
      const data = JSON.parse(JSON.stringify(form))
      if (data.time) {
        data.dayBegin = data.time[0]
        data.dayEnd = data.time[1]
        delete data.time
      }
      data.enrollmentIntro = this.editor.txt.html();
      data.schoolId = Number(this.$route.query.schoolId)
      return data
    },

    handleData(obj) {
      const data = JSON.parse(JSON.stringify(obj))
      const arr = []
      arr.push(data.dayBegin, data.dayEnd)
      data.time = arr
      return data
    },

    termUpdate() {
      termUpdate(this.handleForm(this.form)).then(res => {
        this.backPage(true)
        this.$message.success('修改成功')
      })
    },

    getTerm() {
      getTerm({ id: this.termId }).then(res => {
        this.form = this.handleData(res.result)
        this.renderEdit()
        const arr = []
        arr.push(res.result.dayBegin, res.result.dayEnd)
        this.form.time = arr
      })
    },

    renderEdit() {
      if(!this.editor) {
        this.editor = new E("#editor")
        this.editor.config.excludeMenus = [
          'code'
        ]
        // 上传图片
        this.editor.config.height = 150;
        this.editor.config.showLinkImg = false;
        this.editor.config.uploadImgServer = getEnv()[1] + 'file/upload/img';
        this.editor.config.uploadImgParams = { token: getToken() }
        this.editor.config.uploadFileName = 'file'
        this.editor.config.uploadImgHooks = {
          customInsert: function(insertImgFn, result) {
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            insertImgFn(result.result.url)
          }
        }

        this.editor.create()
        this.editor.txt.html(this.form.enrollmentIntro || '') // 重新设置编辑器内容
      }
    },

    backPage(status) {
      // this.$router.go(-1)
      this.$emit('emitCancel', status)
    }

  }

}
</script>

<style>
 .el-form-item__content{
    line-height: 40px;
    position: relative;
    font-size: 14px;
    z-index: 888;
  }
</style>

<style scoped>
  .a-input {
    width: 584px;
  }

  .a-avatar {
    width: 90px;
    height: 90px;
    background: #F7F7F7;
    border: 1px solid #E7E7E7;
    border-radius: 50%;
    margin-top: 16px;
  }

  .a-button {
    width: 132px;
    height: 32px;
  }

  /* /deep/ .el-upload-dragger {
    border: none !important;
    text-align: left !important;
    width: auto !important;
    height: auto !important;
  } */

  .el-form-item {
    margin-bottom: 32px;
  }
</style>
