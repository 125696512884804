var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-course-create" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" }
        },
        [
          _c(
            "div",
            { staticClass: "half_row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "教师姓名", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "a-input",
                    attrs: {
                      type: "text",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "担任科目" } },
                [
                  _c("el-input", {
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.courseSubject,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "courseSubject", $$v)
                      },
                      expression: "form.courseSubject"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "half_row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "身份证号" } },
                [
                  _c("el-input", {
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.peopleId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "peopleId", $$v)
                      },
                      expression: "form.peopleId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "毕业院校" } },
                [
                  _c("el-input", {
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.graduateSchool,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "graduateSchool", $$v)
                      },
                      expression: "form.graduateSchool"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "half_row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "教师手机号" } },
                [
                  _c("el-input", {
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "工作单位" } },
                [
                  _c("el-input", {
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.organization,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "organization", $$v)
                      },
                      expression: "form.organization"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "教师微信号", prop: "wxNo" } },
            [
              _c("el-input", {
                staticClass: "a-input",
                attrs: { type: "text" },
                model: {
                  value: _vm.form.wxNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "wxNo", $$v)
                  },
                  expression: "form.wxNo"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "教师照片" } }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      action: _vm.uploadUrl,
                      data: _vm.dataObj,
                      "on-success": _vm.handleSuccess,
                      "on-change": _vm.handleChangePic,
                      "file-list": _vm.fileList,
                      "before-upload": _vm.beforeUpload,
                      "show-file-list": false,
                      "list-type": "picture",
                      drag: ""
                    },
                    model: {
                      value: _vm.form.body,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "body", $$v)
                      },
                      expression: "form.body"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          { staticStyle: { width: "120px", height: "36px" } },
                          [_vm._v("上传图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "text-999 text-12",
                            staticStyle: { "margin-left": "8px" }
                          },
                          [_vm._v("建议上传竖版半身照，推荐尺寸342*448px")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-image", {
                      staticClass: "a-pic-preview",
                      attrs: { src: _vm.form.body, fit: "contain" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "margin-right-20",
                    staticStyle: {
                      "margin-left": "130px",
                      "font-weight": "600"
                    }
                  },
                  [_vm._v("教师头像")]
                ),
                _vm._v(" "),
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      action: _vm.uploadUrl,
                      data: _vm.dataObj,
                      "on-success": _vm.handleSuccess1,
                      "on-change": _vm.handleChangePic,
                      "file-list": _vm.fileList,
                      "before-upload": _vm.beforeUpload,
                      "show-file-list": false,
                      "list-type": "picture",
                      drag: ""
                    },
                    model: {
                      value: _vm.form.head,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "head", $$v)
                      },
                      expression: "form.head"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          { staticStyle: { width: "120px", height: "36px" } },
                          [_vm._v("上传图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "text-999 text-12",
                            staticStyle: { "margin-left": "8px" }
                          },
                          [_vm._v("请上传剪裁好的圆形照片")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-image", {
                      staticClass: "a-pic-preview-avatar round",
                      attrs: { src: _vm.form.head, fit: "contain" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "教师标签" } },
            [
              _c("el-input", {
                staticClass: "a-input",
                attrs: { type: "text", maxlength: "10", "show-word-limit": "" },
                model: {
                  value: _vm.form.tag,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tag", $$v)
                  },
                  expression: "form.tag"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "text-999 text-12",
                  staticStyle: { "margin-left": "6px" }
                },
                [_vm._v("一句话简短介绍教师，例如“北师大文学院副教授”")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "详细介绍" } },
            [
              _c("el-input", {
                staticStyle: { width: "376px", "min-height": "140px" },
                attrs: {
                  type: "textarea",
                  placeholder: "请输入详细介绍",
                  maxlength: "500",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.introduce,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "introduce", $$v)
                  },
                  expression: "form.introduce"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticStyle: { padding: "34px 0" } },
            [
              !_vm.edit
                ? _c(
                    "el-button",
                    {
                      staticClass: "a-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.teacherCreate }
                    },
                    [_vm._v("创建")]
                  )
                : _c(
                    "el-button",
                    {
                      staticClass: "a-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.teacherUpdate }
                    },
                    [_vm._v("编辑")]
                  ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "a-button", on: { click: _vm.backPage } },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }