import request from '@/utils/request'

export function studentsList(data) {
  return request({
    url: '/school/student/all',
    params: data,
    type: 'get'
  })
}

export function setWhitelist(data) {
  return request({
    url: '/set_whitelist',
    params: data
  }, true)
}

export function whiteList(data) {
  return request({
    url: '/whitelist',
    params: data
  })
}


export function setVerify(data) {
  return request({
    url: '/verify_student',
    params: data,
    type: 'get'
  })
}

export function exportData(data) {
  return request({
    url: '/school/student/all/export',
    responseType: 'blob',
    params: data,
    type: 'get'
  })
}

export function getStudentInfo(params) {
  return request({
    url: '/school/student/get',
    params: params,
    type: 'get'
  })
}

export function editStudentInfo(data) {
  return request({
    url: '/school/student/editinfo',
    data: data
  })
}

export function getSystemOptions(params) {
  return request({
    url: '/school/manage/getstudentinfosetting',
    params: params,
    type: 'get'
  })
}




