import request from '@/utils/request'

export function getNoticeList(params) {
  return request({
    url: '/notice_list',
    params
  }, true)
}

export function createNotice(params) {
  return request({
    url: '/notice_create',
    params
  }, true)
}

export function editNotice(params) {
  return request({
    url: '/notice_update',
    params
  }, true)
}

export function delNotice(params) {
  return request({
    url: '/notice_del',
    params
  }, true)
}