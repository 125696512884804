<template>
  <div class="school_box">
    <el-tabs v-model="activeName">
      <el-tab-pane label="联系方式" name="1"><FirstTab :schoolInfo="schoolInfo" /></el-tab-pane>
      <el-tab-pane label="学校简介" name="2"><SecondTab :schoolInfo="schoolInfo" /></el-tab-pane>
      <el-tab-pane label="注册协议" name="3"><ThirdTab :schoolInfo="schoolInfo" /></el-tab-pane>
      <el-tab-pane label="审核设置" name="4"><FourthTab :schoolInfo="schoolInfo" /></el-tab-pane>
      <el-tab-pane label="教务设置" name="5"><FifthTab :schoolInfo="schoolInfo" /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FirstTab from './components/FirstTab'
import SecondTab from './components/SecondTab'
import ThirdTab from './components/ThirdTab'
import FourthTab from './components/FourthTab'
import FifthTab from './components/FifthTab'
import { getSchoolInfo } from '@/api/school-info'
export default {
  name: 'SchoolInfo',
  data () {
    return {
      activeName: '1',
      schoolInfo: {},
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      getSchoolInfo().then(res => {
        this.schoolInfo = res.result;
      })
    }
  },
  components: {
    FirstTab,
    SecondTab,
    ThirdTab,
    FourthTab,
    FifthTab
  }
}
</script>

<style lang="scss" scoped>
.school_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
</style>