<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <span class="sidebar-title">{{name}}</span>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <span class="sidebar-title">{{name}}</span>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      name: this.$store.state.user.school_name,
      logo: this.$store.state.user.school_logo
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 59px;
  line-height: 59px;
  background: #FE9905;
  text-align: left;
  overflow: hidden;
  border-bottom: 1px solid #FEAB37;
  margin-bottom: 24px;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    padding-left: 20px;

    & .sidebar-logo {
      width: auto;
      height: 21px;
      vertical-align: middle;
    }

    & .sidebar-title {
      display: inline-block;
      margin-left: 10px;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-left: 10px;
    }
  }
}
</style>
