<template>
  <div class="school_tab_box">
    <div class="text_right">
      <el-button type="primary" style="height: 32px;" @click="editStatus = true">编辑资料</el-button>
    </div>
    <div v-if="editStatus" class="margin-bottom">
      <div id="editor"></div>
    </div>
    <div v-else class="content_box" v-html="schoolInfo.intro"></div>
    <div class="center_btn" v-if="editStatus">
      <el-button @click="editStatus = false">取 消</el-button>
      <el-button type="primary" @click="tapConfirm">确 定</el-button>
    </div>
  </div>
</template>

<script>
import E from "wangeditor"
import { getEnv } from '@/utils/index'
import { getToken } from '@/utils/auth'
import { saveSchoolIntro } from '@/api/school-info'
export default {
  props: {
    schoolInfo: Object
  },
  data () {
    return {
      editStatus: false,
      editor: null,
    }
  },
  watch: {
    editStatus (val) {
      if(val) {
        this.$nextTick(() => {
          this.renderData()
        })
      } else {
        this.editor = null;
      }
    }
  },
  mounted () {

  },
  methods: {
    renderData() {
      if(!this.editor) {
        this.editor = new E("#editor")
        this.editor.config.excludeMenus = [
          'code'
        ]
        // 上传图片
        this.editor.config.showLinkImg = false;
        this.editor.config.uploadImgServer = getEnv()[1] + 'file/upload/img';
        this.editor.config.uploadImgParams = { token: getToken() }
        this.editor.config.uploadFileName = 'file'
        this.editor.config.uploadImgHooks = {
          customInsert: function(insertImgFn, result) {
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            insertImgFn(result.result.url)
          }
        }

        this.editor.create()
        this.editor.txt.html(this.schoolInfo.intro || '') // 重新设置编辑器内容
      }
    },
    tapConfirm () {
      saveSchoolIntro({ intro: this.editor.txt.html(), token: getToken() }).then(res => {
        this.$parent.$parent.$parent.getData()
        this.$message.success('保存成功')
        this.editStatus = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.school_tab_box{
  width: 100%;
  .text_right{
    text-align: right;
    margin-bottom: 10px;
  }
  .center_btn{
    width: 100%;
    text-align: center;
  }
  .content_box{
    width: 100%;
  }
}
</style>