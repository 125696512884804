import { filterEmoji } from '@/utils/index'

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validNotEmpty(str = '') {
  return !!str
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validPassword(str = '') {
  return !!str && str.trim().length >= 6
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validName(str = '') {
  return !!filterEmoji(str)
}

/**
 * @param {string} val
 * @returns {Boolean}
 */
const isPic = function(val) {
  const types = ['png', 'gif', 'jpeg', 'jpg']
  return types.indexOf(val.substring(val.lastIndexOf('.') + 1).toLowerCase()) !== -1
}

/**
 * @param {Array} list
 * @returns {Boolean}
 */
export function hasGoodsPic(list = []) {
  return list.some(item => isPic(item))
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function hasPic(str = '') {
  return !!str
}
