<template>
  <div class="pay_order_box">
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">学期：
        <el-select v-model="param.termId" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option label="全部" value="" />
          <el-option
            v-for="item in termList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">课程名称：
        <el-input v-model="param.courseName" placeholder="请输入课程名称" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">用户信息：
        <el-input v-model="param.studentInfo" placeholder="请输入用户信息：" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">任课老师信息：
        <el-input v-model="param.teacherInfo" placeholder="请输入任课老师信息" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div>班主任：
        <el-select v-model="param.adminId" placeholder="请选择"  style="width: 150px;" @change="tapSearch" class="flex-center margin-right-30 margin-bottom-20">
          <el-option label="全部" value="" />
          <el-option
            v-for="item in adminList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          />
        </el-select>
      </div>
      <div>学员来源：
        <el-select v-model="param.source" placeholder="请选择"  style="width: 150px;" @change="tapSearch" class="flex-center margin-right-30 margin-bottom-20">
          <el-option label="全部" value="" />
          <el-option label="免费" value="FREE" />
          <el-option label="支付学员" value="PAYMENT" />
          <el-option label="白名单" value="WHITE_LIST" />
          <template v-if="roles.includes('VIP_PERMISSION')">
            <el-option label="会员免费" value="VIP_FREE" />
            <el-option label="会员支付" value="VIP_PAY" />
          </template>
        </el-select>
      </div>
      <div>学员状态：
        <el-select v-model="param.status" placeholder="请选择"  style="width: 150px;" @change="tapSearch" class="flex-center margin-right-30 margin-bottom-20">
          <el-option label="全部" value="" />
          <el-option label="报名" value="SIGNED" />
          <el-option label="移除" value="REMOVE" />
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">所属学校：
        <el-select v-model="param.copyrightSchoolId" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option label="全部" value="" />
          <el-option
            v-for="item in copyrightSchoolList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResetting">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapExport">导出</el-button>
      <!-- <el-button class="margin-right-20 margin-bottom-20" type="primary" style="height: 32px;" >批量结业审核</el-button> -->
    </div>
    <el-table v-loading="listLoading" :data="dataList"  @selection-change="onChangeRow" border style="width: 1624px;" >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="termName" label="学期" />
      <el-table-column prop="courseName" label="课程名称" />
      <el-table-column prop="studentName" label="学员姓名" />
      <el-table-column prop="studentPhone" label="学员手机号" />
      <el-table-column prop="teacherName" label="任课老师" />
      <el-table-column prop="teacherPhone" label="任课老师手机号" />
      <el-table-column prop="classAdminName" label="班主任" />
      <el-table-column prop="source" label="来源" />
      <el-table-column prop="status" label="用户状态" />
      <!-- <el-table-column prop="status" label="结业状态"> 
        <template slot-scope="scope">
          <span v-if="scope.row.status == 'SUCCESS'">已生效</span>
          <span v-else-if="scope.row.status == 'REFUNDED'">已退款</span>
        </template>
      </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status != '已移除'" type="text" size="small" @click="tapDelItem(scope.row)">移除</el-button>
          <!-- <el-button type="text" size="small" @click="tapFinishItem(scope.row)">结业审核</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.pageSize" @pagination="getData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import Pagination from '@/components/Pagination'
import { getTermList } from '@/api/pay-order'
import { exportData } from '@/api/class'
import { openDownload } from '@/utils/index'
import { getAdminList } from '@/api/administrator'
import { getClassListApi, classRemoveApi } from '@/api/class'
import { getCopyrightSchoolList } from '@/api/course-manager'

export default {
  components: {
    Pagination
  },
  data() {
    return {
      param: {
        page: 0,
        pageSize: 10,
        termId: '',
        copyrightSchoolId: '',
        courseName: '',
        studentInfo: '',
        teacherInfo: '',
        adminId: '',
        source: '',
        status: '',
      },
      total: 0,
      adminList: [],
      termList: [],
      dataList: [],
      copyrightSchoolList: [],
      listLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'school_id',
      'roles'
    ])
  },
  created() {
    // Promise.all([
    //   this.getOptions()
    // ]).then(res => {
    //   if(this.termList.length) {
    //     this.param.termId = this.termList[0].id;
    //     this.getData()
    //   } else {
    //     this.listLoading = false;
    //   }
    // })
  },
  mounted () {
    this.getData()
    this.getOptions()
  },
  methods: {
    getData() {
      this.listLoading = true
      let params = {}
      for (const k in this.param) {
        if (this.param[k] !== '') params[k] = this.param[k]
      }
      getClassListApi({ ...params }).then(res => {
        this.listLoading = false;
        this.dataList = res.result.content
        this.total = res.result.totalElements
      })
    },
    tapSearch() {
      this.param.page = 0
      this.getData()
    },
    tapExport() {
      let params = {}
      for (const k in this.param) {
        if (this.param[k] !== '') params[k] = this.param[k]
      }
      exportData({ ...params }).then(res => {
        openDownload(res, '班级列表.xlsx')
      })
    },
    getOptions() {
      getAdminList({ page: 0, pageSize: 1000, role:'CLASS_TEACHER' }).then(res => {
        this.adminList = res.result.content
      }).catch(res => {
        this.$message.error(res.errorMsg)
      })
      // return new Promise((resolve, reject) => {
        getTermList({ schoolId: this.school_id, page: 0, count: 100 }).then(res => {
          this.termList = res.result.items
          // resolve()
        })
      // })

      getCopyrightSchoolList().then(res => {
          this.copyrightSchoolList = res.result
          // resolve()
        })
    },
    tapResetting() {
      for (const k in this.param) {
        if (k !== 'page' && k !== 'pageSize') {
          this.param[k] = ''
        }
      }
      this.tapSearch()
    },
    onChangeRow (row) {
      this.selectRow = row;
    },
    tapDelItem (item) {
      this.$confirm('确定要移除此学员吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        classRemoveApi({ studentId: item.studentId, courseId: item.courseId, token: getToken() }).then(res => {
          this.$message.success('操作成功')
          this.getData()
        })
      }).catch(() => { });
    },
    tapFinishItem (item) {

    },
  }
}
</script>

<style lang="scss" scoped>
.pay_order_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
.pay_order_row{
  width: 100%;
  p:first-child{
    width: 100px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    padding-right: 12px;
  }
}
</style>
