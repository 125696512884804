<template>
  <div class="category_box">
    <el-button type="primary" style="height: 32px;" @click="tapCreate">新建一级类目</el-button>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      row-key="id"
      class="margin-top-20"
      style="width: 1624px;"
      :tree-props="{children: 'child'}">
    >
      <!-- <el-table-column
        prop="sort"
        label="排序"
      /> -->
      <el-table-column
        prop="name"
        label="一级类目名称"
      />
      <el-table-column
        prop="intro"
        label="简介"
      />
      <el-table-column
          label="操作"
          width="280"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="tapDetailItem(scope.row)">查看二级类目</el-button>
            <el-button type="text" size="small" @click="tapEditItem(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="tapDelItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
    </el-table>
    <!-- <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" /> -->
    <!-- detail -->

    <el-drawer class="white_list_drawer" title="二级类目" :visible.sync="showDetail" size="90%">
      <el-button type="primary" style="height: 32px;" @click="tapCreate(2)">新建二级类目</el-button>
      <el-table
        :data="detailList"
        border
        class="margin-top-20"
        style="width: 1624px;"
      >
        <el-table-column
          prop="parentName"
          label="一级类目名称"
        />
        <el-table-column
          prop="name"
          label="二级类目名称"
        />
        <el-table-column
          prop="intro"
          label="简介"
        />
        <el-table-column
          prop="courseNum"
          label="课程数量"
        />
        <el-table-column
            label="操作"
            width="200"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="tapEditItem(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="tapDelItem(scope.row)">删除</el-button>
            </template>
          </el-table-column>
      </el-table>
    </el-drawer>

    <!-- 创建，编辑公告 -->
    <el-dialog :title="createParam.id ? '编辑类目' : '新建类目'" :visible.sync="showCreate" width="630px">
      <el-form :model="createParam" :rules="rules" ref="form" label-width="120px">
        <el-form-item label="名称" multiple collapse-tags prop="name">
          <el-input v-model="createParam.name" placeholder="最多6个字" maxlength="6"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="intro">
          <el-input type="textarea" :rows="4" v-model="createParam.intro" placeholder="最多200字"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreate = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import Sortable from 'sortablejs'
import Pagination from '@/components/Pagination'
import { getCateList, addCateItem, editCateItem, delCateItem } from '@/api/cate'
export default {
  name: 'WhiteList',
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        count: 10
      },
      total: 0,
      dataList: [],
      fileList: [],
      termList: [],
      courseList: [],
      listLoading: true,
      showCreate: false,
      editParentId: '',
      createParam: {
        id: '',
        name: '',
        intro: '',
        parentId: '',
      },
      rules: {
        name: [
          { required: true, trigger: 'submit', message: '请填写类目名称' }
        ],
      },
      // detail
      token: getToken(),
      showDetail: false,
      detailList: [],
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getData()
    // this.handleRowDrop()
  },
  methods: {
    getData (type) {
      this.listLoading = true;
      getCateList({ }).then(res => {
        this.listLoading = false;
        this.dataList = res.result;
        // this.total = res.result.total
        if(type == 2) {
          // 更新二级类目
          let idx = res.result.findIndex(item => item.id == this.editParentId);
          this.tapDetailItem(res.result[idx]);
        }
      })
    },
    handleRowDrop() {
      const el = document.querySelector('.el-table__body-wrapper tbody');
      Sortable.create(el, {
        onEnd: e => {
          console.log(e)
        }
      })
    },
    tapCreate (type) {
      for(let k in this.createParam) {
        this.createParam[k] = '';
      }
      if(type == 2) this.createParam.parentId = this.editParentId;
      this.showCreate = true;
    },
    tapConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          var params = { ...this.createParam, token: this.token };
          if(!params.parentId) delete params.parentId;
          if(this.createParam.id) {
            editCateItem(params).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData(params.parentId ? 2 : null)
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          } else {
            delete params.id;
            addCateItem(params).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData(params.parentId ? 2 : null)
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          }
        }
      })
    },
    tapEditItem (item) {
      for(let k in this.createParam) {
        if(item[k]) {
          this.createParam[k] = item[k];
        } else {
          this.createParam[k] = '';
        }
      }
      this.showCreate = true;
    },
    tapDelItem (item) {
      this.$confirm('确认要删除类目吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCateItem({ id: item.id, token: this.token }).then(res => {
          this.$message.success('操作成功')
          this.showCreate = false;
          this.getData(item.parentId ? 2 : null)
        }).catch(err => {
          this.$message.error(err.errorMsg)
        })
      }).catch(() => { });
    },
    tapItemStatus (row) {
      let param = { enabled: !row.enabled, id: row.id }
      statusWhiteList(param).then(res => {
        this.$message.success('操作成功')
        this.getData()
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    tapDetailItem (item) {
      this.editParentId = item.id;
      this.detailList = item.children.map(m => {
        m.parentId = item.id;
        m.parentName = item.name;
        return m;
      });
      this.showDetail = true;
    }
  },
}
</script>

<style lang="scss" scoped>
.category_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
 .dialog_radio_row{
   .el-input{
     width: 120px;
     margin-left: 20px;
   }
   margin-bottom: 10px;
   &:last-child{
     margin-bottom: 0;
   }
 }
 /deep/.white_list_drawer{
   .el-drawer__header{
     margin-bottom: 20px!important;
   }
   .el-drawer__body{
     padding: 0 20px 20px;
     overflow: auto;
   }
 }
</style>