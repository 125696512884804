<template>
  <div class="count_data_box">
    <div class="search_box">
      <el-select v-model="paramTerm.termId" @change="onChangeTerm">
        <template v-for="item in termList">
          <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
        </template>  
      </el-select>
    </div>
    <div class="content">
      <div class="count_list">
        <p class="count_title">
          <span>报名人数统计</span>
          <el-date-picker
            v-model="timesCount"
            type="daterange"
            @change="val => {this.onChangeDate(val, 'Count')}"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <span>总人数：{{resDataCount.total}}</span>
        </p>
        <CountBar :barData="resDataCount.data"></CountBar>
        <!-- <div class="count_type">
          <el-radio-group v-model="paramCount.dayType" @change="val => { this.onChangeType(val, 'Count') }">
            <el-radio label="DAY">日</el-radio>
            <el-radio label="WEEK">周</el-radio>
            <el-radio label="MONTH">月</el-radio>
          </el-radio-group>
        </div> -->
      </div>
      <div class="count_list">
        <p class="count_title">
          <span>报名金额统计</span>
          <el-date-picker
            v-model="timesFee"
            type="daterange"
            @change="val => {this.onChangeDate(val, 'Fee')}"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
          <span>总金额：{{resDataFee.total}}</span>
        </p>
        <CountBar :barData="resDataFee.data"></CountBar>
        <!-- <div class="count_type">
          <el-radio-group v-model="paramFee.dayType" @change="val => { this.onChangeType(val, 'Fee') }">
            <el-radio label="DAY">日</el-radio>
            <el-radio label="WEEK">周</el-radio>
            <el-radio label="MONTH">月</el-radio>
          </el-radio-group>
        </div> -->
      </div>

      <div class="count_list">
        <p class="count_title">
          <span>课程报名人数统计</span>
          <span>总次数：{{resDataTerm.totalSignCount}}</span>
        </p>
        <CountBar :barData="resDataTerm.studentCountGroupByCourse"></CountBar>
      </div>
      <div class="count_list">
        <p class="count_title">
          <span>课程报名金额统计</span>
          <span>总金额：{{resDataTerm.totalSignFeeCount}}</span>
        </p>
        <CountBar :barData="resDataTerm.signFeeGroupByCourse"></CountBar>
      </div>

      <div class="count_list">
        <p class="count_title">
          <span>用户报名方式统计</span>
          <span>报名总数：{{resDataTerm.totalSignCount}}</span>
        </p>
        <CountPie :pieData="resDataTerm.signCountGroupByPaymentType"></CountPie>
      </div>
      <div class="count_list">
        <p class="count_title">
          <span>用户报名方式统计</span>
          <span>报名学员总数：{{resDataTerm.totalSignStudentCount}}</span>
        </p>
        <CountPie :pieData="resDataTerm.studentCountGroupBySignCourseCount"></CountPie>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseTime } from '@/utils/index'
import CountBar from './components/CountBar'
import CountPie from './components/CountPie.vue'
import { getTermList, getTermCount, getTermCountDay } from '@/api/count'
export default {
  data () {
    return {
      termList: [],
      paramTerm: {
        termId: ''
      },
      timesFee: [],
      timesCount: [],
      paramFee: {
        termId: '',
        dayType: 'DAY',
        type: 'FEE',
        startDay: '2021-08-22',
        endDay: '2021-09-22',
      },
      paramCount: {
        termId: '',
        dayType: 'DAY',
        type: 'COUNT',
        startDay: '2021-08-22',
        endDay: '2021-09-22',
      },
      resDataTerm: {},
      resDataFee: {},
      resDataCount: {},
      allKey: ['Count', 'Fee'],
    }
  },
    computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getTermList()
  },
  methods: {
    getDataTerm () {
      getTermCount(this.paramTerm).then(res => {
        this.resDataTerm = res.result;
      })
    },
    getDataFee () {
      getTermCountDay(this.paramFee).then(res => {
        this.resDataFee = res.result;
      })
    },
    getDataCount () {
      getTermCountDay(this.paramCount).then(res => {
        this.resDataCount = res.result;
      })
    },
    getTermList () {
      getTermList({ schoolId: this.school_id, page: 0, count: 100 }).then(res => {
        this.termList = res.result.items;
        this.getLastMonth()
        this.onChangeTerm(this.termList[0].id)
      })
    },
    onChangeTerm (val) {
      this.paramTerm.termId = val;
      this.getDataTerm()
      this.allKey.forEach(key => {
        this['param' + key].termId = val;
        this['getData' + key]();
      })
    },
    onChangeDate (val, key) {
      if(val) {
        let [start, end] = [+new Date(val[0]), +new Date(val[1])];
        if(end - start > 30*24*60*60*1000 && this['param' + key].dayType == 'DAY') {
          this.$message.warning('请选择30天内')
          this['times' + key] = [];
          return false;
        }
        this['param' + key].startDay = val[0];
        this['param' + key].endDay = val[1];
        this['getData' + key]();
      }
    },
    onChangeType (val, key) {
      this['getData' + key]();
    },
    getLastMonth () {
      let now = +new Date();
      let start = now - 30*24*60*60*1000;
      let startDay = parseTime(start, '{y}-{m}-{d}')
      let endDay = parseTime(now, '{y}-{m}-{d}')
      this.allKey.forEach(key => {
        console.log(key)
        this['times' + key] = [startDay, endDay]
        this['param' + key].startDay = startDay;
        this['param' + key].endDay = endDay;
      })
    },
  },
  components: {
    CountBar,
    CountPie
  }
}
</script>

<style lang="scss" scoped>
.count_data_box{
  width: 100%;
  height: 100%;
  padding: 20px;
  .search_box{
    width: 100%;
    padding: 20px 0;
  }
  .content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .count_list{
    width: calc(50% - 10px);
    margin-left: 20px;
    background: #fff;
    margin-bottom: 20px;
    padding-top: 10px;
    &:nth-child(2n+1) {
      margin-left: 0;
    }
    .count_title{
      width: 100%;
      height: 40px;
      margin: 0;
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      span:first-child{
        font-size: 16px;
        font-weight: 700;
      }
      span:nth-child(2){
        font-size: 14px;
      }
    }
    .count_type{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      margin-top: -10px;
    }
  }
}
</style>