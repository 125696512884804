<template>
  <div class="bar_count_box">
    <div class="bar_content" ref="bar_content"></div>
  </div>
</template>

<script>
// 柱状图
import * as echarts from 'echarts';
export default {
  props: {
    barData: Array
  },
  data() {
    return {
      echechatBar: null,
    }
  },
  watch: {
    barData() {
      this.echatBar.setOption(this.renderOption());
    },
  },
  mounted() {
    setTimeout(() => {
      let _this = this;
      this.echatBar = echarts.init(this.$refs.bar_content);
      if (this.barData) this.echatBar.setOption(this.renderOption());
      window.addEventListener("resize", function() {                
        _this.echatBar.resize();           
      });
    }, 0)
  },
  methods: {
    renderOption() {
      return {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#8c8c8c'
            },
          },
          axisLabel: {
                        interval: 0,
                        rotate: 40
                    },
          axisTick: {
            show: false,
          },
          data: this.barData.map(item => item.name),
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          type: 'bar',
          label: {
            show: true,
            position: 'top',
          },
          barMaxWidth: '40px',
          data: this.barData.map(item => item.value),
        }]
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.bar_count_box {
  width: 100%;
  .bar_content {
    width: 100%;
    height: 400px;
  }
}
</style>