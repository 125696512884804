<template>
  <div class="a-container">
    <!-- <sub-title title="添加学期" /> -->
    <div class="flex align-center flex-wrap">
      <el-button type="primary" class="margin-right-20 margin-bottom-20" @click="goTermCreate">新建学期</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      style="width: 100%;"
    >
      <el-table-column
        prop="name"
        label="学期名称"
      />
      <el-table-column
        prop="dayBegin"
        label="开始时间"
      />
      <el-table-column
        prop="dayEnd"
        label="结束时间"
      />
      <el-table-column
        prop="enable"
        label="是否启用"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.enable ? '是' : '否' }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="courseCount"
        label="课程数"
      />
      <el-table-column
        prop="signCount"
        label="报名数"
      />
      <el-table-column
        prop="viewCount"
        label="浏览量"
      /> -->
      <el-table-column
        fixed="right"
        label="操作"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editTerm(scope.row.id)">详情</el-button>
          <el-button type="text" size="small" @click="termDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="listQuery.page" :pagesize.sync="listQuery.count" @pagination="getList" />

    <!-- 新建学期 -->
    <el-dialog :title="editId ? '编辑学期' : '新建学期'" :visible.sync="showDialog" width="830px">
      <term-create :termId="editId" :show="showDialog" @emitCancel="emitCancel"></term-create>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SubTitle from '@/components/SubTitle/index'
import { termList, termDel } from '@/api/course-manager'
import { mapGetters } from 'vuex'
import TermCreate from './TermCreate/index.vue'
export default {
  name: 'TermManager',
  components: {
    SubTitle,
    Pagination,
    TermCreate
  },
  data() {
    return {
      listQuery: {
        page: 0,
        count: 10
      },
      total: 0,
      listLoading: false,
      list: [],

      showDialog: false,
      editId: null,
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  created() {
    this.getList()
    if(this.$route.query.create) this.showDialog = true;
  },
  methods: {
    goTermCreate() {
      // this.$router.push({
      //   name: 'TermCreate',
      //   query: {
      //     schoolId: this.school_id
      //   }
      // })
      this.editId = null;
      this.showDialog = true;
    },

    getList() {
      this.listLoading = true
      termList({ schoolId: this.school_id, ...this.listQuery }).then(res => {
        this.listLoading = false
        this.list = res.result.items
        this.total = res.result.total
      })
    },

    termDel(id) {
      termDel({ id: id }).then(res => {
        this.getList()
        this.$message.success('课程删除成功')
      })
    },

    editTerm(id) {
      this.editId = id;
      this.showDialog = true;
      // this.$router.push({
      //   name: 'TermCreate',
      //   query: {
      //     termId: id
      //   }
      // })
    },

    emitCancel (val) {
      this.showDialog = false;
      if(val) {
        this.listQuery.page = 0;
        this.getList() 
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
