<template>
  <div class="a-container">
    <div class="flex align-center flex-wrap">
      <!-- <div class="flex-center margin-right-30 margin-bottom-20">当前学期：
        <el-select v-model="curTerm.id" placeholder="请选择" style="width: 150px;" @change="changeTerm">
          <el-option label="全部学期" value="-1"></el-option>
          <el-option
            v-for="item in termList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">课程选择：
        <el-select v-model="courseId" placeholder="请选择" style="width: 150px;" @change="changeCourse">
          <el-option
            v-for="item in courseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div> -->
      <div class="flex-center margin-right-30 margin-bottom-20">用户名称：
        <el-input v-model="listQuery.studentName" placeholder="请输入姓名" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">用户手机号：
        <el-input v-model="listQuery.phone" placeholder="请输入手机号" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">用户状态：
        <el-select v-model="listQuery.verifyStatus" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option label="全部" value=""></el-option>
          <el-option label="通过" value="SUCCESS"></el-option>
          <el-option label="等待" value="WAIT"></el-option>
          <el-option label="拒绝" value="FAILED"></el-option>
        </el-select>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResetting">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
      <el-button type="primary" class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapExport">导出</el-button>
      <!-- <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" >编辑</el-button> -->
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      style="width: 100%;"
    >
      <el-table-column
        label="头像名称"
        width="150"
      >
        <template slot-scope="scope">
          <div class="flex align-center">
            <el-avatar :src="scope.row.pic" style="width: 30px; height: 30px;" />
            <span style="margin-left: 12px;">{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="realName"
        label="姓名"
      />
      <!-- <el-table-column
        prop="---"
        label="学号"
      /> -->
      <el-table-column
        prop="phone"
        label="手机号"
        width="150"
      />
      <el-table-column
        label="性别"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.male? '男' : '女' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="signFee"
        label="报名费"
      />
      <el-table-column
        prop="groups"
        label="民族"
      />
      <el-table-column
        prop="age"
        label="年龄"
      />
      <el-table-column
        prop="systemBelong"
        label="所属系统"
      />
      <el-table-column
        prop="organization"
        label="原工作单位"
        width="150"
      />
      <el-table-column
        prop="address"
        label="家庭住址"
        width="200"
      />
      <el-table-column
        prop="peopleId"
        label="身份证号"
        width="200"
      />
      <el-table-column
        prop="verified"
        label="用户状态"
        width="150"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.verified == 'SUCCESS' ? '通过' : (scope.row.verified == 'WAIT' || scope.row.verified == null)? '等待' : '拒绝' }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="是否在白名单"
        v-if="courseId != -1"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.whitelist" @change="setWhitelist(scope.row.id, scope.row.whitelist)">{{ scope.row.whitelist ? '否' : '是' }}</el-checkbox>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="contact"
        label="应急联系人"
        width="150"
      />
      <el-table-column
        prop="contactPhone"
        label="联系电话"
        width="150"
      />
      <el-table-column
        prop="retiredDay"
        label="离退休时间"
      />
      <el-table-column
        prop="retiredCardNo"
        label="离退休证号"
      />
      <el-table-column
        prop="disaccountCardNo"
        label="共享卡号"
      />
      <el-table-column
        fixed="right"
        label="操作"
        width="240"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="getInfo(scope.row, false)">详情</el-button>
          <el-button type="text" @click="getInfo(scope.row, true)">编辑</el-button>
          <el-button type="text" v-if="scope.row.verified == 'WAIT' || scope.row.verified == 'FAILED'  || scope.row.verified == null" @click="tapVerify(scope.row)">审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :auto-scroll="false" :total="total" :page.sync="listQuery.page" :pagesize.sync="listQuery.pageSize" @pagination="getList" />

    <el-dialog title="审核" :visible.sync="showDialog" width="400px">
      <el-select v-model="dialogParam.status" placeholder="请选择" style="width: 100%">
        <el-option label="通过" value="SUCCESS"></el-option>
        <el-option label="等待" value="WAIT"></el-option>
        <el-option label="拒绝" value="FAILED"></el-option>
      </el-select>
      <span slot="footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看学员资料" :visible.sync="showDetail" width="900px">
      <el-form :model="editParam" size="mini" :rules="editRule" ref="form" label-width="100px">
      <div class="user_detail_dialog">
        <div class="user_left">
          <img v-if="editParam.pic" class="user_header" :src="editParam.pic" alt="" />
          <img v-else class="user_header" src="@/assets/header.jpg" alt="" />
          <div class="user_family">
            <p class="left_title">应急联络卡</p>
            <el-form-item label="姓名" prop="contact">
              <el-input v-if="isEdit" v-model="editParam.contact" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.contact }}</p>
            </el-form-item>
            <el-form-item label="关系" prop="contact">
              <p>亲人</p>
            </el-form-item>
            <el-form-item label="电话" prop="contactPhone">
              <el-input v-if="isEdit" v-model="editParam.contactPhone" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.contactPhone }}</p>
            </el-form-item>
            <p class="left_title">健康状况</p>
            <el-form-item class="no_label" label="" prop="health">
              <el-input v-if="isEdit" v-model="editParam.health" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.health }}</p>
            </el-form-item>
          </div>
        </div>
        <div class="user_right">
          <div class="right_module">
            <el-form-item class="half_list" label="姓名" prop="realName">
              <el-input v-if="isEdit" v-model="editParam.realName" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.realName }}</p>
            </el-form-item>
            <!-- <el-form-item class="half_list" label="学号" prop="studentNo">
              <p>{{ editParam.studentNo }}</p>
            </el-form-item>
            <el-form-item class="half_list" label="性别" prop="sex">
              <el-select v-if="isEdit" v-model="editParam.sex" placeholder="请选择">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
              <p v-else>{{ editParam.policitalStatus }}</p>
            </el-form-item> -->
            <el-form-item class="half_list" label="民族" prop="groups">
              <el-input v-if="isEdit" v-model="editParam.groups" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.groups }}</p>
            </el-form-item>
            <el-form-item class="half_list" label="年龄" prop="age">
              <el-input v-if="isEdit" v-model="editParam.age" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.age }}</p>
            </el-form-item>
            <el-form-item class="half_list" label="面貌" prop="policitalStatus">
              <el-select v-if="isEdit" v-model="editParam.policitalStatus" placeholder="请选择">
                <el-option label="群众" value="群众"></el-option>
                <el-option label="党员" value="党员"></el-option>
              </el-select>
              <p v-else>{{ editParam.policitalStatus }}</p>
            </el-form-item>
            <el-form-item label="现住址" prop="address">
              <el-input v-if="isEdit" v-model="editParam.address" placeholder="请输入详细地址" ></el-input>
              <p v-else>{{ editParam.address }}</p>
            </el-form-item>
          </div>
          <div class="right_module">
            <el-form-item label="原工作单位" prop="organization">
              <el-input v-if="isEdit" v-model="editParam.organization" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.organization }}</p>
            </el-form-item>
            <el-form-item label="所属系统" prop="systemBelong">
              <template v-if="isEdit">
                <el-select v-if="systemOptions && systemOptions.length" v-model="editParam.systemBelong">
                  <el-option v-for="item in systemOptions" :key="item.option" :label="item.option" :value="item.option"></el-option>
                </el-select>
                <el-input v-else v-model="editParam.systemBelong" placeholder="请输入" ></el-input>
              </template>  
              <p v-else>{{ editParam.systemBelong }}</p>
            </el-form-item>
            <el-form-item label="户口所在地" prop="registeredResidence">
              <el-input v-if="isEdit" v-model="editParam.registeredResidence" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.registeredResidence }}</p>
            </el-form-item>
            <el-form-item label="离退休时间" prop="retiredDay">
              <el-date-picker v-if="isEdit" v-model="editParam.retiredDay" placeholder="请选择" type="date" value-format="yyyy-MM-dd" ></el-date-picker>
              <p v-else>{{ editParam.retiredDay }}</p>
            </el-form-item>
          </div>
          <div class="right_module">
            <el-form-item label="身份证号" prop="peopleId">
              <el-input v-if="isEdit" v-model="editParam.peopleId" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.peopleId }}</p>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
              <el-input v-if="isEdit" v-model="editParam.phone" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.phone }}</p>
            </el-form-item>
            <el-form-item label="共享卡号" prop="disaccountCardNo">
              <el-input v-if="isEdit" v-model="editParam.disaccountCardNo" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.disaccountCardNo }}</p>
            </el-form-item>
            <!-- <el-form-item class="half_list" label="激活时间" prop="signTime">
              <p>{{ editParam.signTime }}</p>
            </el-form-item> -->
            <el-form-item label="离退休证号" prop="retiredCardNo">
              <el-input v-if="isEdit" v-model="editParam.retiredCardNo" placeholder="请输入" ></el-input>
              <p v-else>{{ editParam.retiredCardNo }}</p>
            </el-form-item>
            <!-- <el-form-item class="half_list" label="最近登陆" prop="disaccountCardNo">
              <p>{{ editParam.disaccountCardNo }}</p>
            </el-form-item> -->
            <!-- <el-form-item label="状态" prop="disaccountCardNo">
              <p>{{ editParam.disaccountCardNo }}</p>
            </el-form-item> -->
          </div>
        </div>
        <div class="btn_view">
          <template v-if="isEdit">
            <el-button @click="showDetail = false">取消</el-button>
            <el-button type="primary" @click="tapEditConfirm">保存</el-button>
          </template>
          <el-button v-else type="primary" @click="isEdit = true">编辑资料</el-button>
        </div>
      </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SubTitle from '@/components/SubTitle/index'
import { studentsList, setVerify, exportData, getStudentInfo, editStudentInfo, getSystemOptions } from '@/api/student-manager'
import { termList, courseList } from '@/api/course-manager'
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import { openDownload } from '@/utils/index'
export default {
  name: 'StudentManager',
  components: {
    SubTitle,
    Pagination
  },
  data() {
    return {
      fileList: [],
      listQuery: {
        page: 0,
        pageSize: 10,
        verifyStatus: '',
      },
      total: 0,
      listLoading: false,
      list: [],
      termId: '',
      courseId: -1,
      termList: [],
      courseList: [],
      statusList: [],
      systemOptions: [],
      curTerm: {},
      showDialog: false,
      dialogParam: {
        schoolId: '',
        studentId: '',
        status: '',
      },
      // edit
      showList: [
        'age',
        'male',
        'realName',
        'peopleId',
        'groups',
        'policitalStatus',
        'area',
        'address',
        'health',
        'systemBelong',
        'organization',
        'registeredResidence',
        'retiredDay',
        'retiredCardNo',
        'disaccountCardNo',
        'contact',
        'contactPhone',
      ],
      isEdit: true,
      showDetail: false,
      showDrawer: false,
      editRule: {
        contact: [
          {required: true, trigger: 'submit'}
        ]
      },
      editParam: {},
      infoSetting: {}
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ]),
    dataObj() {
      return { token: getToken(), courseId: this.courseId }
    }
  },
  created() {
    Promise.all([
      this.getTermList(),
      this.getCourseList()
    ]).then(res => {
      if(this.termList.length) {
        this.$set(this.curTerm, 'id', this.termList[0].id)
        this.getList()
      }
    })
    this.getOptions()
  },
  methods: {
    getList() {
      this.listLoading = true
      studentsList({ schoolId: this.school_id, ...this.listQuery }).then(res => {
        this.listLoading = false
        this.list = res.result.content
        this.total = res.result.totalElements
      })
    },

    tapSearch() {
      this.listQuery.page = 0;
      this.getList()
    },

    tapResetting () {
      this.listQuery.page = 0;
      this.listQuery.studentName = '';
      this.listQuery.phone = '';
      this.listQuery.verifyStatus = '';
      this.getList()
    },

    getCourseList() {
      return new Promise((resolve, reject) => {
        courseList({ schoolId: this.school_id, termId: this.curTerm.id, page: 0, count: 10000 }).then(res => {
          this.courseList = res.result.items
          this.courseList.unshift({ id: -1, name: '全部课程' })
          resolve()
        })
      })
    },

    getTermList() {
      return new Promise((resolve, reject) => {
        termList({ schoolId: this.school_id, page: 0, count: 10000 }).then(res => {
          this.termList = res.result.items
          resolve()
        })
      })
    },

    getOptions () {
      getSystemOptions({ schoolId: this.school_id, page: 0, count: 10000 }).then(res => {
        res.result.studentFields.forEach(item => {
          this.editRule[item.fieldName] = { 
            required: true, trigger: 'submit'
          }
        })
        this.systemOptions = res.result.systemBelongOptions
      })
    },

    changeTerm() {
      this.getCourseList()
      this.courseId = -1;
      this.getList()
    },

    changeCourse() {
      this.getList()
    },

    tapExport () {
      exportData({ ...this.listQuery }).then(res => {
        openDownload(res, '学员列表.xlsx')
      })
    },

    tapVerify (item) {
      this.dialogParam.schoolId = this.school_id;
      this.dialogParam.studentId = item.id;
      this.dialogParam.status = item.verified;
      this.showDialog = true;
    },

    tapConfirm () {
      if(!this.dialogParam.status) {
        this.$message.error('请选择审核结果')
        return false;
      }
      setVerify({ ...this.dialogParam }).then(res => {
        if(res.code == 0) {
          this.$message.success('审核成功')
          this.showDialog = false;
          this.getList()
        } else {
          this.$message.error(res.errorMsg)
        }
      }).catch(res => {
        this.$message.error(res.errorMsg)
      })
    },

    getInfo (item, isEdit) {
      this.editParam = {};
      this.isEdit = isEdit;
      getStudentInfo({ studentId: item.id }).then(res => {
        // for(let item of this.showList) {
        //   if(item == 'address' && res.result[item]) {
        //     this.$set(this.editParam, 'area', res.result[item].split(' ')[0])
        //     this.$set(this.editParam, item, res.result[item].split(' ')[1])
        //     continue;
        //   }
        //   this.$set(this.editParam, item, res.result[item] || '')
        // }
        // this.$set(this.editParam, 'studentId', res.result.id)
        res.result.studentId = res.result.id;
        this.editParam = res.result;
        this.showDetail = true;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      })
    },
    tapEditConfirm () {
      let params = { ...this.editParam };
      editStudentInfo(params).then(res => {
        this.$message.success('操作成功')
        this.showDetail = false;
        this.getList()
      }).catch(res => {
        this.$message.error(res.errorMsg)
      })
    }
  }
}
</script>


<style lang="scss">
  .student_edit_drawer{
    .el-drawer__body{
      overflow-y: auto;
      padding: 0 20px 40px;
      .el-select,.el-date-editor{
        width: 100%;
      }
      .drawer_btn{
        display: flex;
        justify-content: center;
      }
      p{
        margin: 0;
      }
    }
  }
  .user_detail_dialog{
    width: 100%;
    display: flex;
    padding: 0 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    p{
      margin: 0;
    }
    .user_left{
      width: 30%;
      .user_header{
        width: 120px;
        height: 120px;
        display: block;
        margin: 0 auto;
      }
      .user_family{
        width: 100%;
        height: 300px;
        padding: 15px;
        margin: 30px auto 0;
        background: #fafafa;
        .left_title{
          text-align: center;
          margin-bottom: 10px;
          padding-bottom: 5px;
          border-bottom: 1px solid #ccc;
        }
        .full_input{
          max-width: 100%;
        }
        .left_list span:first-child{
          margin-right: 15px;
        }
        label{
          width: 55px!important;
          font-weight: 400;
          text-align: left;
        }
        .el-form-item__content{
          margin-left: 55px!important;
          .el-input{
            width: 100%;
          }
        }
        .no_label{
          label{
            width: 0!important;
          }
          .el-form-item__content{
            margin-left: 0!important;
          }
        }
      }
    }
    .user_right{
      width: 60%;
      .right_module{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        label{
          font-weight: 400;
        }
        .el-input,.el-select,.el-date-editor{
          width: 100%;
        }
        .el-form-item{
          width: 100%;
          margin-bottom: 10px;
        }
        .half_list{
          width: 50%;
        }
      }
    }
    .btn_view{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
</style>