var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container relative" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content flex" }, [
      _c("img", {
        staticClass: "chahua",
        attrs: {
          src:
            "https://img.muketang.com/global/schoolhost/login_chahua_muke.png"
        }
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "69px" } }, [
        _c(
          "div",
          {
            staticClass: "text-28 text-bold orange text-center",
            staticStyle: { "line-height": "40px" }
          },
          [_vm._v("睦课伴读管理系统")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loginType === 1,
                expression: "loginType === 1"
              }
            ],
            staticClass: "qrcode-login animation-show text-center"
          },
          [
            _c(
              "el-image",
              {
                staticClass: "cu-size-200",
                staticStyle: { "margin-top": "17px" },
                attrs: { src: _vm.qrCode }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "error" },
                    slot: "error"
                  },
                  [_vm._v("加载中...")]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "margin-top-20" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "orange clickable text-14 margin-top-10",
                  on: { click: _vm.changeLoginType }
                },
                [_vm._v("或使用账号密码登录")]
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loginType === 2,
                expression: "loginType === 2"
              }
            ],
            staticClass: "animation-show margin-top-50"
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-form",
                  {
                    ref: "loginForm",
                    staticClass: "login-form animation-show",
                    attrs: {
                      model: _vm.loginForm,
                      rules: _vm.loginRules,
                      "auto-complete": "on",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "phone" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-16 text-555",
                            staticStyle: {
                              width: "80px",
                              display: "inline-block"
                            }
                          },
                          [_vm._v("手机号")]
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          ref: "phone",
                          attrs: {
                            placeholder: "手机号",
                            name: "phone",
                            type: "text",
                            tabindex: "1",
                            autocomplete: "on"
                          },
                          model: {
                            value: _vm.loginForm.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.loginForm, "phone", $$v)
                            },
                            expression: "loginForm.phone"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "password" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-16 text-555",
                            staticStyle: {
                              width: "80px",
                              display: "inline-block"
                            }
                          },
                          [_vm._v("登陆密码")]
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          key: _vm.passwordType,
                          ref: "password",
                          attrs: {
                            type: _vm.passwordType,
                            placeholder: "密码",
                            name: "password",
                            tabindex: "2",
                            autocomplete: "on"
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.loginByAccount($event)
                            }
                          },
                          model: {
                            value: _vm.loginForm.password,
                            callback: function($$v) {
                              _vm.$set(_vm.loginForm, "password", $$v)
                            },
                            expression: "loginForm.password"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "show-pwd",
                            on: { click: _vm.showPwd }
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class":
                                  _vm.passwordType === "password"
                                    ? "eye"
                                    : "eye-open"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "margin-top-20",
                        staticStyle: { width: "100%", "margin-bottom": "25px" },
                        attrs: { loading: _vm.loading, type: "primary" },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.loginByAccount($event)
                          }
                        }
                      },
                      [_vm._v("登录")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", {
        staticClass: "title-logo",
        attrs: {
          src: "https://img.muketang.com/global/schoolhost/logo_muke.png"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("img", {
        staticClass: "icon-scan",
        attrs: {
          src: "https://img.muketang.com/global/schoolhost/scan_muke.png"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-666 text-14 margin-left-10" }, [
        _vm._v("\n              打开手机\n              "),
        _c("span", { staticClass: "orange" }, [_vm._v("微信")]),
        _vm._v(" 扫一扫登录\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }