import request from '@/utils/request'

export function baseDataApi(params) {
  return request({
    url: '/school/home/basicdata',
    type: 'get',
    params,
  }, true)
}
export function todayCourseApi(params) {
  return request({
    url: '/school/home/todaycourse',
    type: 'get',
    params,
  }, true)
}
export function tomorrowCourseApi(params) {
  return request({
    url: '/school/home/tomorrowcourse',
    type: 'get',
    params,
  }, true)
}
export function signInfoApi(params) {
  return request({
    url: '/school/home/signinfo',
    type: 'get',
    params,
  }, true)
}
export function signFeeInfoApi(params) {
  return request({
    url: '/school/home/signfeeinfo',
    type: 'get',
    params,
  }, true)
}
export function subSchoolApi(params) {
  return request({
    url: '/school/home/subschool',
    type: 'get',
    params,
  }, true)
}
export function viewInfoApi(params) {
  return request({
    url: '/school/home/viewInfo',
    type: 'get',
    params,
  }, true)
}
export function whitelistApi(params) {
  return request({
    url: '/school/home/whitelistinfo',
    type: 'get',
    params,
  }, true)
}