<template>
  <div class="school_tab_box">
    <div class="text_right">
      <el-button type="primary" style="height: 32px;" @click="editStatus = true">编辑资料</el-button>
    </div>
    <el-form :model="createParam" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="学校名称" prop="name">
        <p class="text_val" v-if="!editStatus">{{ createParam.name }}</p>
        <el-input :disabled="true" v-else v-model="createParam.name"></el-input>
      </el-form-item>
      <el-form-item label="联系地址" prop="address">
        <p class="text_val" v-if="!editStatus">{{ createParam.address }}</p>
        <el-input v-else v-model="createParam.address"></el-input>
      </el-form-item>
      <el-form-item label="邮政编码" prop="zipCode">
        <p class="text_val" v-if="!editStatus">{{ createParam.zipCode }}</p>
        <el-input v-else v-model="createParam.zipCode"></el-input>
      </el-form-item>
      <el-form-item label="E-mail" prop="email">
        <p class="text_val" v-if="!editStatus">{{ createParam.email }}</p>
        <el-input v-else v-model="createParam.email"></el-input>
      </el-form-item>
      <el-form-item label="网站" prop="website">
        <p class="text_val" v-if="!editStatus">{{ createParam.website }}</p>
        <el-input v-else v-model="createParam.website"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <p class="text_val" v-if="!editStatus">{{ createParam.phone }}</p>
        <el-input v-else v-model="createParam.phone"></el-input>
      </el-form-item>
      <el-form-item label="咨询电话" prop="consultationTelephone">
        <p class="text_val" v-if="!editStatus">{{ createParam.consultationTelephone }}</p>
        <el-input v-else v-model="createParam.consultationTelephone"></el-input>
      </el-form-item>
      <el-form-item label="工作时间" prop="workTime">
        <p class="text_val" v-if="!editStatus">{{ createParam.workTime }}</p>
        <el-input v-else v-model="createParam.workTime"></el-input>
      </el-form-item>
    </el-form>
    <div class="bom_btn" v-if="editStatus">
      <el-button @click="editStatus = false">取 消</el-button>
      <el-button type="primary" @click="tapConfirm">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { saveSchoolInfo } from '@/api/school-info'
export default {
  props: {
    schoolInfo: Object
  },
  data () {
    return {
      editStatus: false,
      createParam: {
        name: '',
        address: '',
        consultationTelephone: '',
        email: '',
        phone: '',
        website: '',
        workTime: '',
        zipCode: '',
      },
      rules: {}
    }
  },
  watch: {
    schoolInfo (val) {
      this.renderData()
    }
  },
  mounted () {
    if(this.schoolInfo) {
      this.renderData()
    }
  },
  methods: {
    renderData () {
      for(let k in this.createParam) {
        this.createParam[k] = this.schoolInfo[k]
      }
    },
    tapConfirm () {
      saveSchoolInfo({ ...this.createParam, token: getToken() }).then(res => {
        this.$parent.$parent.$parent.getData()
        this.$message.success('保存成功')
        this.editStatus = false;
      })
    }
  }
}
</script>

<style lang="scss">
.school_tab_box{
  width: 100%;
  .text_right{
    text-align: right;
    margin-bottom: 10px;
  }
  .el-form-item__label{
    color: #606266!important;
    font-weight: 400!important;
  }
  .el-input{
    width: 500px;
  }
  .text_val{
    margin: 0;
  }
  .bom_btn{
    width: 620px;
    text-align: center;
  }
}
</style>
