<template>
  <div class="a-course-list">
    <!-- <sub-title title="课程管理" /> -->
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">当前学期：
        <el-select v-model="listQuery.termId" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in termList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">课程名称：
        <el-input v-model="listQuery.courseName" placeholder="请输入姓名" style="width: 150px;" clearable @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">教师名称：
        <el-input v-model="listQuery.teacherName" placeholder="请输入姓名" style="width: 150px;" clearable @change="tapSearch"></el-input>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResetting">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
      <el-button class="margin-right-20 margin-bottom-20" type="primary" @click="goCourseCreate">新建课程</el-button>
      <el-upload
        class="margin-right-20 margin-bottom-20"
        :action="uploadUrl"
        :data="dataObj"
        :on-success="handleSuccess"
        :on-change="handleChangePic"
        :on-error="handleError"
        :show-file-list="false"
      >
        <el-button type="primary">上传课程</el-button>
      </el-upload>
      <el-button class="margin-right-20 margin-bottom-20" type="primary" @click="tapDownload">下载模板</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      style="width: 100%;"
    >
      <el-table-column
        prop="name"
        label="课程名称"
      />
      <el-table-column
        prop="termName"
        label="学期"
      />
      <el-table-column
        prop="firstCateName"
        label="一级类目"
      >
      </el-table-column>
      <el-table-column
        prop="secondCateName"
        label="二级类目"
      >
      </el-table-column>
      <el-table-column
        prop="teacherName"
        label="授课老师"
      />
      <!-- <el-table-column
        prop="---"
        label="管理人员"
      /> -->
      <el-table-column
        label="上课时间"
        width="200"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.timeBegin + '-' + scope.row.timeEnd }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="signCount"
        label="当前报名"
      />
      <!-- <el-table-column
        prop="courseType"
        label="课程状态"
      >
        <template slot-scope="scope">
          <div>
            未开课 课程中 已结束 已作废
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="courseType"
        label="课程类型"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.courseType == "Private" ? '私密' : '公开' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="signCount"
        label="课程来源"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.copyright">
            {{ scope.row.copyright == 'EXTERNAL' ? '外采' : (scope.row.copyright == 'SELF' ? '自研' : '三方') }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="disabled"
        label="是否禁用"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.disabled ? '禁用' : '启用' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="disabledReason"
        label="禁用原因"
      />
      <el-table-column
        prop="creator.account"
        label="创建者"
      /> -->
      <el-table-column
        prop="time"
        label="创建时间"
        width="200"
      />
      <el-table-column
        fixed="right"
        label="操作"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editCourse(scope.row.id)">详情</el-button>
          <el-button type="text" size="small" @click="courseDel(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="listQuery.page" :pagesize.sync="listQuery.count" @pagination="getList" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SubTitle from '@/components/SubTitle/index'
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'
import { termList, courseListNew, courseDel } from '@/api/course-manager'
import { mapGetters } from 'vuex'
export default {
  name: 'CourseList',
  components: {
    SubTitle,
    Pagination
  },
  // props: {
  //   termId: {
  //     type: Number,
  //     required: true
  //   }
  // },
  data() {
    return {
      listQuery: {
        page: 0,
        pageSize: 10,
        termId: '',
        courseName: '',
        teacherName: '',
      },
      termList: [],
      total: 0,
      listLoading: true,
      list: [],
      uploadUrl: getEnv()[1] + '/course_create_by_file',
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ]),
    dataObj() {
      return { token: getToken(), termId: this.listQuery.termId }
    }
  },
  created() {
    this.getList()
    this.getTermList()
  },
  methods: {
    goCourseCreate() {
      this.$router.push({
        name: 'CourseCreate',
        query: {
          schoolId: this.school_id,
          termId: this.listQuery.termId
        }
      })
    },

    getTermList() {
      return new Promise((resolve, reject) => {
        termList({ schoolId: this.school_id, page: 0, count: 100 }).then(res => {
          this.termList = res.result.items
          resolve()
        })
      })
    },

    getList() {
      this.listLoading = true
      courseListNew({ schoolId: this.school_id, ...this.listQuery }).then(res => {
        this.listLoading = false
        this.list = res.result.content
        this.total = res.result.totalElements
      })
    },

    tapSearch () {
      this.listQuery.page = 0; 
      this.getList()
    },

    tapResetting () {
      this.listQuery.page = 0;
      this.listQuery.termId = '';
      this.listQuery.courseName = '';
      this.listQuery.teacherName = '';
      this.getList()
    },

    courseDel(courseId) {

      this.$confirm('确定要删除此课程吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        courseDel({ id: courseId }).then(res => {
        this.getList()
        this.$message.success('删除成功')
      })
      }).catch(() => { })

      
    },

    editCourse(courseId) {
      // this.$router.push({
      //   name: 'CourseCreate',
      //   query: {
      //     courseId: courseId,
      //     schoolId: this.school_id
      //   }
      // })
      const routeData = this.$router.resolve({ path: '/course-edit', query: {
            courseId: courseId,
            schoolId: this.school_id
          }})
      window.open(routeData.href, '_blank')
    },

    handleSuccess(response) {
      if(response.result >= 1) {
        this.$message.success('上传成功')
        this.getList()
      } else {
        this.$message.error(response.errorMsg)
      }
    },

    handleChangePic(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
    },

    handleError (err) {
      this.$message.error('上传失败')
    },

    tapDownload () {
      window.open('https://file.muketang.com/temp/course_upload.xlsx')
    }
  }
}
</script>

<style scoped>
  .a-course-list {
    height: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
  }

</style>
