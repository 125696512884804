var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "school_fourth_box" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.statusParam, "label-width": "150px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "新注册学员需审核", prop: "title" } },
            [
              _c("el-switch", {
                on: { change: _vm.onSaveVerify },
                model: {
                  value: _vm.statusParam.studentInfoVerify,
                  callback: function($$v) {
                    _vm.$set(_vm.statusParam, "studentInfoVerify", $$v)
                  },
                  expression: "statusParam.studentInfoVerify"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text_right" },
        [
          _c(
            "el-button",
            {
              staticStyle: { height: "32px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.editStatus = true
                }
              }
            },
            [_vm._v("编辑资料")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.createParam,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "审核提示", prop: "title" } },
            [
              !_vm.editStatus
                ? _c("p", { staticClass: "text_val" }, [_vm._v("hhh")])
                : _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      maxlength: "120",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.createParam.title,
                      callback: function($$v) {
                        _vm.$set(_vm.createParam, "title", $$v)
                      },
                      expression: "createParam.title"
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.editStatus
        ? _c(
            "div",
            { staticClass: "bom_btn" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editStatus = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.tapConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }