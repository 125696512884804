<template>
  <div class="pie_count_box">
    <div class="pie_content" ref="pie_content"></div>
  </div>
</template>

<script>
// 饼图
import * as echarts from 'echarts';
export default {
  props: {
    pieData: Array
  },
  data() {
    return {
      echechatPie: null
    };
  },
  watch: {
    pieData: {
      handler(val) {
        console.log(val)
        this.echatPie.setOption(this.renderOption());
      },
      deep: true
    }
  },
  mounted() {
    setTimeout(() => {
      let _this = this;
      this.echatPie = echarts.init(this.$refs.pie_content);
      if (this.pieData) this.echatPie.setOption(this.renderOption());
      window.addEventListener("resize", function() {                
        _this.echatPie.resize();           
      });
    }, 0)
  },
  methods: {
    renderOption() {
      return {
        color: [
          "#7186A5",
          "#65A2FF",
          "#3CE1B0",
          "#FFC500",
          "#FC765D",
          "#67D3F3"
        ],
        tooltip: {
          show: true, //看需求打开关闭
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          orient: 'vertical',
          right: 20,
          bottom: 10,
        },
        series: [
          {
            type: "pie",
            startAngle: 225,
            name: this.pieData.map(item => item.name),
            radius: ["40%", "70%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              formatter: "{b} {c}"
            },
            labelLine: {
              show: true
            },
            data: this.pieData,
            minAngle: 1
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.pie_count_box {
  width: 100%;
  .pie_content {
    width: 100%;
    height: 400px;
  }
}
</style>
