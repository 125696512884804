var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar flex align-center flex-between" }, [
    _c(
      "div",
      { staticClass: "flex align-center" },
      [
        _c("hamburger", {
          staticClass: "hamburger-container",
          attrs: { "is-active": _vm.sidebar.opened },
          on: { toggleClick: _vm.toggleSideBar }
        }),
        _vm._v(" "),
        _c("breadcrumb", { staticClass: "breadcrumb-container" })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-menu flex align-center" },
      [
        _c(
          "div",
          {
            staticClass: "text-333 text-12",
            staticStyle: { "margin-right": "16px" }
          },
          [_vm._v("账号：" + _vm._s(_vm.name))]
        ),
        _vm._v(" "),
        _c(
          "el-dropdown",
          { staticClass: "avatar-container", attrs: { trigger: "click" } },
          [
            _c(
              "div",
              { staticClass: "avatar-wrapper flex align-center clickable" },
              [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src:
                      (_vm.avatar || _vm.blankPic) + "?imageView2/1/w/80/h/80"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text-black",
                    staticStyle: { "margin-left": "8px" }
                  },
                  [_vm._v(_vm._s(_vm.name))]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "user-dropdown",
                attrs: { slot: "dropdown" },
                slot: "dropdown"
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/" } },
                  [
                    _c("el-dropdown-item", [
                      _vm._v("\n            首页\n          ")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.logout($event)
                      }
                    }
                  },
                  [
                    _c("span", { staticStyle: { display: "block" } }, [
                      _vm._v("退出登录")
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }