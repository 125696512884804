import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/university-manager',
  //   meta: { title: '大学管理', icon: '学士帽' },
  //   children: [{
  //     path: 'university-manager',
  //     name: 'UniversityManager',
  //     component: () => import('@/views/university-manager/index'),
  //     meta: { title: '大学管理', breadcrumb: false }
  //   },
  //   {
  //     path: 'school-create',
  //     name: 'SchoolCreate',
  //     component: () => import('@/views/university-manager/SchoolCreate'),
  //     meta: { title: '新建大学', icon: '直播中' },
  //     hidden: true
  //   }]
  // },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: { title: '首页', icon: 'index', roles: ['INDEX_PERMISSION'] },
    children: [{
      path: '/index',
      name: 'Index',
      component: () => import('@/views/index/index'),
      meta: { title: '首页', icon: 'index' }
    }]
  },
  {
    path: '/course',
    component: Layout,
    redirect: '/course-manager',
    meta: { title: '课程管理', icon: 'course', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '/course-manager',
      name: 'CourseManager',
      component: () => import('@/views/course-manager/index'),
      meta: { title: '课程管理', icon: 'course', breadcrumb: false }
    },
    {
      path: '/course-create',
      name: 'CourseCreate',
      component: () => import('@/views/course-manager/CourseCreate'),
      meta: { title: '新建课程' },
      hidden: true
    },
    {
      path: '/course-edit',
      name: 'CourseEdit',
      component: () => import('@/views/course-manager/CourseCreate'),
      meta: { title: '编辑课程' },
      hidden: true
    },
    {
      path: '/course-share',
      name: 'CourseShare',
      component: () => import('@/views/course-manager/courseShare/index'),
      meta: { title: '课程分发', icon: 'course', breadcrumb: false }
    }]
  },

  {
    path: '/academic',
    component: Layout,
    meta: { title: '教务管理', icon: 'academic', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '',
      name: 'AcademicSchool',
      component: () => import('@/views/academic/school'),
      meta: { title: '学校信息', icon: 'academic', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
    },
    {
      path: 'administrator',
      name: 'AcademicAdministrator',
      component: () => import('@/views/academic/administrator'),
      meta: { title: '管理人员', icon: 'academic', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
    },
    {
      path: 'category',
      name: 'AcademicCategory',
      component: () => import('@/views/academic/category'),
      meta: { title: '课程类目', icon: 'academic', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
    }]
  },

  {
    path: '/student-manager',
    component: Layout,
    meta: { title: '用户管理', icon: 'student', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '',
      name: 'StudentManager',
      component: () => import('@/views/student-manager/user-list'),
      meta: { title: '学员管理', icon: 'student', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
    },
    {
      path: 'white-list',
      name: 'WhiteList',
      component: () => import('@/views/student-manager/white-list'),
      meta: { title: '白名单管理', icon: 'student', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
    }
    ]
  },

  {
    path: '/order',
    component: Layout,
    meta: { title: '订单管理', icon: 'order', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '',
      name: 'PayOrder',
      component: () => import('@/views/order/pay-order'),
      meta: { title: '订单管理', icon: 'order', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
    },{
      path: 'chargeback',
      name: 'Chargeback',
      component: () => import('@/views/order/chargeback'),
      meta: { title: '退单管理', icon: 'order', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
    }]
  },

  {
    path: '/vip',
    component: Layout,
    meta: { title: '会员管理', icon: 'vip', roles: ['VIP_PERMISSION'] },
    children: [{
      path: '',
      name: 'VIPIndex',
      component: () => import('@/views/vip/index'),
      meta: { title: '会员管理', icon: 'vip', roles: ['VIP_PERMISSION']  }
    },{
      path: 'activated',
      name: 'VIPActivated',
      component: () => import('@/views/vip/activated'),
      meta: { title: '激活管理', icon: 'vip', roles: ['VIP_PERMISSION']  }
    }]
  },


  {
    path: '/vip-card',
    component: Layout,
    meta: { title: '会员卡管理', icon: 'vip-card', roles: ['VIP_PERMISSION'] },
    children: [{
      path: 'grant',
      name: 'grant',
      component: () => import('@/views/vip-card/grant'),
      meta: { title: '发放管理', icon: 'vip-card', roles: ['VIP_PERMISSION'] }
    },{
      path: 'exchange',
      name: 'exchange',
      component: () => import('@/views/vip-card/exchange'),
      meta: { title: '兑换管理', icon: 'vip-card', roles: ['VIP_PERMISSION'] }
    }]
  },

  // {
  //   path: '/finance',
  //   component: Layout,
  //   meta: { title: '财务管理', icon: 'finance', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
  //   children: [{
  //     path: '',
  //     name: 'FinanceReceive',
  //     component: () => import('@/views/finance/receive'),
  //     meta: { title: '外采账单', icon: 'finance', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
  //   },{
  //     path: '/receive/detail',
  //     hidden: true,
  //     name: 'FinanceReceiveDetail',
  //     component: () => import('@/views/finance/receive/detail'),
  //     meta: { title: '外采账单详情', icon: 'finance', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
  //   },{
  //     path: 'share',
  //     name: 'FinanceShare',
  //     component: () => import('@/views/finance/share'),
  //     meta: { title: '共享收单', icon: 'finance', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
  //   },{
  //     path: '/share/detail',
  //     hidden: true,
  //     name: 'FinanceShareDetail',
  //     component: () => import('@/views/finance/share/detail'),
  //     meta: { title: '共享收单详情', icon: 'finance', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] }
  //   }]
  // },

  {
    path: '/notice',
    component: Layout,
    children: [{
      path: '',
      name: 'Notice',
      component: () => import('@/views/notice'),
      meta: { title: '消息管理', icon: 'notice' }
    }]
  },

  {
    path: '/term-manager',
    component: Layout,
    meta: { title: '学期管理', icon: 'term', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '',
      name: 'TermManager',
      component: () => import('@/views/term-manager'),
      meta: { title: '学期管理', breadcrumb: false }
    },
    {
      path: 'term-create',
      name: 'TermCreate',
      component: () => import('@/views/term-manager/TermCreate'),
      meta: { title: '新建学期' },
      hidden: true
    }]
  },

  {
    path: '/class',
    component: Layout,
    meta: { title: '班级管理', icon: 'class', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '',
      name: 'ClassIndex',
      component: () => import('@/views/class/index'),
      meta: { title: '班级管理' }
    }]
  },

  {
    path: '/teacher-manager',
    component: Layout,
    meta: { title: '教师管理', icon: 'teacher', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '',
      name: 'TeacherManager',
      component: () => import('@/views/teacher-manager/index'),
      meta: { title: '教师列表', icon: 'teacher' }
    },
    {
      path: 'teacher-create',
      name: 'TeacherCreate',
      component: () => import('@/views/teacher-manager/TeacherCreate'),
      meta: { title: '新建教师' },
      hidden: true
    }]
  },

  {
    path: '/count',
    component: Layout,
    meta: { title: '数据统计', icon: 'count', roles: ['SCHOOL_ADMIN', 'ADMIN', 'CLASS_TEACHER'] },
    children: [{
      path: '',
      name: 'CountData',
      component: () => import('@/views/count/index'),
      meta: { title: '业务统计', icon: 'count' }
    },{
      path: '/count/user',
      name: 'CountUer',
      component: () => import('@/views/count/user'),
      meta: { title: '用户画像', icon: 'count' },
    }]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
