var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-container" },
    [
      _c(
        "div",
        { staticClass: "flex align-center flex-wrap" },
        [
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("用户名称：\n      "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "请输入姓名" },
                on: { change: _vm.tapSearch },
                model: {
                  value: _vm.listQuery.studentName,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "studentName", $$v)
                  },
                  expression: "listQuery.studentName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("用户手机号：\n      "),
              _c("el-input", {
                staticStyle: { width: "150px" },
                attrs: { placeholder: "请输入手机号" },
                on: { change: _vm.tapSearch },
                model: {
                  value: _vm.listQuery.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "phone", $$v)
                  },
                  expression: "listQuery.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("用户状态：\n      "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.tapSearch },
                  model: {
                    value: _vm.listQuery.verifyStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "verifyStatus", $$v)
                    },
                    expression: "listQuery.verifyStatus"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "" } }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "通过", value: "SUCCESS" }
                  }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "等待", value: "WAIT" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { label: "拒绝", value: "FAILED" } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              staticStyle: { width: "104px", height: "32px" },
              on: { click: _vm.tapResetting }
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              staticStyle: { width: "104px", height: "32px" },
              on: { click: _vm.tapSearch }
            },
            [_vm._v("查看")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              staticStyle: { width: "104px", height: "32px" },
              attrs: { type: "primary" },
              on: { click: _vm.tapExport }
            },
            [_vm._v("导出")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { label: "头像名称", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-center" },
                      [
                        _c("el-avatar", {
                          staticStyle: { width: "30px", height: "30px" },
                          attrs: { src: scope.row.pic }
                        }),
                        _vm._v(" "),
                        _c("span", { staticStyle: { "margin-left": "12px" } }, [
                          _vm._v(_vm._s(scope.row.name))
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "realName", label: "姓名" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "手机号", width: "150" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "性别" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.male ? "男" : "女") +
                          "\n        "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "signFee", label: "报名费" }
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "groups", label: "民族" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "age", label: "年龄" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "systemBelong", label: "所属系统" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "organization", label: "原工作单位", width: "150" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "address", label: "家庭住址", width: "200" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "peopleId", label: "身份证号", width: "200" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "verified", label: "用户状态", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.verified == "SUCCESS"
                            ? "通过"
                            : scope.row.verified == "WAIT" ||
                              scope.row.verified == null
                            ? "等待"
                            : "拒绝"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "contact", label: "应急联系人", width: "150" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "contactPhone", label: "联系电话", width: "150" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "retiredDay", label: "离退休时间" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "retiredCardNo", label: "离退休证号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "disaccountCardNo", label: "共享卡号" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "240",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.getInfo(scope.row, false)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.getInfo(scope.row, true)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    scope.row.verified == "WAIT" ||
                    scope.row.verified == "FAILED" ||
                    scope.row.verified == null
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.tapVerify(scope.row)
                              }
                            }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0 && !_vm.listLoading,
            expression: "total>0 && !listLoading"
          }
        ],
        attrs: {
          "auto-scroll": false,
          total: _vm.total,
          page: _vm.listQuery.page,
          pagesize: _vm.listQuery.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:pagesize": function($event) {
            return _vm.$set(_vm.listQuery, "pageSize", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核", visible: _vm.showDialog, width: "400px" },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.dialogParam.status,
                callback: function($$v) {
                  _vm.$set(_vm.dialogParam, "status", $$v)
                },
                expression: "dialogParam.status"
              }
            },
            [
              _c("el-option", { attrs: { label: "通过", value: "SUCCESS" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "等待", value: "WAIT" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { label: "拒绝", value: "FAILED" } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.tapConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看学员资料",
            visible: _vm.showDetail,
            width: "900px"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDetail = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.editParam,
                size: "mini",
                rules: _vm.editRule,
                "label-width": "100px"
              }
            },
            [
              _c("div", { staticClass: "user_detail_dialog" }, [
                _c("div", { staticClass: "user_left" }, [
                  _vm.editParam.pic
                    ? _c("img", {
                        staticClass: "user_header",
                        attrs: { src: _vm.editParam.pic, alt: "" }
                      })
                    : _c("img", {
                        staticClass: "user_header",
                        attrs: { src: require("@/assets/header.jpg"), alt: "" }
                      }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "user_family" },
                    [
                      _c("p", { staticClass: "left_title" }, [
                        _vm._v("应急联络卡")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "contact" } },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.contact,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "contact", $$v)
                                  },
                                  expression: "editParam.contact"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.contact))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关系", prop: "contact" } },
                        [_c("p", [_vm._v("亲人")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话", prop: "contactPhone" } },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.contactPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "contactPhone", $$v)
                                  },
                                  expression: "editParam.contactPhone"
                                }
                              })
                            : _c("p", [
                                _vm._v(_vm._s(_vm.editParam.contactPhone))
                              ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "left_title" }, [
                        _vm._v("健康状况")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "no_label",
                          attrs: { label: "", prop: "health" }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.health,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "health", $$v)
                                  },
                                  expression: "editParam.health"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.health))])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "user_right" }, [
                  _c(
                    "div",
                    { staticClass: "right_module" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "half_list",
                          attrs: { label: "姓名", prop: "realName" }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.realName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "realName", $$v)
                                  },
                                  expression: "editParam.realName"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.realName))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "half_list",
                          attrs: { label: "民族", prop: "groups" }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.groups,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "groups", $$v)
                                  },
                                  expression: "editParam.groups"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.groups))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "half_list",
                          attrs: { label: "年龄", prop: "age" }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.age,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "age", $$v)
                                  },
                                  expression: "editParam.age"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.age))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "half_list",
                          attrs: { label: "面貌", prop: "policitalStatus" }
                        },
                        [
                          _vm.isEdit
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.editParam.policitalStatus,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.editParam,
                                        "policitalStatus",
                                        $$v
                                      )
                                    },
                                    expression: "editParam.policitalStatus"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "群众", value: "群众" }
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "党员", value: "党员" }
                                  })
                                ],
                                1
                              )
                            : _c("p", [
                                _vm._v(_vm._s(_vm.editParam.policitalStatus))
                              ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "现住址", prop: "address" } },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入详细地址" },
                                model: {
                                  value: _vm.editParam.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "address", $$v)
                                  },
                                  expression: "editParam.address"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.address))])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right_module" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "原工作单位", prop: "organization" }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.organization,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "organization", $$v)
                                  },
                                  expression: "editParam.organization"
                                }
                              })
                            : _c("p", [
                                _vm._v(_vm._s(_vm.editParam.organization))
                              ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属系统", prop: "systemBelong" } },
                        [
                          _vm.isEdit
                            ? [
                                _vm.systemOptions && _vm.systemOptions.length
                                  ? _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.editParam.systemBelong,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.editParam,
                                              "systemBelong",
                                              $$v
                                            )
                                          },
                                          expression: "editParam.systemBelong"
                                        }
                                      },
                                      _vm._l(_vm.systemOptions, function(item) {
                                        return _c("el-option", {
                                          key: item.option,
                                          attrs: {
                                            label: item.option,
                                            value: item.option
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  : _c("el-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.editParam.systemBelong,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editParam,
                                            "systemBelong",
                                            $$v
                                          )
                                        },
                                        expression: "editParam.systemBelong"
                                      }
                                    })
                              ]
                            : _c("p", [
                                _vm._v(_vm._s(_vm.editParam.systemBelong))
                              ])
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "户口所在地",
                            prop: "registeredResidence"
                          }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.registeredResidence,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editParam,
                                      "registeredResidence",
                                      $$v
                                    )
                                  },
                                  expression: "editParam.registeredResidence"
                                }
                              })
                            : _c("p", [
                                _vm._v(
                                  _vm._s(_vm.editParam.registeredResidence)
                                )
                              ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "离退休时间", prop: "retiredDay" } },
                        [
                          _vm.isEdit
                            ? _c("el-date-picker", {
                                attrs: {
                                  placeholder: "请选择",
                                  type: "date",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.editParam.retiredDay,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "retiredDay", $$v)
                                  },
                                  expression: "editParam.retiredDay"
                                }
                              })
                            : _c("p", [
                                _vm._v(_vm._s(_vm.editParam.retiredDay))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right_module" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "身份证号", prop: "peopleId" } },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.peopleId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "peopleId", $$v)
                                  },
                                  expression: "editParam.peopleId"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.peopleId))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "phone" } },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editParam, "phone", $$v)
                                  },
                                  expression: "editParam.phone"
                                }
                              })
                            : _c("p", [_vm._v(_vm._s(_vm.editParam.phone))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "共享卡号", prop: "disaccountCardNo" }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.disaccountCardNo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editParam,
                                      "disaccountCardNo",
                                      $$v
                                    )
                                  },
                                  expression: "editParam.disaccountCardNo"
                                }
                              })
                            : _c("p", [
                                _vm._v(_vm._s(_vm.editParam.disaccountCardNo))
                              ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "离退休证号", prop: "retiredCardNo" }
                        },
                        [
                          _vm.isEdit
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.editParam.retiredCardNo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editParam,
                                      "retiredCardNo",
                                      $$v
                                    )
                                  },
                                  expression: "editParam.retiredCardNo"
                                }
                              })
                            : _c("p", [
                                _vm._v(_vm._s(_vm.editParam.retiredCardNo))
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btn_view" },
                  [
                    _vm.isEdit
                      ? [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showDetail = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.tapEditConfirm }
                            },
                            [_vm._v("保存")]
                          )
                        ]
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                _vm.isEdit = true
                              }
                            }
                          },
                          [_vm._v("编辑资料")]
                        )
                  ],
                  2
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }