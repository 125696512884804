<template>
  <div class="vip_exchange_box">
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">用户信息：
        <el-input v-model="param.studentInfo" placeholder="请输入姓名" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">来源渠道：
        <el-select v-model="param.sourceType" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option label="全部" value=""></el-option>
          <el-option label="在线购买" value="MINI_PAYMENT"/>
          <el-option label="会员卡" value="VIP_CARD"/>
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">单位名称：
        <el-input v-model="param.groupName" placeholder="请输入姓名" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">会员状态：
        <el-select v-model="param.status" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option label="全部" value=""></el-option>
          <el-option label="未激活" value="UN_ACTIVATION"/>
          <el-option label="使用中" value="ACTIVATED"/>
          <el-option label="已过期" value="EXPIRE"/>
          <el-option label="已作废" value="DISABLE"/>
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">有效期限：
        <el-date-picker v-model="distDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="onChangeDate"></el-date-picker>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResettingSearch">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        prop="studentName"
        label="会员姓名"
      />
      <el-table-column
        prop="studentPhone"
        label="会员手机号"
      />
      <el-table-column
        prop="sourceType"
        label="来源渠道"
      >
      <template slot-scope="scope">
          <span v-if="scope.row.sourceType === 'MINI_PAYMENT'">在线购买</span>
          <span v-if="scope.row.sourceType === 'VIP_CARD'">会员卡</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="groupName"
        label="单位名称"
      />
      <el-table-column
        prop="status"
        label="会员状态"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'VIP'">使用中</span>
          <span v-if="scope.row.status === 'EXPIRE'">已过期</span>
          <span v-if="scope.row.status === 'DISABLE'">已作废</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="surplusDays"
        label="剩余天数"
      />
      <el-table-column
        prop="firstTime"
        label="首次购买日期"
      />
      <el-table-column
        prop="activeTime"
        label="最新续费日期"
      />
      <el-table-column
        prop="expireTime"
        label="有效期限"
      />
      <!-- <el-table-column
        fixed="right"
        label="续费记录"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="tapItemDetail(scope.row)">详情</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.pageSize" @pagination="getData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import Pagination from '@/components/Pagination'
import { getVIPListApi } from '@/api/vip'
export default {
  name: 'WhiteList',
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        pageSize: 10,
        studentInfo: '',
        sourceType: '',
        groupName: '',
        status: '',
        expireStartTime: '',
        expireEndTime: '',
      },
      distDate: [],
      total: 0,
      dataList: [],
      listLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getData()
  },
  methods: {
    tapResettingSearch () {
      this.param.studentInfo= '';
      this.param.sourceType= '';
      this.param.groupName= '';
      this.param.status= '';
      this.param.expireStartTime= '';
      this.param.expireEndTime= '';
      this.distDate = [];
      this.tapSearch()
    },
    onChangeDate (val) {
      if(val) {
        this.param.expireStartTime = val[0];
        this.param.expireEndTime = val[1];
        this.tapSearch()
      }
    },
    tapSearch() {
      this.param.page = 0;
      this.getData()
    },
    getData () {
      this.listLoading = true;
      let params = Object.keys(this.param).reduce((pre, key) => {
        if(key !== 'page' && key !== 'pageSize') {
          if(this.param[key]) pre[key] = this.param[key];
          return pre;
        } else {
          pre[key] = this.param[key];
          return pre;
        }
      }, {})
      getVIPListApi(params).then(res => {
        this.listLoading = false
        this.dataList = res.result.content
        this.total = res.result.totalElements
      })
    },
    tapItemDetail (item) {
      
    }
  }
}
</script>

<style lang="scss" scoped>
.vip_exchange_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
 .dialog_radio_row{
   .el-input,.el-select{
     width: 365px;
     margin-left: 20px;
   }
   margin-bottom: 10px;
   &:last-child{
     margin-bottom: 0;
   }
 }
 /deep/.white_list_drawer{
   .el-drawer__header{
     margin-bottom: 20px!important;
   }
   .el-drawer__body{
     padding: 0 20px 20px;
     overflow: auto;
   }
 }
</style>