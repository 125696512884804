<template>
  <div class="white_list_box">
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">学员姓名：
        <el-input v-model="param.realName" placeholder="请输入姓名" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">学员手机号：
        <el-input v-model="param.phone" placeholder="请输入手机号" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResettingSearch">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
      <el-button type="primary" class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapCreate">添加分组</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        fixed
        prop="name"
        label="分组名称"
      />
      <el-table-column
        prop="term"
        label="所属学期"
      >
        <template slot-scope="scope">
          <span>{{scope.row.term.name}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="courseNames"
        label="课程范围"
      >
      <template slot-scope="scope">
          <span>{{scope.row.courseNames.map(item => item.name).join()}}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="studentCount"
        label="用户数量"
      />
      <el-table-column
        prop="enabled"
        label="状态"
      >
        <template slot-scope="scope">
          <span>{{scope.row.enabled ? '启用' : '停用'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
      />
      <el-table-column
          fixed="right"
          label="操作"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="tapEditItem(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.enabled" type="text" size="small" @click="tapItemStatus(scope.row)">停用</el-button>
            <el-button v-else type="text" size="small" @click="tapItemStatus(scope.row)">恢复</el-button>
            <el-button type="text" size="small" @click="tapItemDetail(scope.row)">查看名单</el-button>
            <el-button type="text" size="small" @click="tapAddStudents(scope.row)">添加学员</el-button>
          </template>
        </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.pageSize" @pagination="getData" />
    <!-- detail -->

    <el-drawer class="white_list_drawer" title="白名单详情" :visible.sync="showDetail" size="90%">
      <div class="flex align-center flex-wrap">
        <div class="flex-center margin-right-30 margin-bottom-20">学员名称：
          <el-input v-model="detailParam.studentName" placeholder="请输入名称" style="width: 150px;"></el-input>
        </div>
        <div class="flex-center margin-right-30 margin-bottom-20">学员手机号：
          <el-input v-model="detailParam.studentPhone" placeholder="请输入手机号" style="width: 150px;"></el-input>
        </div>
        <div class="flex-center margin-right-30 margin-bottom-20">状态：
          <el-select style="width: 150px;" v-model="detailParam.state">
            <el-option label="全部" value="ALL"></el-option>
            <el-option label="启用" value="ENABELD"></el-option>
            <el-option label="停用" value="DISABLED"></el-option>
          </el-select>
        </div>
        <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="getWhiteListDetail">查看</el-button>
        <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResetting">重置</el-button>
      </div>
      <el-table
        v-loading="listLoading1"
        :data="detailList"
        border
        style="width: 1624px;"
      >
        <el-table-column
          fixed
          prop="parent"
          label="分组名称"
        />
        <el-table-column
          prop="termName"
          label="所属学期"
        />
        <!-- <el-table-column
          prop="courseName"
          label="课程名称"
        /> -->
        <el-table-column
          prop="studentName"
          label="用户姓名"
        />
        <el-table-column
          prop="studentPhone"
          label="用户手机号"
        />
        <el-table-column
          prop="enabled"
          label="状态"
        >
          <template slot-scope="scope">
            <span>{{scope.row.enabled ? '启用' : '停用'}}</span>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="200"
            align="center"
          >
            <template slot-scope="scope">
              <el-button v-if="scope.row.enabled" type="text" size="small" @click="tapItemDetailStatus(scope.row)">停用</el-button>
              <el-button v-else type="text" size="small" @click="tapItemDetailStatus(scope.row)">恢复</el-button>
            </template>
          </el-table-column>
      </el-table>
      <pagination v-show="totalDetail>0 && !listLoading1" :total="totalDetail" :page.sync="detailParam.page" :pagesize.sync="detailParam.count" @pagination="getWhiteListDetail" />
    </el-drawer>

    <!-- 创建，编辑公告 -->
    <el-dialog :title="createParam.id ? '编辑分组' : '新建分组'" :visible.sync="showCreate" width="630px">
      <el-form :model="createParam" :rules="rules" ref="form" label-width="120px">
        <el-form-item label="分组名称" prop="name">
          <el-input v-model="createParam.name" ></el-input>
        </el-form-item>
        <el-form-item label="所属学期" prop="termId">
          <el-select class="width-100" v-model="createParam.termId" placeholder="请选择" @change="getCourseList">
            <el-option v-for="item in termList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程范围" prop="reduceType">
          <div class="dialog_radio_row">
            <el-radio v-model="createParam.reduceType" label="1">课程名称 
              <el-select class="width-100" v-model="createParam.courses" placeholder="请选择" multiple collapse-tags>
                <el-option v-for="item in courseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-radio>
          </div>
          <div class="dialog_radio_row">
            <el-radio v-model="createParam.reduceType" label="2">全部课程</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="减免数量" prop="freeType">
          <div class="dialog_radio_row">
            <el-radio v-model="createParam.freeType" label="1">减免数量
              <el-input class="width-100" v-model="createParam.freeCount" placeholder="输入免费课程数量"></el-input>
            </el-radio>
          </div>
          <div class="dialog_radio_row">
            <el-radio v-model="createParam.freeType" label="2">全部减免</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="添加用户名单" prop="createType">
          <div class="dialog_radio_row">
            <el-radio v-model="createParam.createType" label="1">模板导入 
              <el-upload
                :action="uploadUrl"
                :data="dataObj"
                class="margin-left display-i-b"
                :on-change="onBeforeUpload"
                :on-success="onSuccessUpload"
                :show-file-list="false"
                :file-list="fileList">
                <el-button>导入名单</el-button>
              </el-upload>
              <el-button @click="tapTemplate">下载模板</el-button>
            </el-radio>
            <div v-for="item in fileList" :key="item.name">
              {{item.name}}
              <span class="el-icon-close margin-left" @click="fileList = []"></span>
            </div>
          </div>
          <div class="dialog_radio_row">
            <el-radio v-model="createParam.createType" label="2">输入用户手机号
            </el-radio>
            <div><el-input type="textarea" :rows="4" v-model="createParam.phones" placeholder="每个手机号占据一行"></el-input></div>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" :rows="4" v-model="createParam.remark" placeholder="最多200字"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreate = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加学员 -->
    <el-dialog title="添加学员" :visible.sync="showAdd" width="630px">
      <el-form :model="addParam" :rules="rulesAdd" ref="form" label-width="120px">
        <el-form-item label="用户姓名" prop="name">
          <el-input v-model="addParam.name" ></el-input>
        </el-form-item>
        <el-form-item label="用户手机号" prop="phone">
          <el-input v-model="addParam.phone" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showAdd = false">取 消</el-button>
        <el-button type="primary" @click="tapAddConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'
// import SubTitle from '@/components/SubTitle/index'
import Pagination from '@/components/Pagination'
import { getWhiteList, getWhiteInfo, createWhiteList, updateWhiteList, getTermList, getCourseList, 
  statusWhiteList, getWhiteListDetail, statusWhiteListDetail, addStudentWhiteList } from '@/api/white-list'
export default {
  name: 'WhiteList',
  components: {
    // SubTitle,
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        pageSize: 10,
        realName: '',
        phone: '',
      },
      total: 0,
      dataList: [],
      fileList: [],
      termList: [],
      courseList: [],
      listLoading: true,
      showCreate: false,
      createParam: {
        id: '',
        name: '',
        termId: '',
        courses: [],
        phones: '',
        remark: '',
        fileId: '',
        freeCount: '',
        freeType: '1',
        reduceType: '1',
        createType: '2',
      },
      rules: {
        name: [
          { required: true, trigger: 'submit', message: '请填写分组名称' }
        ],
        termId: [
          { required: true, trigger: 'submit', message: '请选择所属学期' }
        ],
        reduceType: [
          { required: true, trigger: 'submit', validator: (rule, value, cb) => {
            if(value == '1' && !this.createParam.courses.length) {
              return cb(new Error('请选择课程'))
            }
            cb()
          }}
        ],
        freeType: [
          { required: true, trigger: 'submit', validator: (rule, value, cb) => {
            if(value == '1' && (!this.createParam.freeCount || this.createParam.freeCount < 1)) {
              return cb(new Error('请填写减免数量'))
            }
            cb()
          }}
        ],
        createType: [
          { required: true, trigger: 'submit', validator: (rule, value, cb) => {
            if(value == '1' && !this.createParam.fileId) {
              return cb(new Error('请导入名单'))
            } else if(value == '2' && !this.createParam.phones) {
              return cb(new Error('请输入用户手机号'))
            }
            cb()
          }}
        ],
      },
      showAdd: false,
      addParam: {
        id: '',
        name: '',
        phone: '',
      },
      rulesAdd: {
        phone: [
          { required: true, trigger: 'submit', message: '请填写用户手机号' }
        ],
      },
      uploadUrl: getEnv()[1] + 'file_upload',
      dataObj: { token: getToken() },
      // detail
      showDetail: false,
      listLoading1: false,
      detailList: [],
      totalDetail: 0,
      detailParam: {
        id: '',
        course: '',
        state: 'ALL',
        studentName: '',
        studentPhone: '',
        page: 0,
        count: 10,
      },
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getData()
    this.getOptions()
    if(this.$route.query.create) this.showCreate = true;
  },
  methods: {
    tapResettingSearch () {
      this.param.realName = '';
      this.param.phone = '';
      this.tapSearch()
    },
    tapSearch() {
      this.param.page = 0;
      this.getData()
    },
    getData () {
      this.listLoading = true;
      getWhiteList({ ...this.param }).then(res => {
        this.listLoading = false
        this.dataList = res.result.content
        this.total = res.result.totalElements
      })
    },
    getOptions () {
      getTermList({ schoolId: this.school_id, page: 0, count: 100 }).then(res => {
        this.termList = res.result.items
      })
    },
    getCourseList (val) {
      getCourseList({ termId: val }).then(res => {
        this.courseList = res.result
      })
    },
    tapCreate () {
      for(let k in this.createParam) {
        this.createParam[k] = '';
      }
      this.fileList = [];
      this.createParam.courses = [];
      this.createParam.createType = '2';
      this.createParam.reduceType = '1';
      this.createParam.freeType = '1';
      this.showCreate = true;
    },
    tapTemplate () {
      window.open('https://file.muketang.com/temp/%E7%99%BD%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx')
    },
    onBeforeUpload (file) {
      this.fileList = [file]
    },
    onSuccessUpload (res) {
      console.log(res)
      if(res.code == 0) {
        this.createParam.fileId = res.result.id;
      } else {
        this.$message.error(res.errorMsg)
      }
    },
    tapConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          var params = { ...this.createParam, token: this.dataObj.token };
          params.phones = params.phones.replace(/\n/g, ',')
          if(params.reduceType == '2') params.courses = [];
          if(params.freeType == '2')  params.freeCount = '-1';
          if(params.createType == '1') {
            params.phones = '';
          } else {
            params.fileId = '';
          }
          delete params.freeType;
          delete params.reduceType;
          delete params.createType;
          if(this.createParam.id) {
            updateWhiteList(params).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          } else {
            delete params.id;
            createWhiteList(params).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          }
        }
      })
    },
    tapEditItem (item) {
      getWhiteInfo({ id: item.id }).then(res => {
        this.createParam.id = res.result.id;
        this.createParam.name = res.result.name;
        this.createParam.termId = res.result.term.id;
        this.createParam.remark = res.result.remark;
        this.createParam.freeCount = res.result.freeCount;
        if(res.result.courseNames && res.result.courseNames.length) {
          this.createParam.reduceType = '1';
          this.createParam.courses = res.result.courseNames.map(item => item.id)
        } else {
          this.createParam.reduceType = '2';
          this.createParam.courses = [];
        }
        if(res.result.freeCount > 0) {
          this.createParam.freeType = '1';
        } else {
          this.createParam.freeCount = '';
          this.createParam.freeType = '2';
        }
        if(res.result.file) {
          this.fileList = [res.result.file];
          this.createParam.phones = '';
          this.createParam.createType = '1';
          this.createParam.fileId = res.result.file.id;
        } else {
          this.fileList = [];
          this.createParam.fileId = '';
          this.createParam.createType = '2';
          this.createParam.phones = res.result.phones.replace(/\,/g, '\n')
        }
        this.getCourseList(res.result.term.id)
        this.showCreate = true;
      })
    },
    tapItemStatus (row) {
      let param = { enabled: !row.enabled, id: row.id }
      statusWhiteList(param).then(res => {
        this.$message.success('操作成功')
        this.getData()
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    tapItemDetail (row) {
      this.tapResetting()
      this.detailParam.id = row.id;
      this.getWhiteListDetail()
      this.showDetail = true;
    },
    tapResetting () {
      this.detailParam.course = '';
      this.detailParam.state = 'ALL';
      this.detailParam.studentName = '';
      this.detailParam.studentPhone = '';
    },
    getWhiteListDetail () {
      this.listLoading1 = true;
      getWhiteListDetail({ ...this.detailParam }).then(res => {
        this.listLoading1 = false;
        this.detailList = res.result.items;
        this.totalDetail = res.result.total;
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    tapItemDetailStatus (row) {
      let param = { enabled: !row.enabled, id: row.id }
      statusWhiteListDetail(param).then(res => {
        this.$message.success('操作成功')
        this.getWhiteListDetail()
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    tapAddStudents (item) {
      this.addParam = {
        id: item.id,
        name: '',
        phone: '',
      }
      this.showAdd = true;
    },
    tapAddConfirm () {
      addStudentWhiteList({ ...this.addParam, token: this.dataObj.token }).then(res => {
        this.$message.success('操作成功')
        this.showAdd = false;
        this.getData()
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.white_list_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
 .dialog_radio_row{
   .el-input,.el-select{
     width: 365px;
     margin-left: 20px;
   }
   margin-bottom: 10px;
   &:last-child{
     margin-bottom: 0;
   }
 }
 /deep/.white_list_drawer{
   .el-drawer__header{
     margin-bottom: 20px!important;
   }
   .el-drawer__body{
     padding: 0 20px 20px;
     overflow: auto;
   }
 }
</style>