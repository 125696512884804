var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index_page" }, [
    _c("div", { staticClass: "module_box" }, [
      _c("p", { staticClass: "module_title" }, [_vm._v("常用操作")]),
      _vm._v(" "),
      _c("div", { staticClass: "module_flex_bet" }, [
        _c(
          "div",
          { staticClass: "term_amount" },
          [
            _c("p", [_vm._v(_vm._s(_vm.baseData.today))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.baseData.currentTermName))]),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c("p", { staticClass: "module_flex_bet" }, [
              _c("span", [_vm._v("本学期学员")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.baseData.currentTermStudentCount) + "人")
              ])
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "module_flex_bet" }, [
              _c("span", [_vm._v("本学期课程")]),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.baseData.currentTermCourseCount) + "门")
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "route_view" }, [
          _c(
            "div",
            {
              staticClass: "route_top",
              on: {
                click: function($event) {
                  return _vm.tapJump("/academic/administrator")
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: { className: "avg_icon", iconClass: "academic" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("班主任管理")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "route_bom",
              on: {
                click: function($event) {
                  return _vm.tapJump("/academic/administrator?create=true")
                }
              }
            },
            [_vm._v("新建班主任")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "route_view" }, [
          _c(
            "div",
            {
              staticClass: "route_top",
              on: {
                click: function($event) {
                  return _vm.tapJump("/student-manager/white-list")
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: { className: "avg_icon", iconClass: "student" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("白名单管理")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "route_bom",
              on: {
                click: function($event) {
                  return _vm.tapJump("/student-manager/white-list?create=true")
                }
              }
            },
            [_vm._v("新建白名单")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "route_view" }, [
          _c(
            "div",
            {
              staticClass: "route_top",
              on: {
                click: function($event) {
                  return _vm.tapJump("/teacher-manager")
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: { className: "avg_icon", iconClass: "teacher" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("老师管理")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "route_bom",
              on: {
                click: function($event) {
                  return _vm.tapJump("/teacher-manager?create=true")
                }
              }
            },
            [_vm._v("新建老师")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "route_view" }, [
          _c(
            "div",
            {
              staticClass: "route_top",
              on: {
                click: function($event) {
                  return _vm.tapJump("/course-manager")
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: { className: "avg_icon", iconClass: "course" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("课程管理")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "route_bom",
              on: {
                click: function($event) {
                  return _vm.tapJump("/course-create?schoolId=" + _vm.school_id)
                }
              }
            },
            [_vm._v("新建课程")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "route_view" }, [
          _c(
            "div",
            {
              staticClass: "route_top",
              on: {
                click: function($event) {
                  return _vm.tapJump("/term-manager")
                }
              }
            },
            [
              _c("svg-icon", {
                attrs: { className: "avg_icon", iconClass: "term" }
              }),
              _vm._v(" "),
              _c("p", [_vm._v("学期管理")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "route_bom",
              on: {
                click: function($event) {
                  return _vm.tapJump("/term-manager?create=true")
                }
              }
            },
            [_vm._v("新建学期")]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "module_box" },
      [
        _c("p", { staticClass: "module_title" }, [_vm._v("数据统计")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("el-divider", { staticClass: "margin5" }),
        _vm._v(" "),
        _c("div", { staticClass: "module_flex_bet" }, [
          _c("div", { staticClass: "flex1" }, [
            _vm._v(_vm._s(_vm.baseData.termCountData.termName))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex1 bold_text" }, [
            _vm._v(_vm._s(_vm.baseData.termCountData.signStudentCount))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex1 bold_text" }, [
            _vm._v(_vm._s(_vm.baseData.termCountData.signCount))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex1 bold_text" }, [
            _vm._v(_vm._s(_vm.baseData.termCountData.signFeeCount))
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.baseData.termData, function(item) {
          return [
            _c("div", { key: item.termId, staticClass: "module_flex_bet" }, [
              _c("div", { staticClass: "flex1" }, [
                _vm._v(_vm._s(item.termName))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex1" }, [
                _vm._v(_vm._s(item.signStudentCount))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex1" }, [
                _vm._v(_vm._s(item.signCount))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex1" }, [
                _vm._v(_vm._s(item.signFeeCount))
              ])
            ])
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "module_box" }, [
      _c("p", { staticClass: "module_title" }, [_vm._v("今日课程")]),
      _vm._v(" "),
      _vm.todayCourseData.length
        ? _c(
            "div",
            { staticClass: "module_flex_bet" },
            [
              _vm._l(_vm.todayCourseData, function(item) {
                return [
                  _c(
                    "div",
                    { key: item.name, staticClass: "live_view border" },
                    [
                      _c("img", { attrs: { src: item.cover, alt: "" } }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "live_info" },
                        [
                          _c("div", { staticClass: "module_flex_bet" }, [
                            _c("div", { staticClass: "bold_text" }, [
                              _c("p", { staticClass: "course_name" }, [
                                _vm._v(_vm._s(item.name))
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    item.status === "ENDLIVE"
                                      ? "已结束"
                                      : item.status === "UNLIVE"
                                      ? "未开始"
                                      : "直播中"
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "bold_text" }, [
                              _c("p", [_vm._v("观看人数")]),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(item.studentCount))])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "bold_text" }, [
                              _c("p", [_vm._v("观看次数")]),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(item.viewCount))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-divider"),
                          _vm._v(" "),
                          _c("div", { staticClass: "module_flex_bet" }, [
                            _c("span", [
                              _vm._v("开始时间：" + _vm._s(item.startTime))
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v("结束时间：" + _vm._s(item.endTime))
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]
              })
            ],
            2
          )
        : _c("p", { staticClass: "null_text" }, [_vm._v("暂无课程")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "module_flex_bet" }, [
      _c(
        "div",
        { staticClass: "module_box half_module" },
        [
          _c("p", { staticClass: "module_title" }, [_vm._v("明日课程")]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("el-divider", { staticClass: "margin5" }),
          _vm._v(" "),
          _vm._l(_vm.tomorrowData, function(item, index) {
            return [
              _c("div", { key: index, staticClass: "module_flex_bet" }, [
                _c(
                  "div",
                  { staticClass: "header_view" },
                  [
                    _c("el-avatar", {
                      staticStyle: { width: "30px", height: "30px" },
                      attrs: { src: item.teacherHead }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "w100" }, [
                  _vm._v(_vm._s(item.teacherName))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w100 bold_text" }, [
                  _vm._v(_vm._s(item.teacherPhone))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex1" }, [
                  _c("p", [_vm._v(_vm._s(item.courseName))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex1" }, [
                  _c("p", [_vm._v(_vm._s(item.lessonName))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w100" }, [
                  _vm._v(_vm._s(item.classAdminName))
                ])
              ])
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_box half_module" },
        [
          _c("p", { staticClass: "module_title" }, [_vm._v("白名单添加记录")]),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("el-divider", { staticClass: "margin5" }),
          _vm._v(" "),
          _vm._l(_vm.whiteListData, function(item, index) {
            return [
              _c("div", { key: index, staticClass: "module_flex_bet" }, [
                _c("div", { staticClass: "flex1" }, [
                  _vm._v(_vm._s(item.createTime))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w100" }, [
                  _vm._v(_vm._s(item.whitelistName))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w100" }, [
                  _vm._v(_vm._s(item.studentCount))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex1" }, [
                  _vm._v(_vm._s(item.courseNames))
                ])
              ])
            ]
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "module_flex_bet" }, [
      _c(
        "div",
        { staticClass: "module_box half_module" },
        [
          _c("p", { staticClass: "module_title" }, [_vm._v("最新报名")]),
          _vm._v(" "),
          _c("div", { staticClass: "module_flex_bet" }, [
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signInfoData.yesterdaySignCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("昨日人数")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signInfoData.todaySignCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("今日人数")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signInfoData.currentMonthSignCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("本月人数")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signInfoData.preMonthSignCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("上月人数")])
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c("el-divider", { staticClass: "margin5" }),
          _vm._v(" "),
          _vm._l(_vm.signInfoData.signData, function(item, index) {
            return [
              _c("div", { key: index, staticClass: "module_flex_bet" }, [
                _c("div", { staticClass: "flex1" }, [
                  _vm._v(_vm._s(item.signDate))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w100" }, [
                  _vm._v(_vm._s(item.studentName))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "w100" }, [
                  _vm._v(_vm._s(item.studentPhone))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex1" }, [
                  _vm._v(_vm._s(item.courseName))
                ])
              ])
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "module_box half_module" },
        [
          _c("p", { staticClass: "module_title" }, [_vm._v("最新缴费")]),
          _vm._v(" "),
          _c("div", { staticClass: "module_flex_bet" }, [
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signFeeInfoData.yesterdaySignFeeCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("昨日人数")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signFeeInfoData.todaySignFeeCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("今日人数")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signFeeInfoData.currentMonthSignFeeCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("本月人数")])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module_flex_bet quarter_view" }, [
              _c("div", { staticClass: "day_amount_view" }, [
                _c("p", { staticClass: "bold_text" }, [
                  _vm._v(_vm._s(_vm.signFeeInfoData.preMonthSignFeeCount))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("上月人数")])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("CountBar", {
            attrs: { barData: _vm.signFeeInfoData.signFeeGroupByCourse }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.subSchoolData.termData && _vm.subSchoolData.termData.termId
      ? _c(
          "div",
          { staticClass: "module_box" },
          [
            _c("div", { staticClass: "module_title" }, [
              _c("span", [_vm._v("分校信息")]),
              _vm._v(" "),
              _c("p", { staticClass: "right_view" }, [
                _vm._v(
                  "\n        学期: " +
                    _vm._s(_vm.subSchoolData.termData.termName) +
                    "\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "module_flex_bet" }, [
              _c("div", { staticClass: "flex1" }, [
                _vm._v(_vm._s(_vm.subSchoolData.termData.signStudentCount))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex1" }, [
                _vm._v(_vm._s(_vm.subSchoolData.termData.signCount))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex1" }, [
                _vm._v(_vm._s(_vm.subSchoolData.termData.signFeeCount))
              ])
            ]),
            _vm._v(" "),
            _c("el-divider", { staticClass: "margin5" }),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("p", { staticClass: "module_title size12" }, [
              _vm._v("今日课程")
            ]),
            _vm._v(" "),
            _vm.subSchoolData.todayCourseData.length
              ? _c(
                  "div",
                  { staticClass: "module_flex_bet" },
                  [
                    _vm._l(_vm.subSchoolData.todayCourseData, function(
                      item,
                      index
                    ) {
                      return [
                        _c(
                          "div",
                          { key: index, staticClass: "live_view border" },
                          [
                            _c("img", { attrs: { src: item.cover, alt: "" } }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "live_info" },
                              [
                                _c("div", { staticClass: "module_flex_bet" }, [
                                  _c("div", { staticClass: "bold_text" }, [
                                    _c("p", { staticClass: "course_name" }, [
                                      _vm._v(_vm._s(item.name))
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          item.status === "ENDLIVE"
                                            ? "已结束"
                                            : item.status === "UNLIVE"
                                            ? "未开始"
                                            : "直播中"
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "bold_text" }, [
                                    _c("p", [_vm._v("观看人数")]),
                                    _vm._v(" "),
                                    _c("p", [_vm._v(_vm._s(item.studentCount))])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "bold_text" }, [
                                    _c("p", [_vm._v("观看次数")]),
                                    _vm._v(" "),
                                    _c("p", [_vm._v(_vm._s(item.viewCount))])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("el-divider"),
                                _vm._v(" "),
                                _c("div", { staticClass: "module_flex_bet" }, [
                                  _c("span", [
                                    _vm._v(
                                      "开始时间：" + _vm._s(item.startTime)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v("结束时间：" + _vm._s(item.endTime))
                                  ])
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              : _c("p", { staticClass: "null_text" }, [_vm._v("暂无课程")])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module_flex_bet" }, [
      _c("div", { staticClass: "flex1 bold_text" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex1 bold_text" }, [_vm._v("报名学员")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1 bold_text" }, [_vm._v("报名次数")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1 bold_text" }, [_vm._v("收入金额")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module_flex_bet" }, [
      _c("div", { staticClass: "header_img" }),
      _vm._v(" "),
      _c("div", { staticClass: "w100 bold_text" }, [_vm._v("老师名称")]),
      _vm._v(" "),
      _c("div", { staticClass: "w100 bold_text" }, [_vm._v("老师手机号")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1 bold_text" }, [_vm._v("课程名称")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1 bold_text" }, [_vm._v("子课节名称")]),
      _vm._v(" "),
      _c("div", { staticClass: "w100 bold_text" }, [_vm._v("班主任")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module_flex_bet" }, [
      _c("div", { staticClass: "flex1" }, [_vm._v("添加时间")]),
      _vm._v(" "),
      _c("div", { staticClass: "w100" }, [_vm._v("白名单名称")]),
      _vm._v(" "),
      _c("div", { staticClass: "w100" }, [_vm._v("人数")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1" }, [_vm._v("课程范围")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module_flex_bet" }, [
      _c("div", { staticClass: "flex1" }, [_vm._v("报名时间")]),
      _vm._v(" "),
      _c("div", { staticClass: "w100" }, [_vm._v("用户姓名")]),
      _vm._v(" "),
      _c("div", { staticClass: "w100" }, [_vm._v("用户手机号")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1" }, [_vm._v("报名课程")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "module_flex_bet" }, [
      _c("div", { staticClass: "flex1" }, [_vm._v("报名学员")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1" }, [_vm._v("报名次数")]),
      _vm._v(" "),
      _c("div", { staticClass: "flex1" }, [_vm._v("收入金额")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }