<template>
  <div class="vip_grant_box">
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">发放日期：
        <el-date-picker v-model="distDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="onChangeDate"></el-date-picker>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">客户名称：
        <el-input v-model="param.name" placeholder="请输入客户名称" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResettingSearch">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
      <el-button type="primary" class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapCreate">新建</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        prop="createTime"
        label="发放日期"
      />
      <el-table-column
        prop="name"
        label="客户名称"
      />
      <el-table-column
        prop="count"
        label="创建数量"
      />
      <el-table-column
        prop="activeCount"
        label="使用数量"
      />
      <el-table-column
        prop="startTime"
        label="开始日期"
      />
      <el-table-column
        prop="endTime"
        label="结束日期"
      />
      <el-table-column
          fixed="right"
          label="详情"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="tapDownload(scope.row)">下载会员卡明细</el-button>
          </template>
        </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.pageSize" @pagination="getData" />

    <!-- 卡券发放 -->
    <el-dialog custom-class="vip_card_create_dialog" title="添加学员" :visible.sync="showAdd" width="630px">
      <el-form :model="addParam" :rules="rulesAdd" ref="addForm" label-width="120px">
        <el-form-item label="客户名称" prop="req.name">
          <el-input v-model="addParam.req.name" ></el-input>
        </el-form-item>
        <el-form-item label="兑换日期" prop="req.startTime">
          <el-date-picker v-model="addParam.timeArr" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="onChangeTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="导入会员卡明细" prop="file">
          <div class="flex align-start">
            <div class="upload_box">
              <!-- <el-button size="small" type="primary">导入</el-button>
              <input type="file" class="file_input" @change="onChangeFile" /> -->
              <el-upload
                ref="upload"
                :data="dataObj"
                :multiple="false"
                :file-list="fileList"
                :action="confirmAction"
                :on-change="onChangeFile"
                :on-success="onUploadSuccess"
                :on-error="onUploadError"
                :on-remove="onRemove"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              </el-upload>
            </div>
            <el-link type="primary" :underline="false" href="https://file.muketang.com/temp/vipcarddisttemplate.xlsx">下载模板</el-link>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showAdd = false">取 消</el-button>
        <el-button type="primary" @click="tapAddConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'
import Pagination from '@/components/Pagination'
import { getVIPCardGroupListApi, postVIPCardDisableApi } from '@/api/vip'
export default {
  name: 'WhiteList',
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        pageSize: 10,
        startDistDate: '',
        endDistDate: '',
        name: '',
      },
      distDate: [],
      total: 0,
      dataList: [],
      listLoading: true,
      showAdd: false,
      addParam: {
        file: null,
        timeArr: [],
        req: {          
          name: '',
          startTime: '',
          endTime: '',
        },
      },
      fileList: [],
      dataObj: {
        token: getToken()
      },
      rulesAdd: {
        req: {
          name: [
            { required: true, trigger: 'submit', message: '请填写客户名称' }
          ],
          startTime: [
            { required: true, trigger: 'submit', message: '请选择兑换日期' }
          ],
        },
        file: [
          { required: true, trigger: 'submit', message: '请选择导入会员卡明细' }
        ],
      },
      confirmAction: getEnv()[1] + '/school/vipcard/dist',
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getData()
    if(this.$route.query.create) this.showCreate = true;
  },
  methods: {
    tapResettingSearch () {
      this.param.name = '';
      this.param.startDistDate = '';
      this.param.endDistDate = '';
      this.distDate = [];
      this.tapSearch()
    },
    onChangeDate (val) {
      if(val) {
        this.param.startDistDate = val[0];
        this.param.endDistDate = val[1];
        this.tapSearch()
      }
    },
    tapSearch() {
      this.param.page = 0;
      this.getData()
    },
    getData () {
      this.listLoading = true;
      let params = Object.keys(this.param).reduce((pre, key) => {
        if(key !== 'page' && key !== 'pageSize') {
          if(this.param[key]) pre[key] = this.param[key];
          return pre;
        } else {
          pre[key] = this.param[key];
          return pre;
        }
      }, {})
      getVIPCardGroupListApi(params).then(res => {
        this.listLoading = false
        this.dataList = res.result.content
        this.total = res.result.totalElements
      })
    },
    tapDownload () {

    },
    onChangeTime (val) {
      if(val) {
        this.addParam.req.startTime = val[0];
        this.addParam.req.endTime = val[1];
      } else {
        this.addParam.req.startTime = '';
        this.addParam.req.endTime = '';
      }
    },
    onChangeFile (e) {
      if(e.size) {
        this.addParam.file = e.raw;
      } else {
        this.addParam.file = null;
      }
    },
    onRemove () {
      this.addParam.file = null;
    },
    onUploadSuccess (res) {
      console.log(res)
      if(res.code == 0) {
        this.$message.success('操作成功')
        this.showAdd = false;
        this.getData()
      } else {
        this.$message.error(res.errorMsg)
      }
    },
    onUploadError (res) {
      this.$message.error(err.errorMsg)
    },
    tapCreate (item) {
      this.addParam = {
        file: null,
        timeArr: [],
        req: {          
          name: '',
          startTime: '',
          endTime: '',
        },
      },
      this.fileList = []
      this.showAdd = true;
    },
    tapAddConfirm () {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$set(this.dataObj, 'name', this.addParam.req.name)
          this.$set(this.dataObj, 'startTime', this.addParam.req.startTime)
          this.$set(this.dataObj, 'endTime', this.addParam.req.endTime)
          this.$refs.upload.submit();
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.vip_grant_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
.vip_card_create_dialog{
  .upload_box{
    margin-right: 20px;
    position: relative;
    .el-button{
      height: 40px;
    }
    .file_input{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0; top: 0;
      opacity: 0;
    }
  }
}
</style>