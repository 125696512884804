import request from '@/utils/request'

export function getCateList() {
  return request({
    url: '/school/course/cate/all',
    type: 'get'
  }, true)
}

export function addCateItem(data) {
  return request({
    url: '/school/course/cate/add',
    data: data
  })
}

export function editCateItem(data) {
  return request({
    url: '/school/course/cate/edit',
    data: data
  })
}

export function delCateItem(data) {
  return request({
    url: '/school/course/cate/delete',
    data: data
  })
}