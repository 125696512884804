import request from '@/utils/request'

export function getSchoolInfo() {
  return request({
    url: '/school/manage/getinfo',
    type: 'get'
  }, true)
}

export function saveSchoolInfo(data) {
  return request({
    url: '/school/manage/editcontactinfo',
    data: data
  })
}

export function saveSchoolIntro(data) {
  return request({
    url: '/school/manage/editintro',
    data: data
  })
}

export function saveSchoolRegister(data) {
  return request({
    url: '/school/manage/editregistrationagreement',
    data: data
  })
}

export function saveSchoolVerify(data) {
  return request({
    url: '/school/manage/editstudentinfoverify',
    data: data
  })
}
