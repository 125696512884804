var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "count_data_box" }, [
    _c(
      "div",
      { staticClass: "search_box" },
      [
        _c(
          "el-select",
          {
            attrs: { placeholder: "请选择" },
            on: { change: _vm.getData },
            model: {
              value: _vm.param.termId,
              callback: function($$v) {
                _vm.$set(_vm.param, "termId", $$v)
              },
              expression: "param.termId"
            }
          },
          [
            _vm._l(_vm.termList, function(item) {
              return [
                _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id }
                })
              ]
            })
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "count_list" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("CountBar", {
            attrs: { barData: _vm.resData.studentCountGroupByAge }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "count_list" },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("CountPie", {
            attrs: { pieData: _vm.resData.studentCountGroupBySex }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "count_list" },
        [
          _vm._m(2),
          _vm._v(" "),
          _c("CountPie", {
            attrs: { pieData: _vm.resData.studentCountGroupBySystemBelong }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "count_list" },
        [
          _vm._m(3),
          _vm._v(" "),
          _c("CountPie", {
            attrs: { pieData: _vm.resData.studentCountGroupByPoliticalStatus }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "count_title" }, [
      _c("span", [_vm._v("年龄分布")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "count_title" }, [
      _c("span", [_vm._v("性别分布")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "count_title" }, [
      _c("span", [_vm._v("所属系统分布")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "count_title" }, [
      _c("span", [_vm._v("政治面貌分布")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }