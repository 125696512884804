import request from '@/utils/request'

export function login(params) {
  return request({
    url: '/login_by_phone',
    data: params
  }, true)
}

export function getInfo(token) {
  return request({
    url: '/user',
    params: { token }
  })
}

export function schoolList(params) {
  return request({
    url: '/school_list',
    params
  })
}
