// import de from 'element-ui/src/locale/lang/de'

const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.user.roles,
  school_id: state => state.user.school_id,
  school_name: state => state.user.school_name,
  permission_routes: state => state.permission.routes
}
export default getters
