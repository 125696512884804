var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "school_fourth_box" },
    [
      _c(
        "div",
        { staticClass: "text_right" },
        [
          _c(
            "el-button",
            {
              staticStyle: { height: "32px" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.editStatus = true
                }
              }
            },
            [_vm._v("编辑资料")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.createParam,
            rules: _vm.rules,
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "结业证书", prop: "title" } },
            [
              !_vm.editStatus
                ? _c("p", { staticClass: "text_val" }, [_vm._v("hhh")])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  staticClass: "display-i-b",
                  attrs: {
                    action: _vm.uploadUrl,
                    data: _vm.dataObj,
                    "on-change": _vm.onBeforeUpload,
                    "on-success": _vm.onSuccessUpload,
                    "file-list": _vm.fileList,
                    "list-type": "picture",
                    drag: ""
                  }
                },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "a-pic-preview absolute-center",
                      attrs: { src: _vm.createParam.image, fit: "contain" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [_c("i", { staticClass: "el-icon-picture-outline" })]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text_remark" }, [
                _vm._v(
                  "请上传带有学校红章的空白结业证书模板，中间区域保持空白，尺寸为1500px*2000px"
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "文案", prop: "title" } },
            [
              !_vm.editStatus
                ? _c("p", { staticClass: "text_val" }, [_vm._v("hhh")])
                : _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      maxlength: "120",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.createParam.title,
                      callback: function($$v) {
                        _vm.$set(_vm.createParam, "title", $$v)
                      },
                      expression: "createParam.title"
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.editStatus
        ? _c(
            "div",
            { staticClass: "bom_btn" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editStatus = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.tapConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }