var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-container" },
    [
      _c(
        "div",
        { staticClass: "flex align-center flex-wrap" },
        [
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              attrs: { type: "primary" },
              on: { click: _vm.goTermCreate }
            },
            [_vm._v("新建学期")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "学期名称" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "dayBegin", label: "开始时间" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "dayEnd", label: "结束时间" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "enable", label: "是否启用" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.enable ? "是" : "否"))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "200",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.editTerm(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.termDel(scope.row.id)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0 && !_vm.listLoading,
            expression: "total>0 && !listLoading"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          pagesize: _vm.listQuery.count
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:pagesize": function($event) {
            return _vm.$set(_vm.listQuery, "count", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editId ? "编辑学期" : "新建学期",
            visible: _vm.showDialog,
            width: "830px"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c("term-create", {
            attrs: { termId: _vm.editId, show: _vm.showDialog },
            on: { emitCancel: _vm.emitCancel }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }