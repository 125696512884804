<template>
  <div class="navbar flex align-center flex-between">
    <div class="flex align-center">
      <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

      <breadcrumb class="breadcrumb-container" />
    </div>

    <div class="right-menu flex align-center">
      <div class="text-333 text-12" style="margin-right: 16px;">账号：{{ name }}</div>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper flex align-center clickable">
          <img :src="(avatar || blankPic) + '?imageView2/1/w/80/h/80'" class="user-avatar">
          <div class="text-black" style="margin-left: 8px;">{{ name }}</div>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>
              首页
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  data() {
    return {
      blankPic: 'https://txm.oss-cn-beijing.aliyuncs.com/TXM_static_material_img_1581479260default-avatar.png'
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'name'
    ])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 60px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    height: 100%;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .right-menu {
    height: 100%;
    line-height: 60px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        width: auto;
        border-left: 1px solid #E8E8E8;

        .user-avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-left: 16px;
        }

        .el-icon-caret-bottom {
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }

    .business-container {
      position: relative;
      padding-right: 30px;
      cursor:pointer;
      .text-success {
        color: #67C23A;
      }
      .text-warning {
        color: #E6A23C;
      }
      .text-danger {
        color: #F56C6C;
      }
    }
  }
}
</style>
