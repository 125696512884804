var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "110px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "学期名称", prop: "name" } },
            [
              _c("el-input", {
                ref: "name",
                staticClass: "a-input",
                attrs: { type: "text" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "开始结束时间", prop: "time" } },
            [
              _c("el-date-picker", {
                staticClass: "a-input",
                attrs: {
                  type: "daterange",
                  "range-separator": "~",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.form.time,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "time", $$v)
                  },
                  expression: "form.time"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否启用", prop: "enable" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.enable,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "enable", $$v)
                    },
                    expression: "form.enable"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { "z-index": "888" },
              attrs: { label: "招生简章", prop: "enrollmentIntro" }
            },
            [_c("div", { attrs: { id: "editor" } })]
          ),
          _vm._v(" "),
          !_vm.edit
            ? _c(
                "el-button",
                {
                  staticClass: "a-button",
                  staticStyle: { "margin-left": "80px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.termCreate }
                },
                [_vm._v("创建")]
              )
            : _c(
                "el-button",
                {
                  staticClass: "a-button",
                  staticStyle: { "margin-left": "80px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.termUpdate }
                },
                [_vm._v("编辑")]
              ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "a-button",
              on: {
                click: function($event) {
                  return _vm.backPage(false)
                }
              }
            },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }