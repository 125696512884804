var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "school_tab_box" }, [
    _c(
      "div",
      { staticClass: "text_right" },
      [
        _c(
          "el-button",
          {
            staticStyle: { height: "32px" },
            attrs: { type: "primary" },
            on: {
              click: function($event) {
                _vm.editStatus = true
              }
            }
          },
          [_vm._v("编辑资料")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.editStatus
      ? _c("div", { staticClass: "margin-bottom" }, [
          _c("div", { attrs: { id: "editor" } })
        ])
      : _c("div", {
          staticClass: "content_box",
          domProps: { innerHTML: _vm._s(_vm.schoolInfo.intro) }
        }),
    _vm._v(" "),
    _vm.editStatus
      ? _c(
          "div",
          { staticClass: "center_btn" },
          [
            _c(
              "el-button",
              {
                on: {
                  click: function($event) {
                    _vm.editStatus = false
                  }
                }
              },
              [_vm._v("取 消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.tapConfirm } },
              [_vm._v("确 定")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }