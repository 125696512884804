import request from '@/utils/request'

export function getAdminList(params) {
  return request({
    url: '/school/adminstor/list',
    type: 'get',
    params
  }, true)
}

export function createAdmin(data) {
  return request({
    url: '/school/adminstor/add',
    data
  }, true)
}

export function editAdmin(data) {
  return request({
    url: '/school/adminstor/edit',
    data
  }, true)
}

export function getAdminDetail(params) {
  return request({
    url: '/school/adminstor/get',
    type: 'get',
    params
  }, true)
}

export function delAdmin(data) {
  return request({
    url: '/school/adminstor/del',
    data
  }, true)
}

// export function getBindCodeApi(params) {
//   return request({
//     url: '/school/adminstor/getbindqrcode',
//     type: 'get',
//     params
//   }, true)
// }

export function getBindStateApi(params) {
  return request({
    url: '/school/adminstor/getbindstate',
    type: 'get',
    params
  }, true)
}