<template>
  <div class="count_data_box">
    <div class="search_box">
      <el-select v-model="param.termId" placeholder="请选择" @change="getData">
        <template v-for="item in termList">
          <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
        </template>  
      </el-select>
      <!-- <el-button size="small" @click="getData()">查看</el-button> -->
    </div>
    <div class="content">
      <div class="count_list">
        <p class="count_title">
          <span>年龄分布</span>
        </p>
        <CountBar :barData="resData.studentCountGroupByAge"></CountBar>
      </div>
      <div class="count_list">
        <p class="count_title">
          <span>性别分布</span>
        </p>
        <CountPie :pieData="resData.studentCountGroupBySex"></CountPie>
      </div>
      <div class="count_list">
        <p class="count_title">
          <span>所属系统分布</span>
        </p>
        <CountPie :pieData="resData.studentCountGroupBySystemBelong"></CountPie>
      </div>
      <div class="count_list">
        <p class="count_title">
          <span>政治面貌分布</span>
        </p>
        <CountPie :pieData="resData.studentCountGroupByPoliticalStatus"></CountPie>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CountBar from './components/CountBar'
import CountPie from './components/CountPie.vue'
import { getTermList, getStudentCountDay } from '@/api/count'
export default {
  data () {
    return {
      termList: [],
      param: {
        termId: ''
      },
      resData: {},
    }
  },
    computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getTermList()
  },
  methods: {
    getData () {
      getStudentCountDay(this.param).then(res => {
        this.resData = res.result;
      })
    },
    getTermList () {
      getTermList({ schoolId: this.school_id, page: 0, count: 100 }).then(res => {
        this.termList = res.result.items;
        this.param.termId = this.termList[0].id;
        this.getData()
      })
    }
  },
  components: {
    CountBar,
    CountPie
  }
}
</script>

<style lang="scss" scoped>
.count_data_box{
  width: 100%;
  height: 100%;
  padding: 20px;
  .search_box{
    width: 100%;
    padding: 20px 0;
  }
  .content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .count_list{
    width: calc(50% - 10px);
    margin-left: 20px;
    background: #fff;
    margin-bottom: 20px;
    padding-top: 10px;
    &:nth-child(2n+1) {
      margin-left: 0;
    }
    .count_title{
      width: 100%;
      height: 40px;
      margin: 0;
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      span:first-child{
        font-size: 16px;
        font-weight: 700;
      }
      span:nth-child(2){
        font-size: 14px;
      }
    }
  }
}
</style>