import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
// import { md5 } from '@/utils/md5'
import { Message, MessageBox } from 'element-ui'
// import ResizeHandler from '@/layout/mixin/ResizeHandler';

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    school_id: '',
    roles: [],
    school_name: '',
    school_logo: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ID: (state, id) => {
    state.school_id = id
  },
  SET_SCHOOL_NAME: (state, school_name) => {
    state.school_name = school_name
  },
  SET_SCHOOL_LOGO: (state, school_logo) => {
    state.school_logo = school_logo
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    console.log('lokdsflsjfsl')
    console.log(state.token, getToken())
    const { phone, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ phone: phone.trim(), password: password.trim(), token: state.token }).then(response => {
        resolve()
      }).catch(error => {
        if (error.err_code === 50024) {
          MessageBox.confirm('本账号已暂停使用，如需重新开启，请联系您的客户经理', '温馨提示', {
            confirmButtonText: '我知道了',
            type: 'warning'
          }).then(() => {})
        } else {
          Message({
            message: error.errorMsg || '遇到错误',
            type: 'error',
            duration: 5 * 1000
          })
        }
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const data = response.result

        if (!data) {
          reject('获取顾客信息失败，请重新登录')
        }

        // 暂时没有权限功能
        const roles = []
        roles.push(data.role)
        console.log(roles, 'userInfo')
        commit('SET_ROLES', roles)
        commit('SET_NAME', data.name)
        commit('SET_AVATAR', data.pic)
        if (data.school) {
          commit('SET_ID', data.school.id)
          commit('SET_SCHOOL_NAME', data.school.name)
          commit('SET_SCHOOL_LOGO', data.school.logo)
        }
        resolve({school: data.school, roles: roles})
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit }) {
    removeToken() // must remove  token  first
    resetRouter()
    commit('RESET_STATE')
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  },

  // admin login
  adminLogin({ commit }, token) {
    return new Promise(resolve => {
      removeToken()
      commit('RESET_STATE')
      commit('SET_TOKEN', token)
      setToken(token)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
