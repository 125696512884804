var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "school_box" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "联系方式", name: "1" } },
            [_c("FirstTab", { attrs: { schoolInfo: _vm.schoolInfo } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "学校简介", name: "2" } },
            [_c("SecondTab", { attrs: { schoolInfo: _vm.schoolInfo } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "注册协议", name: "3" } },
            [_c("ThirdTab", { attrs: { schoolInfo: _vm.schoolInfo } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "审核设置", name: "4" } },
            [_c("FourthTab", { attrs: { schoolInfo: _vm.schoolInfo } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "教务设置", name: "5" } },
            [_c("FifthTab", { attrs: { schoolInfo: _vm.schoolInfo } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }