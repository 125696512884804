<template>
  <div class="notice_box">
    <el-button type="primary" style="width: 104px; height: 32px;" @click="tapCreate">新建</el-button>
    <el-table
      v-loading="listLoading"
      :data="noticeList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        fixed
        prop="time"
        label="创建时间"
      />
      <el-table-column
        prop="title"
        label="标题"
      />
      <el-table-column
        prop="content"
        label="内容"
      />
      <el-table-column prop="enabled" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.enabled">已发布</span>
          <span v-else>已作废</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="tapDelNotice(scope.row)">作废</el-button>
            <el-button type="text" size="small" @click="tapEditNotice(scope.row)">修改</el-button>
          </template>
        </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" />

    <!-- 创建，编辑公告 -->
    <el-dialog :title="createParam.id ? '编辑消息' : '新建消息'" :visible.sync="showCreate" width="30%">
      <el-form :model="createParam" :rules="rules" ref="form" label-width="50px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="createParam.title"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" :rows="4" v-model="createParam.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreate = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Pagination from '@/components/Pagination'
import { getNoticeList, createNotice, editNotice, delNotice } from '@/api/notice'
export default {
  name: 'Notice',
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        count: 10
      },
      total: 0,
      noticeList: [],
      listLoading: true,
      showCreate: false,
      createParam: {
        id: '',
        title: '',
        enabled: null,
        content: '',
      },
      rules: {
        title: [
          { required: true, trigger: 'submit', message: '请输入标题' }
        ],
        content: [
          { required: true, trigger: 'submit', message: '请输入内容' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.listLoading = true;
      getNoticeList({ schoolId: this.school_id, ...this.param }).then(res => {
        this.listLoading = false;
        this.noticeList = res.result.items;
        this.total = res.result.total
      })
    },
    tapCreate () {
      for(let k in this.createParam) {
        this.createParam[k] = '';
      }
      this.showCreate = true;
    },
    tapConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if(this.createParam.id) {
            editNotice({ schoolId: this.school_id, id: this.createParam.id, title: this.createParam.title, content: this.createParam.content, enabled: this.createParam.enabled }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            })
          } else {
            createNotice({ schoolId: this.school_id, title: this.createParam.title, content: this.createParam.content }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            })
          }
        }
      })
    },
    tapDelNotice (row) {
      this.$confirm('确定要作废此条消息吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editNotice({ schoolId: this.school_id, id: row.id, title: row.title, content: row.content, enabled: false }).then(res => {
          this.$message.success('操作成功')
          this.getData()
        })
        // delNotice({ id: row.id }).then(res => {
        //   this.$message.success('删除成功')
        //   this.getData()
        // })
      }).catch(() => { });
    },
    tapEditNotice (item) {
      this.createParam.id = item.id;
      this.createParam.title = item.title;
      this.createParam.content = item.content;
      this.createParam.enabled = item.enabled;
      this.showCreate = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.notice_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}

</style>