<template>
  <div class="notice_box">
    <el-button type="primary" style="width: 104px; height: 32px;" @click="tapCreate">新建分发</el-button>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        fixed
        prop="term"
        label="学期"
      />
      <el-table-column
        prop="course"
        label="课程名称"
      />
      <el-table-column
        prop="copyright"
        label="来源"
      />
      <el-table-column
        prop="priceMode"
        label="计价方式"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.priceMode == 'AMOUNT' ? '按用户计价' : '一口价' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="课程售价"
      />
      <el-table-column
        prop="destSchoolCount"
        label="分享学校数量"
      />
      <el-table-column
        prop="signCount"
        label="报名用户数"
      />
      <el-table-column
        prop="destSchools"
        label="目标商户名称"
      />
      <el-table-column
          fixed="right"
          label="操作"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <!-- <el-button type="text" size="small" @click="tapStopItem(scope.row)">暂停</el-button> -->
            <el-button type="text" size="small" @click="tapEditItem(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="tapDelItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" />

    <!-- 创建，编辑公告 -->
    <el-dialog :title="createParam.id ? '编辑分发' : '新建分发'" :visible.sync="showCreate" width="30%">
      <el-form :model="createParam" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="选择课程" prop="courseId">
          <el-select class="width-100" v-model="createParam.courseId" placeholder="请选择" @change="getSchoolList">
            <el-option v-for="item in courseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="计价方式" prop="priceMode">
          <div class="dialog_radio_row"><el-radio v-model="createParam.priceMode" label="AMOUNT">按用户计价 <el-input v-model="createParam.price" placeholder="价格"></el-input></el-radio></div>
          <div class="dialog_radio_row"><el-radio v-model="createParam.priceMode" label="ONCE">一口价 <el-input v-model="createParam.price" placeholder="价格"></el-input></el-radio></div>
        </el-form-item> -->
        <el-form-item label="计价方式" prop="priceMode">
          <el-radio-group v-model="createParam.priceMode">
            <el-radio label="AMOUNT">按用户计价</el-radio>
            <el-radio label="ONCE">一口价</el-radio>
            <el-radio label="DIRECT">直销模式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="createParam.priceMode != 'DIRECT'" label="价格" prop="price">
          <el-input v-model="createParam.price" placeholder="请输入价格"></el-input>
        </el-form-item>
        <el-form-item label="共享对象" prop="schools">
          <el-select  class="width-100" v-model="createParam.schools" multiple collapse-tags placeholder="请选择">
            <el-option v-for="item in schoolList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreate = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/utils/auth'
import Pagination from '@/components/Pagination'
import { getShareList, courseShare, delCourseShare, courseShareUpdate, getCanCourses, getCanSchools } from '@/api/course-share'
export default {
  name: 'Notice',
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        count: 10
      },
      total: 0,
      dataList: [],
      courseList: [],
      schoolList: [],
      listLoading: true,
      showCreate: false,
      createParam: {
        id: '',
        priceMode: '',
        courseId: '',
        price: '',
        schools: [],
      },
      rules: {
        courseId: [
          { required: true, trigger: 'submit', message: '请选择课程' }
        ],
        priceMode: [
          { required: true, trigger: 'submit', message: '请选择计价方式' }
        ],
        price: [
          { required: true, trigger: 'submit', validator: (rule, value, cb) => {
            if(this.createParam.priceMode != 'DIRECT' && !this.createParam.price) {
              cb(new Error('请输入价格'))
              return false
            }
            cb()
          } }
        ],
        schools: [
          { required: true, trigger: 'submit', message: '请选择共享对象' }
        ]
      },
      token: getToken(),
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted () {
    this.getData()
    this.getOptions()
  },
  methods: {
    getOptions () {
      // termId 不指定默认为当前学期
      getCanCourses().then(res => {
        this.courseList = res.result;
      })
    },
    getSchoolList (val) {
      this.createParam.schools = []
      getCanSchools({courseId: val}).then(res => {
        this.schoolList = res.result;
      })
    },
    getData () {
      this.listLoading = true;
      getShareList({ schoolId: this.school_id, ...this.param }).then(res => {
        this.listLoading = false;
        this.dataList = res.result.items;
        this.total = res.result.total
      })
    },
    tapCreate () {
      for(let k in this.createParam) {
        this.createParam[k] = '';
      }
      this.showCreate = true;
    },
    tapConfirm () {
      this.$refs.form.validate(valid => {
        console.log(this.createParam)
        if (valid) {
          if(this.createParam.id) {
            courseShareUpdate({ ...this.createParam, token: this.token }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            })
          } else {
            let params = { ...this.createParam, token: this.token };
            delete params.id;
            courseShare(params).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            })
          }
        }
      })
    },
    tapDelItem (row) {
      this.$confirm('确定要删除此分发课程吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCourseShare({ id: row.id, token: this.token }).then(res => {
          this.$message.success('删除成功')
          this.getData()
        })
      }).catch(() => { });
    },
    tapEditItem (item) {
      this.createParam.id = item.id;
      this.createParam.priceMode = item.priceMode;
      this.createParam.courseId = item.courseId;
      this.createParam.price = item.price;
      this.createParam.schools = item.destSchools.split('、');
      this.showCreate = true;
    },
    tapStopItem () {

    }
  }
}
</script>

<style lang="scss" scoped>
.notice_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
 .dialog_radio_row{
   .el-input{
     width: 220px;
     margin-left: 20px;
   }
   margin-bottom: 10px;
   &:last-child{
     margin-bottom: 0;
   }
 }
</style>