import request from '@/utils/request'

// vip-card
export function getVIPCardGroupListApi(params) {
  return request({
    url: '/school/vipcard/grouplist',
    type: 'get',
    params
  }, true)
}

export function getVIPCardListApi(params) {
  return request({
    url: '/school/vipcard/list',
    type: 'get',
    params
  }, true)
}

export function postVIPCardDistApi(data) {
  return request({
    url: '/school/vipcard/dist',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }, true)
}

export function postVIPCardDisableApi(data) {
  return request({
    url: '/school/vipcard/disable',
    data,
  }, true)
}

// vip
export function getVIPListApi(params) {
  return request({
    url: '/school/vip/list',
    type: 'get',
    params
  }, true)
}

export function getVIPActivatedApi(params) {
  return request({
    url: '/school/vip/activelist',
    type: 'get',
    params
  }, true)
}