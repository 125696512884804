var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-course-container" },
    [
      _c(
        "div",
        {
          staticClass: "flex align-center",
          staticStyle: { padding: "24px 0" }
        },
        [
          _c("div", { staticClass: "margin-right-30" }, [
            _vm._v("当前大学：" + _vm._s(_vm.school_name))
          ])
        ]
      ),
      _vm._v(" "),
      _vm.curTerm.id
        ? _c("course-list", {
            ref: "mychild",
            attrs: { "term-id": _vm.curTerm.id }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }