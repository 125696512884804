import request from '@/utils/request'

export function getTermList(params) {
  return request({
    url: '/term_list',
    params
  })
}

export function getTermCount(data) {
  return request({
    url: '/school/statistics/business/term',
    params: data,
    type: 'get'
  })
}

export function getTermCountDay(data) {
  return request({
    url: '/school/statistics/business/term/day',
    params: data,
    type: 'get'
  })
}


export function getStudentCountDay(data) {
  return request({
    url: '/school/statistics/student/term',
    params: data,
    type: 'get'
  })
}




