<template>
  <div class="index_page">
    <!-- 常用操作 -->
    <div class="module_box">
      <p class="module_title">常用操作</p>
      <div class="module_flex_bet">
        <div class="term_amount">
          <p>{{baseData.today}}</p>
          <p>{{baseData.currentTermName}}</p>
          <el-divider></el-divider>
          <p class="module_flex_bet">
            <span>本学期学员</span>
            <span>{{baseData.currentTermStudentCount}}人</span>
          </p>
          <p class="module_flex_bet">
            <span>本学期课程</span>
            <span>{{baseData.currentTermCourseCount}}门</span>
          </p>
        </div>
        <div class="route_view">
          <div class="route_top" @click="tapJump('/academic/administrator')">
            <svg-icon className="avg_icon" iconClass="academic"></svg-icon>
            <p>班主任管理</p>
          </div>
          <div class="route_bom" @click="tapJump('/academic/administrator?create=true')">新建班主任</div>
        </div>
        <div class="route_view">
          <div class="route_top" @click="tapJump('/student-manager/white-list')">
            <svg-icon className="avg_icon" iconClass="student"></svg-icon>
            <p>白名单管理</p>
          </div>
          <div class="route_bom" @click="tapJump('/student-manager/white-list?create=true')">新建白名单</div>
        </div>
        <div class="route_view">
          <div class="route_top" @click="tapJump('/teacher-manager')">
            <svg-icon className="avg_icon" iconClass="teacher"></svg-icon>
            <p>老师管理</p>
          </div>
          <div class="route_bom" @click="tapJump('/teacher-manager?create=true')">新建老师</div>
        </div>
        <div class="route_view">
          <div class="route_top" @click="tapJump('/course-manager')">
            <svg-icon className="avg_icon" iconClass="course"></svg-icon>
            <p>课程管理</p>
          </div>
          <div class="route_bom" @click="tapJump('/course-create?schoolId=' + school_id)">新建课程</div>
        </div>
        <div class="route_view">
          <div class="route_top" @click="tapJump('/term-manager')">
            <svg-icon className="avg_icon" iconClass="term"></svg-icon>
            <p>学期管理</p>
          </div>
          <div class="route_bom" @click="tapJump('/term-manager?create=true')">新建学期</div>
        </div>
      </div>
    </div>
    <!-- 数据统计 -->
    <div class="module_box">
      <p class="module_title">数据统计</p>
      <div class="module_flex_bet">
        <div class="flex1 bold_text"></div>
        <!-- <div class="flex1 bold_text">注册用户</div> -->
        <div class="flex1 bold_text">报名学员</div>
        <div class="flex1 bold_text">报名次数</div>
        <div class="flex1 bold_text">收入金额</div>
        <!-- <div class="flex1 bold_text">学习次数</div> -->
      </div>
      <el-divider class="margin5"></el-divider>
      <div class="module_flex_bet">
        <div class="flex1">{{baseData.termCountData.termName}}</div>
        <!-- <div class="flex1 bold_text">{{baseData.termCountData.signStudentCount}}</div> -->
        <div class="flex1 bold_text">{{baseData.termCountData.signStudentCount}}</div>
        <div class="flex1 bold_text">{{baseData.termCountData.signCount}}</div>
        <div class="flex1 bold_text">{{baseData.termCountData.signFeeCount}}</div>
        <!-- <div class="flex1 bold_text">{{baseData.termCountData.viewCourseCount}}</div> -->
      </div>
      <template v-for="item in baseData.termData">
        <div class="module_flex_bet" :key="item.termId">
          <div class="flex1">{{item.termName}}</div>
          <!-- <div class="flex1">{{item.signStudentCount}}</div> -->
          <div class="flex1">{{item.signStudentCount}}</div>
          <div class="flex1">{{item.signCount}}</div>
          <div class="flex1">{{item.signFeeCount}}</div>
          <!-- <div class="flex1">{{item.viewCourseCount}}</div> -->
        </div>
      </template>
    </div>
    <!-- 今日课程 -->
    <div class="module_box">
      <p class="module_title">今日课程</p>
      <!-- <div class="module_flex_bet">
        <div class="quarter_view module_flex_bet border">
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.yesterdayViewStudentCount}}</p>
            <p>昨日观看人数</p>
          </div>
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.yesterdayViewCount}}</p>
            <p>昨日观看次数</p>
          </div>
        </div>
        <div class="quarter_view module_flex_bet border">
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.todayViewStudentCount}}</p>
            <p>今日观看人数</p>
          </div>
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.todayViewCount}}</p>
            <p>今日观看次数</p>
          </div>
        </div>
        <div class="quarter_view module_flex_bet border">
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.currentMonthViewStudentCount}}</p>
            <p>本月观看人数</p>
          </div>
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.currentMonthViewCount}}</p>
            <p>本月观看次数</p>
          </div>
        </div>
        <div class="quarter_view module_flex_bet border">
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.currentTermViewStudentCount}}</p>
            <p>本学期观看人数</p>
          </div>
          <div class="day_amount_view">
            <p class="bold_text">{{viewInfoData.currentTermViewCount}}</p>
            <p>本学期观看次数</p>
          </div>
        </div>
      </div> -->
      <div v-if="todayCourseData.length" class="module_flex_bet">
        <template v-for="item in todayCourseData">
          <div class="live_view border" :key="item.name">
            <img :src="item.cover" alt="">
            <div class="live_info">
              <div class="module_flex_bet">
                <div class="bold_text">
                  <p class="course_name">{{item.name}}</p>
                  <p>{{ item.status === 'ENDLIVE' ? '已结束' : item.status === 'UNLIVE' ? '未开始' : '直播中' }}</p>
                </div>
                <div class="bold_text">
                  <p>观看人数</p>
                  <p>{{item.studentCount}}</p>
                </div>
                <div class="bold_text">
                  <p>观看次数</p>
                  <p>{{item.viewCount}}</p>
                </div>
              </div>
              <el-divider></el-divider>
              <div class="module_flex_bet">
                <span>开始时间：{{item.startTime}}</span>
                <span>结束时间：{{item.endTime}}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <p v-else class="null_text">暂无课程</p>
    </div>
    <!-- 明日课程、白名单 -->
    <div class="module_flex_bet">
      <div class="module_box half_module">
        <p class="module_title">明日课程</p>
        <div class="module_flex_bet">
          <div class="header_img"></div>
          <div class="w100 bold_text">老师名称</div>
          <div class="w100 bold_text">老师手机号</div>
          <div class="flex1 bold_text">课程名称</div>
          <div class="flex1 bold_text">子课节名称</div>
          <div class="w100 bold_text">班主任</div>
        </div>
        <el-divider class="margin5"></el-divider>
        <template v-for="(item, index) in tomorrowData">
          <div class="module_flex_bet" :key="index">
            <div class="header_view"><el-avatar :src="item.teacherHead" style="width: 30px; height: 30px;" /></div>
            <div class="w100">{{item.teacherName}}</div>
            <div class="w100 bold_text">{{item.teacherPhone}}</div>
            <div class="flex1"><p>{{item.courseName}}</p></div>
            <div class="flex1"><p>{{item.lessonName}}</p></div>
            <div class="w100">{{item.classAdminName}}</div>
          </div>
        </template>
      </div>
      <div class="module_box half_module">
        <p class="module_title">白名单添加记录</p>
        <div class="module_flex_bet">
          <div class="flex1">添加时间</div>
          <div class="w100">白名单名称</div>
          <div class="w100">人数</div>
          <div class="flex1">课程范围</div>
        </div>
        <el-divider class="margin5"></el-divider>
        <template v-for="(item, index) in whiteListData">
          <div class="module_flex_bet" :key="index">
            <div class="flex1">{{item.createTime}}</div>
            <div class="w100">{{item.whitelistName}}</div>
            <div class="w100">{{item.studentCount}}</div>
            <div class="flex1">{{item.courseNames}}</div>
          </div>
        </template>
      </div>
    </div>
    <!-- 最新报名、最新缴费 -->
    <div class="module_flex_bet">
      <div class="module_box half_module">
        <p class="module_title">最新报名</p>
        <div class="module_flex_bet">
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signInfoData.yesterdaySignCount}}</p>
              <p>昨日人数</p>
            </div>
          </div>
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signInfoData.todaySignCount}}</p>
              <p>今日人数</p>
            </div>
          </div>
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signInfoData.currentMonthSignCount}}</p>
              <p>本月人数</p>
            </div>
          </div>
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signInfoData.preMonthSignCount}}</p>
              <p>上月人数</p>
            </div>
          </div>
        </div>
        <div class="module_flex_bet">
          <div class="flex1">报名时间</div>
          <div class="w100">用户姓名</div>
          <div class="w100">用户手机号</div>
          <div class="flex1">报名课程</div>
        </div>
        <el-divider class="margin5"></el-divider>
        <template v-for="(item, index) in signInfoData.signData">
          <div class="module_flex_bet" :key="index">
            <div class="flex1">{{item.signDate}}</div>
            <div class="w100">{{item.studentName}}</div>
            <div class="w100">{{item.studentPhone}}</div>
            <div class="flex1">{{item.courseName}}</div>
          </div>
        </template>
      </div>
      <div class="module_box half_module">
        <p class="module_title">最新缴费</p>
        <div class="module_flex_bet">
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signFeeInfoData.yesterdaySignFeeCount}}</p>
              <p>昨日人数</p>
            </div>
          </div>
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signFeeInfoData.todaySignFeeCount}}</p>
              <p>今日人数</p>
            </div>
          </div>
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signFeeInfoData.currentMonthSignFeeCount}}</p>
              <p>本月人数</p>
            </div>
          </div>
          <div class="module_flex_bet quarter_view">
            <div class="day_amount_view">
              <p class="bold_text">{{signFeeInfoData.preMonthSignFeeCount}}</p>
              <p>上月人数</p>
            </div>
          </div>
        </div>
        <CountBar :barData="signFeeInfoData.signFeeGroupByCourse"></CountBar>
      </div>
    </div>
    <!-- 分校信息 -->
    <div class="module_box" v-if="subSchoolData.termData && subSchoolData.termData.termId">
      <div class="module_title">
        <span>分校信息</span>
        <p class="right_view">
          学期: {{subSchoolData.termData.termName}}
          <!-- <el-select size="mini">
            <el-option></el-option>
          </el-select> -->
        </p>
      </div>
      <div class="module_flex_bet">
        <!-- <div class="flex1">{{subSchoolData.termData.signStudentCount}}</div> -->
        <div class="flex1">{{subSchoolData.termData.signStudentCount}}</div>
        <div class="flex1">{{subSchoolData.termData.signCount}}</div>
        <div class="flex1">{{subSchoolData.termData.signFeeCount}}</div>
        <!-- <div class="flex1">{{subSchoolData.termData.viewCourseCount}}</div> -->
      </div>
      <el-divider class="margin5"></el-divider>
      <div class="module_flex_bet">
        <!-- <div class="flex1">注册用户</div> -->
        <div class="flex1">报名学员</div>
        <div class="flex1">报名次数</div>
        <div class="flex1">收入金额</div>
        <!-- <div class="flex1">学习次数</div> -->
      </div>

      <p class="module_title size12">今日课程</p>
      <div v-if="subSchoolData.todayCourseData.length" class="module_flex_bet">
        <template v-for="(item, index) in subSchoolData.todayCourseData">
          <div class="live_view border" :key="index">
            <img :src="item.cover" alt="">
            <div class="live_info">
              <div class="module_flex_bet">
                <div class="bold_text">
                  <p class="course_name">{{item.name}}</p>
                  <p>{{item.status === 'ENDLIVE' ? '已结束' : item.status === 'UNLIVE' ? '未开始' : '直播中'}}</p>
                </div>
                <div class="bold_text">
                  <p>观看人数</p>
                  <p>{{item.studentCount}}</p>
                </div>
                <div class="bold_text">
                  <p>观看次数</p>
                  <p>{{item.viewCount}}</p>
                </div>
              </div>
              <el-divider></el-divider>
              <div class="module_flex_bet">
                <span>开始时间：{{item.startTime}}</span>
                <span>结束时间：{{item.endTime}}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <p v-else class="null_text">暂无课程</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CountBar from '../count/components/CountBar'
import {
  baseDataApi, todayCourseApi, tomorrowCourseApi, signInfoApi, signFeeInfoApi, subSchoolApi, viewInfoApi, whitelistApi
} from '@/api/index'
export default {
  data() {
    return {
      baseData: {
        termCountData: {}
      },
      todayCourseData: {},
      tomorrowData: [],
      signInfoData: {},
      signFeeInfoData: {},
      subSchoolData: {
        termData: {}
      },
      viewInfoData: {},
      whiteListData: {},
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      baseDataApi().then(res => {
        this.baseData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      todayCourseApi().then(res => {
        this.todayCourseData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      tomorrowCourseApi().then(res => {
        this.tomorrowData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      signInfoApi().then(res => {
        this.signInfoData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      signFeeInfoApi().then(res => {
        this.signFeeInfoData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      subSchoolApi().then(res => {
        this.subSchoolData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      viewInfoApi().then(res => {
        this.viewInfoData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      whitelistApi().then(res => {
        this.whiteListData = res.result;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
    },
    tapJump(path) {
      this.$router.push(path)
    }
  },
  filters: {
    liveStatusFilter (val, item) {
      if(val === 'UNLIVE') {
        let now = new Date();
        let [h, m] = [now.getHours(), now.getMinutes()];
        if(h <= item.runTimeHours && m < item.runTimeMins) {
          return '未开始'
        }
        return '已结束'
      }
      return '直播中'
    }
  },
  components: {
    CountBar
  }
}
</script>

<style lang="scss" scoped>
.index_page{
  width: 100%;
  padding: 24px;
  color: #606266;
  .module_box{
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 0 24px 24px;
    margin-bottom: 24px;
  }
  .half_module{
    flex: 1;
    &:nth-child(2n) {
      margin-left: 24px;
    }
  }
  .module_title{
    margin: 0;
    width: 100%;
    display: flex;
    line-height: 40px;
    font-weight: 700;
    justify-content: space-between;
    .right_view{
      height: 40px;
      margin: 0;
    }
  }
  .module_flex_bet{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .term_amount{
    flex: 2;
    padding: 0 12px;
    font-weight: 700;
    margin-right: 24px;
    border: 1px solid #DCDFE6;
    p{
      margin: 12px 0;
    }
    .el-divider{
      margin: 12px 0;
    }
  }
  .route_view{
    flex: 1.2;
    display: flex;
    margin-right: 24px;
    flex-direction: column;
    justify-content: space-between;
    &:last-child{
      margin-right: 0;
    }
    .route_top{
      flex: 1;
      display: flex;
      cursor: pointer;
      font-weight: 700;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #DCDFE6;
      .avg_icon{
        color: #FE9905;
        font-size: 50px;
      }
      p{
        margin: 5px 0 0;
      }
    }
    .route_bom{
      height: 40px;
      font-size: 14px;
      margin-top: 20px;
      cursor: pointer;
      line-height: 40px;
      text-align: center;
      border: 1px solid #DCDFE6;
    }
  }
  .w100{
    width: 100px;
    min-height: 40px;
    padding: 5px;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    p{
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .flex1{
    flex: 1;
    width: 100px;
    min-height: 40px;
    padding: 5px;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    p{
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .header_view{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header_img{
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 50%;
  }
  .bold_text{
    font-weight: 700;
  }
  .margin5{
    margin: 5px 0;
  }
  .quarter_view{
    flex: 1;
    margin-right: 24px;
    &:last-child{
      margin-right: 0;
    }
  }
  .day_amount_view{
    flex: 1;
    text-align: center;
  }
  .border{
    border: 1px solid #DCDFE6;
  }
  .size12{
    font-size: 12px;
    margin-bottom: -24px;
  }
  .live_view{
    width: 49%;
    padding: 12px;
    display: flex;
    margin-top: 24px;
    img{
      height: 80px;
      margin-right: 12px;
    }
    .live_info{
      flex: 1;
      height: 80px;
      display: flex;
      font-size: 12px;
      flex-direction: column;
      justify-content: space-between;
      p{
        margin: 0;
        text-align: center;
        &:last-child{
          margin-top: 10px;
        }
      }
      .el-divider{
        margin: 12px 0;
      }
      .course_name{
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .null_text{
    margin: 0;
    padding: 24px;
    text-align: center;
  }
}
</style>