<template>
  <div class="a-course-create">
    <div class="a-basic-info" style="margin-top: 24px;">
      <sub-title title="基本信息" class="margin-bottom-30" style="padding-top: 24px;" />
      <el-form :model="form" label-width="120px">
        <template v-if="edit">
          <el-form-item label="课程来源">
            <span>{{isOutsourcing ? '外采' : '自研'}}</span>
          </el-form-item>
        </template>
        <el-form-item label="归属学期">
          <el-select v-model="form.termId" placeholder="请选择">
            <el-option v-for="item in termList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程类型">
          <el-select v-model="form.lecture" placeholder="请选择">
            <el-option label="标准课程" :value="false"></el-option>
            <el-option label="讲座" :value="true"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程名称">
          <el-input
            v-model="form.name"
            type="text"
            maxlength="20"
            show-word-limit
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="课程一级类目">
          <el-select v-model="form.firstCateId" placeholder="请选择" @change="onChangeCate">
            <el-option
              v-for="item in firstCateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="课程二级类目">
          <el-select v-model="form.cateId" placeholder="请选择">
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="用户群组">
          <el-radio v-model="wxType" label="1">
            企业微信群
            <el-input
              v-model="form.chatId"
              type="text"
              class="b-input margin-left"
            />
          </el-radio>
          <div class="margin-top-10">
            <el-radio v-model="wxType" label="2">
              个人微信群
              <el-upload
                :action="uploadUrl"
                :data="dataObj"
                class="margin-left display-i-b"
                :on-change="onBeforeUploadWx"
                :on-success="onSuccessUploadWx"
                :show-file-list="false"
                :file-list="fileListWx">
                <el-image
                  class="a-pic-preview absolute-center"
                  :src="form.chatScancode"
                  :lazy="true"
                  fit="contain"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline" />
                  </div>
                </el-image>
              </el-upload>
            </el-radio>
          </div>
        </el-form-item>
        <el-form-item label="上课时间">
          <div class="flex align-center">
            <span class="text-666">每</span>
            <el-select v-model="form.weekDay" placeholder="请选择" style="width: 70px; margin-left: 8px;" :disabled="isOutsourcing">
              <el-option
                v-for="item in weekDay"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-time-picker
              v-model="form.time1"
              is-range
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="HH:mm"
              format="HH:mm"
              style="width: 316px; margin-left: 16px;"
              :disabled="isOutsourcing"
            />
          </div>
        </el-form-item>
        <el-form-item label="班主任">
          <el-select v-model="form.classAdminId" placeholder="请选择" style="width: 190px;" clearable>
            <el-option
              v-for="item in classTearchList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.courseNameOrigin" label="课程老师">
          <el-input
            v-model="form.teacher.name"
            type="text"
            style="width: 108px;"
            disabled
          />
        </el-form-item>
        <el-form-item v-else label="课程老师">
          <el-select v-model="form.teacherId" placeholder="请选择" style="width: 190px;" clearable>
            <el-option
              v-for="item in teachList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="this.priceList.length" label="课程价格" class="text-666">
          <template v-if="priceList.length">
            <template v-for="item in priceList">
              <div class="price_list" :key="item.priceId">
                <span>{{item.name}}</span>
                <el-input
                  v-model="item.value"
                  type="number"
                  style="width: 108px;"
                />
              </div>
            </template>
          </template>
        </el-form-item>
        <el-form-item v-else label="课程价格" class="text-666">
          <el-input
            v-model="form.price"
            type="number"
            style="width: 108px;"
          />
          <span style="margin-left:6px;">元</span>
          <span style="margin-left: 20px" v-if="form.courseNameOrigin">标准价格：{{form.priceOrigin}}</span>

          <div style="margin-left: 60px; display: inline-block" v-if="roles.includes('VIP_PERMISSION')">
            <span>会员价格</span>
            <el-input
              v-model="form.vipPrice"
              type="number"
              style="width: 108px;"
            />
            <span style="margin-left:6px;">元</span>
          </div>
        </el-form-item>
        <el-form-item label="报名人数上限" class="text-666">
          <el-input
            v-model="form.maxSignCount"
            type="number"
            style="width: 108px;"
          />
          <span style="margin-left:6px;">人</span>
          <!-- <span style="margin-left: 20px" v-if="form.courseNameOrigin">标准价格：{{form.priceOrigin}}</span> -->
        </el-form-item>
        <el-form-item label="课程图片">
          <el-upload
            v-model="form.cover"
            class="upload-demo"
            :action="uploadUrl"
            :data="dataObj"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :on-change="handleChangePic"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :show-file-list="false"
            list-type="picture"
            drag
          >
            <div>
              <el-button style="width: 120px; height: 36px;">上传图片</el-button>
              <span class="text-999 text-12" style="margin-left: 8px;">建议上传横版图片</span>
            </div>
            <el-image
              class="a-pic-preview absolute-center"
              :src="form.cover"
              fit="contain"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
          </el-upload>
        </el-form-item>
        <el-form-item label="课程介绍">
          <el-input
            v-model="form.introduce"
            type="textarea"
            placeholder="请输入内容"
            maxlength="500"
            show-word-limit
            :disabled="isInnerPage"
            style="width: 376px; min-height: 140px;"
          />
        </el-form-item>
        <el-form-item>
          <el-upload
            v-model="form.introducePic"
            class="upload-demo"
            :action="uploadUrl"
            :data="dataObj"
            :on-success="handleSuccess1"
            :on-remove="handleRemove"
            :on-change="handleChangePic"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :show-file-list="false"
            list-type="picture"
            :disabled="isInnerPage"
            drag
          >
            <div>
              <el-button :disabled="isInnerPage" style="width: 120px; height: 36px;">上传图片</el-button>
            </div>
            <el-image
              class="a-pic-preview absolute-center"
              :src="form.introducePic"
              fit="contain"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline" />
              </div>
            </el-image>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="精品课">
          <el-checkbox v-model="form.shared" :disabled="isOutsourcing">是否勾选为精品课</el-checkbox>
        </el-form-item> -->
        <el-form-item label="公开课" class="padding-bottom-30">
          <el-radio v-model="form.courseType" :label="0" :disabled="isInnerPage">公开直播</el-radio>
          <el-radio v-model="form.courseType" :label="1" :disabled="isInnerPage">私密直播</el-radio>
        </el-form-item>
      </el-form>
    </div>

    <div class="a-sub-course" style="margin-top: 16px; padding-bottom: 28px;">
      <sub-title title="子课程信息" class="margin-bottom-30" style="padding-top: 24px;" />
      <el-form label-width="80px" class="text-left">
        <el-form-item v-for="(lessonItem, index) in form.lessons" :key="lessonItem.id" :label="'课节' + (index + 1)" :value="lessonItem">
          <div class="a-sub-couse-item relative">
            <div class="flex align-center">
              <div class="text-666 margin-right-10">子课程名称</div>
              <el-input
                v-model="lessonItem.name"
                type="text"
                maxlength="50"
                show-word-limit
                style="width: 345px;"
                :disabled="isOutsourcing"
              />
            </div>
            <div class="flex align-center" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">课程时间</div>
              <el-date-picker
                v-model="lessonItem.time2"
                type="datetimerange"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                :disabled="isOutsourcing"
              />
            </div>
            <div class="flex align-center" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">直播类型</div>
              <el-radio v-model="lessonItem.liveType" :label="1" :disabled="isOutsourcing">推流直播</el-radio>
              <el-radio v-model="lessonItem.liveType" :label="0" :disabled="isOutsourcing">手机直播</el-radio>
              <el-radio v-if="lessonItem.id" v-model="lessonItem.liveType" :label="2" :disabled="isOutsourcing">本地上传</el-radio>
              <el-radio v-model="lessonItem.liveType" :label="3" :disabled="isOutsourcing">互动教室</el-radio>
            </div>
            <div v-show="!form.courseType && form.price > 0" class="flex align-center" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">是否免费</div>
              <el-radio v-model="lessonItem.free" :label="false" :disabled="isOutsourcing">收费</el-radio>
              <el-radio v-model="lessonItem.free" :label="true" :disabled="isOutsourcing">免费</el-radio>
            </div>
            <div v-if="edit" class="flex align-center" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">房间号</div>
              <div>{{ lessonItem.liveRoomId }}</div>
            </div>
            <div v-if="edit && lessonItem.liveType == 3" class="flex align-center" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">互动教室</div>
              <div>{{ interactiveRoomId }}</div>
            </div>
            <div v-if="edit && lessonItem.liveType == 3" class="flex align-center" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">互动链接</div>
              <div style="max-width: 400px;"><a target="_blank" :href="interactiveRoomUrl">{{ interactiveRoomUrl }}</a><span class="copy-btn" style="color:red;margin-left:10px;cursor:pointer;" v-clipboard:copy="interactiveRoomUrl" v-clipboard:success="onCopyOk" v-clipboard:error="onError">复制</span></div>
            </div>
            <div v-if="lessonItem.liveType == 1 || lessonItem.liveType == 3" class="flex" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">视频文件</div>
              <div class="video_view">
                <template v-if="lessonItem.videoUrl && lessonItem.videoUrl.liveUrls && lessonItem.videoUrl.liveUrls.length" >
                  <div class="flex flex-wrap">
                    <template v-for="(item, index) in lessonItem.videoUrl.liveUrls">
                      <div :key="item" class="flex" style="flex-direction: column;">
                        <img class="video_img" src="@/assets/video.jpg" alt="">
                        视频{{index + 1}}
                        <el-button type="primary" plain @click="tapDownloadVideo(lessonItem.name, index + 1, item)">下载视频</el-button>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="lessonItem.liveType == 2" class="flex" style="margin-top: 24px;">
              <div class="text-666" style="margin-right: 24px;">视频文件</div>
              <div class="video_view">
                <el-input
                v-model="lessonItem.url"
                type="text"
                style="width: 345px;"
                :disabled="isOutsourcing"
              />
                <div class="uploading" v-if="uploadTaskIds[String(lessonItem.id)]">
                  <p class="video_text">上传中...</p>
                  <el-progress :percentage="videoPercents[String(lessonItem.id)]"></el-progress>
                  <div class="flex mt10">
                    <el-button v-if="pauseUpload" type="primary" plain @click="tapKeepOnVideo(lessonItem.id)">继续上传</el-button>
                    <el-button v-else type="primary" plain @click="tapPauseVideo(lessonItem.id)">暂停上传</el-button>
                    <el-button type="primary" plain @click="tapCancelVideo(lessonItem.id)">取消上传</el-button>
                  </div>
                </div>
                <template v-else-if="lessonItem.url" >
                  <div class="flex flex-wrap">
                    <!-- <template v-for="item in lessonItem.url"> -->
                      <img class="video_img" src="@/assets/video.jpg" alt="">
                    <!-- </template> -->
                  </div>
                  <p class="video_text">提示：如果需要编辑直播视频，需要将全部片段下载，合成一个完整视频后再上传，用以替换原来的视频片段组。</p>
                  <div class="video_btns">
                    <input type="file" class="upload_input" accept="video/mp4" @change="onUploadVideo($event, lessonItem)" />
                    <el-button type="primary" plain @click="tapUpdateVideo(lessonItem)">重新上传</el-button>
                    <el-button type="primary" plain @click="tapDelVideo(lessonItem)">删除视频</el-button>
                    <el-button type="primary" plain @click="tapDownloadVideo(lessonItem.name, 1, lessonItem.url)">下载视频</el-button>
                  </div>
                </template>
                <div v-else class="flex align-end relative">
                  <span class="el-icon-plus upload_icon"></span>
                  <input type="file" class="upload_input" accept="video/mp4" @change="onUploadVideo($event, lessonItem)" />
                  <span class="upload_text">请上传mp4格式的视频文件</span>
                </div>
              </div>
            </div>
            <svg-icon v-if="!isOutsourcing" icon-class="close" class="a-close-btn clickable" style="width:22px;height:22px;" @click="lessonDel(index,lessonItem.id)" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button v-if="!isOutsourcing" type="primary" style="width: 118px; height: 32px;" @click="addTime()">添加子课程</el-button>
          <span class="text-999" style="margin-left: 6px;">注：课程时间是计划时间，实际直播中，课程教师将不受此时间限制</span>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin: 34px 0;">
      <el-button v-if="!edit" type="primary" class="a-button" @click="courseCreate">创建</el-button>
      <el-button v-else type="primary" class="a-button" @click="courseUpdate">修改</el-button>
      <el-button class="a-button" @click="backPage">取消</el-button>
    </div>
  </div>
</template>

<script>
var uploadCos = null;
import COS from 'cos-js-sdk-v5';
import { mapGetters } from 'vuex'
import SubTitle from '@/components/SubTitle/index'
import { getToken } from '@/utils/auth'
import { getEnv, openDownload } from '@/utils/index'
import { getCateList } from '@/api/cate'
import { getAdminList } from '@/api/administrator'
import { termList } from '@/api/course-manager'
import { courseCreate, teacherList, getCourse, courseUpdate, lessonDel, coursePriceList, getVideoConfigApi } from '@/api/course-manager'
export default {
  name: 'CourseCreate',
  components: {
    SubTitle
  },
  data() {
    return {
      uploadUrl: getEnv()[1] + 'file/upload/img',
      dataObj: { token: getToken() },
      wxType: '1',
      fileList: [],
      cateList: [],
      firstCateList: [],
      fileListWx: [],
      priceList: [],
      maxSize: false,
      weekDay: [{
        value: 1,
        label: '周一'
      }, {
        value: 2,
        label: '周二'
      }, {
        value: 3,
        label: '周三'
      }, {
        value: 4,
        label: '周四'
      }, {
        value: 5,
        label: '周五'
      }, {
        value: 6,
        label: '周六'
      }, {
        value: 7,
        label: '周日'
      }],
      form: {
        cover: '',
        termId: '',
        chatId: '',
        cateId: '',
        lecture: false,
        firstCateId: '',
        introducePic: '',
        chatScancode: '',
        lessons: [{ name: undefined, time2: undefined, liveType: 1, free: false }],
        courseType: 0
      },
      interactiveRoomId: 0,
      interactiveRoomUrl: '',
      listQuery: {
        page: 0,
        count: 100
      },
      teachList: [{}],
      edit: false,
      termList: [],
      classTearchList: [],
      pauseUpload: false,
      uploadTaskIds: {},
      videoPercents: {},
    }
  },
  created() {
    this.form.schoolId = this.$route.query.schoolId
    this.form.termId = this.$route.query.termId ? Number(this.$route.query.termId) : '';
    this.teacherList()

    this.getCateList(() => {
      if (this.$route.query.courseId) {
        this.edit = true
        this.getCourse()
        // this.getVideoConfig()
      }
    })

    this.getPriceList()
  },
  computed: {
    isOutsourcing () {
      return !!this.form.courseNameOrigin;
    },
    isInnerPage () {
      return !!this.form.courseNameOrigin && this.form.coopperationMode === 'INNER_PAGE';
    },
    ...mapGetters([
      'roles'
    ])
  },
  methods: {
    teacherList() {
      teacherList({ schoolId: this.form.schoolId, ...this.listQuery }).then(res => {
        this.teachList = res.result.items
      })
    },

    getCourse() {
      getCourse({ id: this.$route.query.courseId }).then(res => {
        this.form = this.handleData(res.result)

        this.initInteractiveRoom(this.form.schoolId, res.result.id)

        if (this.form.cateId) {
          for (let index = 0; index < this.firstCateList.length; index++) {
            const firstCate = this.firstCateList[index]

            if (firstCate.children) {
              for (let j = 0; j < firstCate.children.length; j++) {
                const cate = firstCate.children[j]
                if (cate.id === this.form.cateId) {
                  this.cateList = firstCate.children
                  this.$set(this.form, 'firstCateId', firstCate.id)
                }
              }
            }
          }          
        }
      })
    },

    getCateList (callback) {
      getCateList().then(res => {
        this.firstCateList = res.result;
        if (this.$route.query.courseId) {
          if (callback) {
            callback()
          }
        }
        
      })
    },

    getPriceList () {
      coursePriceList({ schoolId: this.form.schoolId, courseId: this.$route.query.courseId }).then(res => {
       this.priceList =  res.result == null ? [] : res.result ;
      })
      getAdminList({ page: 0, pageSize: 10000, role: 'CLASS_TEACHER' }).then(res => {
        this.classTearchList = res.result.content;
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
      termList({ page: 0, count: 10000, schoolId: this.form.schoolId }).then(res => {
        this.termList = res.result.items
      }).catch(res => {
        this.$message.error(res.errorMsg)
      });
    },

    onChangeCate (val) {
      let idx = this.firstCateList.findIndex(item => item.id == val);
      this.cateList = this.firstCateList[idx].children
      this.$set(this.form, 'cateId', this.cateList[0].id)
    },

    courseCreate() {
      if(!this.form.termId) {
        this.$message.error('请选择所属学期')
        return false;
      }
      if(this.form.lecture && !this.form.classAdminId) {
        this.$message.error('请选择班主任')
        return false;
      } else if(!this.form.lecture && !this.form.teacherId) {
        this.$message.error('请选择课程老师')
        return false;
      }
      courseCreate(this.handleForm(this.form)).then(res => {
        this.backPage()
      })
    },

    courseUpdate() {
      if(!this.form.termId) {
        this.$message.error('请选择所属学期')
        return false;
      }
      if(!this.form.cateId && this.form.firstCateId) {
        this.$message.error('请选择二级类目')
        return false;
      }
      if(this.form.lecture && !this.form.classAdminId) {
        this.$message.error('请选择班主任')
        return false;
      } else if(!this.form.lecture && !this.form.teacherId) {
        this.$message.error('请选择课程老师')
        return false;
      }
      courseUpdate(this.handleForm(this.form)).then(res => {
        this.backPage()
      })
    },

    handleData(obj) {
      const data = JSON.parse(JSON.stringify(obj))
      const arr = []
      arr.push((data.timeBegin || ''), (data.timeEnd || ''))
      data.time1 = arr
      data.lessons.forEach(item => {
        const arr1 = []
        arr1.push((item.timeBegin || ''), (item.timeEnd || ''))
        item.time2 = arr1
      })
      data.schoolId = this.$route.query.schoolId ? Number(this.$route.query.schoolId) : data.school ? data.school.id : '';
      data.termId = this.$route.query.termId ? Number(this.$route.query.termId) : data.term ? data.term.id : '';
      data.classAdminId = (data.classAdmin && data.classAdmin.id) ? data.classAdmin.id : '';
      this.wxType = obj.chatScancode ? '2' : '1';
      data.teacherId = data.teacher ? data.teacher.id : '';
      return data
    },

    handleForm(form) {
      const data = JSON.parse(JSON.stringify(form))
      if (data.time1) {
        data.timeBegin = data.time1[0]
        data.timeEnd = data.time1[1]
        delete data.time1
      }
      // data.schoolId = Number(this.$route.query.schoolId)
      // data.termId = Number(this.$route.query.termId)
      data.price = Number(data.price)
      data.courseType = Number(data.courseType)

      if(this.priceList.length) {
        data.customPrices = this.priceList.map(item => {
          return { priceId: item.priceId, value: item.value }
        })
      }

      if(this.wxType == '1') {
        data.chatScancode = ''
      } else {
        data.chatId = '';
      }

      if (data.lessons) {
        data.lessons.forEach(item => {
          if (item.time2) {
            item.timeBegin = item.time2[0]
            item.timeEnd = item.time2[1]
          }
          if (data.price <= 0 || data.courseType === 1) {
            delete item.free
          }
          delete item.time2
        })
      }
      return data
    },

    onBeforeUploadWx (file) {
      this.fileListWx = [file]
    },

    onSuccessUploadWx (res) {
      if(res.code == 0) {
        this.$set(this.form, 'chatScancode', res.result.url)
      } else {
        this.$message.error(res.errorMsg)
      }
      this.removeLock()
    },

    handleSuccess(response) {
      this.form.cover = response.result.url
      this.removeLock()
    },

    handleSuccess1(response) {
      this.form.introducePic = response.result.url
      this.removeLock()
    },

    // 上传图片前检测
    beforeUpload(file) {
      if (file.type === 'video/mp4') {
        this.$message.error('请上传正确图片格式')
        return false
      }
    },

    handleChangePic(file, fileList) {
      if (fileList.length > 1 && file.size < 500 * 1024) {
        fileList.splice(0, 1)
      }
    },

    handleRemove(file, fileList) {
      this.form.cover = ''
    },

    removeLock () {
      this.$nextTick(() => {
        document.body.style.overflow = 'auto'
      })
    },

    backPage() {
      // this.$router.go(-1)
      this.$message.success('保存成功')
      this.$router.push('/course-manager')
    },

    addTime() {
      this.form.lessons.push({
        name: '',
        time2: '',
        liveType: 1
      })
    },

    lessonDel(index, id) {
      if (id) {
        lessonDel({ id: id }).then(res => {
          this.form.lessons.splice(index, 1)
        })
      } else {
        this.form.lessons.splice(index, 1)
      }
    },

    onUploadVideo (e, item) {
      let _this = this;
      getVideoConfigApi({ lessonId: item.id }).then(res => {
        var credentials = res && res.result.credential.credentials;
        uploadCos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: res.result.credential.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: res.result.credential.expiredTime, // 时间戳，单位秒，如：1580000900
          });
          }
        });
        uploadCos.uploadFile({
            Bucket: res.result.bucket, /* 必须 */
            Region: res.result.region,     /* 存储桶所在地域，必须字段 */
            Key: res.result.fileKey,              /* 必须 */
            Body: e.target.files[0],                /* 必须 */
            SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
            onTaskReady: function(taskId) {                   /* 非必须 */
              console.log(taskId);
              _this.$set(_this.uploadTaskIds, item.id, taskId)
            },
            onProgress: function (progressData) {           /* 非必须 */
              console.log(JSON.stringify(progressData));
              if(_this.videoPercents[String(item.id)] > 0 && progressData.percent == 0) return;
              _this.$set(_this.videoPercents, item.id, Math.round(progressData.percent * 100))
            },
            onFileFinish: function (err, data, options) {
              console.log(options.Key + '上传' + (err ? '失败' : '完成'));
            },
        }, function(err, data) {
            console.log(err, data);
            _this.$set(_this.videoPercents, item.id, 0)
            _this.$set(_this.uploadTaskIds, item.id, null)
            if(data) _this.$set(item, 'url', res.result.fileUrl);
        });
      }).catch(res => {
        this.$message.error(res)
      });
    },
    tapPauseVideo (id) {
      this.pauseUpload = true;
      uploadCos.pauseTask(this.uploadTaskIds[id])
    },
    tapKeepOnVideo (id) {
      this.pauseUpload = false;
      uploadCos.restartTask(this.uploadTaskIds[id])
    },
    tapCancelVideo (id) {
      uploadCos.cancelTask(this.uploadTaskIds[id])
      this.pauseUpload = false;
      this.$set(this.videoPercents, id, 0)
      this.$set(this.uploadTaskIds, id, null)
    },
    tapUpdateVideo (item) {
      this.$confirm('直播视频如果上传以后，会代替原来的全部的视频片段组。', '温馨提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        document.querySelector('.upload_input').click()
      }).catch(() => { });
    },
    tapDelVideo (item) {
      this.$confirm('确定要删除视频吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$set(item, 'url', null)
      }).catch(() => { });
    },
    tapDownloadVideo (name, idx, url) {
      let filename = `${name}${idx}.mp4`;
      openDownload(url, filename)
    },
    initInteractiveRoom(schoolId, id){
      this.interactiveRoomId =  900000000 + schoolId * 100000 + id;
      this.interactiveRoomUrl = 'https://room.muketang.com/?userId=13888888888&username=班主任&roomId=' + this.interactiveRoomId + '&role=teacher'
    },
    onCopyOk(){
      alert("复制成功")
    }
  }
}
</script>

<style lang="scss" scoped>
  .a-course-create {
    margin: 0 24px;
  }

  .a-basic-info,.a-sub-course {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0 24px;
  }

  .a-input, .el-select {
    width: 364px;
  }

  .b-input{
    width: 240px;
  }

  .a-pic-preview {
    width: 240px;
    height: 140px;
    background: #F7F7F7;
    border: 1px solid #E7E7E7;
    margin-top: 16px;
  }

  .a-close-btn {
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .a-button {
    width: 132px;
    height: 32px;
  }

  /deep/ .el-upload-dragger {
    border: none !important;
    text-align: left !important;
    width: auto !important;
    height: auto !important;
  }

  /deep/ .el-textarea__inner {
    min-height: 140px !important;
  }

  .a-sub-couse-item {
    width: 656px;
    background: #F7F7F7;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    padding: 32px 40px;
  }

  /deep/ .el-form-item__label {
    text-align: left !important;
  }

  /deep/ .el-icon-picture-outline {
    font-size: 25px;
    color: #cccccc;
  }

  .price_list{
    display: inline-block;
    margin-right: 10px;
  }

  .video_view{
    flex: 1;
    .upload_icon{
      color: #606266;
      padding: 39px;
      font-size: 20px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #606266;
    }
    .upload_input{
      position: absolute;
      left: 0; top: 0;
      width: 100px;
      height: 100px;
      opacity: 0;
    }
    .upload_text{
      color: #606266;
      margin-left: 10px;
    }
    .video_img{
      width: 100px;
      margin-right: 10px;
    }
    .video_text{
      color: #999;
      line-height: 1.2;
      margin: 10px 0;
    }
    .video_btns{
      position: relative;
      .upload_input{
        width: 0;
        height: 0;
      }
    }
    a{
      line-height: 1.2;
      text-align: center;
    }
    .mb10{
      margin-bottom: 10px;
    }
    .mt10{
      margin-top: 10px;
    }
  }

</style>
