<template>
  <div class="a-container">
    <!-- <sub-title title="教师管理" /> -->
    <el-button class="margin-right-20 margin-bottom-20" type="primary" @click="goCourseCreate">新建教师</el-button>
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      style="width: 100%;"
    >
      <el-table-column
        label="头像名称"
      >
        <template slot-scope="scope">
          <div class="flex align-center">
            <el-avatar :src="scope.row.head" style="width: 30px; height: 30px;" />
            <span style="margin-left: 12px;">{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="教师手机号"
      />
      <el-table-column
        prop="wxNo"
        label="教师微信号"
      />
      <el-table-column
        prop="wxId"
        label="wxId"
      />
      <el-table-column
        prop="tag"
        label="教师标签"
      />
      <el-table-column
        prop="createTime"
        label="添加时间"
      />
      <el-table-column
        label="操作"
        align="center"
        width="400"
      >
        <template slot-scope="scope">
          <div class="flex flex-around">
            <el-popover trigger="click">
              <div style="position: relative;" class="goods-table-code">
                <img :src="qrcodeTeacher" class="qrcode" alt="">
                <div v-if="!qrcodeTeacher" class="qrcode-loading">加载中...</div>
              </div>
              <div slot="reference">
                <el-button type="text" size="small" @click="qrcodeTeacherBind(scope.row.id)">扫码绑定教师</el-button>
                <span>{{ scope.row.enabled ? '（已启用）' : '（未启用）' }}</span>
              </div>
            </el-popover>
            <el-button type="text" size="small" @click="goDetail(scope.row.id)">详情</el-button>
            <el-button type="text" size="small" @click="teacherDel(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="listQuery.page" :pagesize.sync="listQuery.count" @pagination="getList" />

    <!-- 老师详情 -->
    <el-dialog title="老师信息" :visible.sync="showDialog" width="1100px">
      <div class="teacher_create_dialog">
        <teacher-create :teacherId="editId" :show="showDialog"  @emitCancel="emitCancel"></teacher-create>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SubTitle from '@/components/SubTitle/index'
import { teacherList, teacherDel, qrcodeTeacherBind } from '@/api/course-manager'
import { mapGetters } from 'vuex'
import TeacherCreate from './TeacherCreate/index'
export default {
  name: 'TeacherList',
  components: {
    SubTitle,
    Pagination,
    TeacherCreate
  },
  data() {
    return {
      listQuery: {
        page: 0,
        count: 10
      },
      total: 0,
      listLoading: true,
      list: [],
      qrcodeTeacher: '',

      showDialog: false,
      editId: null,
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  created() {
    this.getList()
    if(this.$route.query.create) this.showDialog = true;
  },
  methods: {
    goCourseCreate() {
      // this.$router.push({
      //   name: 'TeacherCreate',
      //   query: {
      //     schoolId: this.school_id
      //   }
      // })
      this.editId = null;
      this.showDialog = true;
    },

    getList() {
      this.listLoading = true
      teacherList({ schoolId: this.school_id, ...this.listQuery }).then(res => {
        this.listLoading = false
        this.list = res.result.items
        this.total = res.result.total
      })
    },

    teacherDel(id) {
      teacherDel({ id: id }).then(res => {
        this.getList()
        this.$message.success('删除成功')
      })
    },

    goDetail(teacherId) {
      // this.$router.push({
      //   name: 'TeacherCreate',
      //   query: {
      //     teacherId: teacherId
      //   }
      // })
      this.editId = teacherId;
      this.showDialog = true;
    },

    qrcodeTeacherBind(id) {
      qrcodeTeacherBind({ id: id }).then(res => {
        this.qrcodeTeacher = 'https://course.muketang.com/' + res.result.image
        console.log(res)
      })
    },

    emitCancel (val) {
      this.showDialog = false;
      if(val) {
        this.listQuery.page = 0;
        this.getList() 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .goods-table-code {
    .qrcode {
      width: 150px;
      height: 150px;
    }

    .qrcode-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #999999;
    }
  }
  /deep/.teacher_create_dialog{
    max-height: 65vh;
    overflow-y: auto;
  }
</style>
