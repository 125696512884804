<template>
  <div class="chargeback_box">
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">学期：
        <el-select v-model="param.termId" placeholder="请选择" style="width: 150px;" @change="tapSearch">
          <el-option
            v-for="item in termList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">用户名称：
        <el-input v-model="param.studentName" placeholder="请输入姓名" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">用户手机号：
        <el-input v-model="param.phone" placeholder="请输入手机号" style="width: 150px;" @change="tapSearch"></el-input>
      </div>
      <div class="flex-center margin-right-30 margin-bottom-20">退款时间：
        <el-date-picker
          v-model="times"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="onChangeTime"
        >
        </el-date-picker>
      </div>
      <div>退款状态：
        <el-select v-model="param.refundStatus" placeholder="请选择" class="flex-center margin-right-30 margin-bottom-20" @change="tapSearch">
          <el-option label="全部" value="ALL" />
          <el-option label="退款成功" value="SUCCESS" />
          <el-option label="退款失败" value="FAILED" />
        </el-select>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapResetting">重置</el-button>
      <el-button class="margin-right-20 margin-bottom-20" style="width: 104px; height: 32px;" @click="tapSearch">查看</el-button>
    </div>
    <el-table v-loading="listLoading" :data="refundList" border highlight-current-row style="width: 1624px;" >
      <el-table-column fixed prop="time" label="退款时间" />
      <el-table-column prop="phone" label="用户手机号" />
      <el-table-column prop="studentName" label="用户姓名" />
      <el-table-column prop="termName" label="所属学期" />
      <el-table-column prop="courseName" label="课程名称" />
      <el-table-column prop="totalFee" label="实付金额" />
      <el-table-column prop="settlementRefundFee" label="退款金额" />
      <el-table-column prop="refundStatus" label="退款状态">
        <template slot-scope="scope">
          <span v-if="scope.row.refundStatus == 'SUCCESS'">退款成功</span>
          <span v-else-if="scope.row.refundStatus == 'FAILED'">退款失败</span>
        </template>
      </el-table-column>
      <el-table-column prop="refundNo" label="订单号" />
      <el-table-column prop="reason" label="备注" />
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Pagination from '@/components/Pagination'
import { getrefundList, getTermList } from '@/api/chargeback'
export default {
  name: 'PayOrder',
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        termId: '',
        page: 0,
        count: 10,
        studentName: '',
        phone: '',
        timeBegin: '',
        timeEnd: '',
        refundStatus: 'ALL',
      },
      times: [],
      termList: [],

      total: 0,
      refundList: [],
      listLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'school_id'
    ])
  },
  created() {
    Promise.all([
      this.getOptions()
    ]).then(res => {
      if(this.termList.length) {
        this.param.termId = this.termList[0].id;
        this.getData()
      } else {
        this.listLoading = false;
      }
    })
  },
  methods: {
    getData() {
      this.listLoading = true;
      let params = {};
      for(let k in this.param) {
        if(this.param[k] !== '') params[k] = this.param[k];
      }
      getrefundList({ schoolId: this.school_id, ...params }).then(res => {
        this.listLoading = false;
        this.refundList = res.result.items;
        this.total = res.result.total
      })
    },
    getOptions() {
      return new Promise((resolve, reject) => {
        getTermList({ schoolId: this.school_id, page: 0, count: 100  }).then(res => {
          this.termList = res.result.items
          resolve()
        })
      })
    },
    onChangeTime(val) {
      if(val) {
        this.param.timeBegin = val[0];
        this.param.timeEnd = val[1];
      }
      this.tapSearch()
    },
    tapSearch () {
      this.param.page = 0;
      this.getData()
    },
    tapResetting() {
      for (const k in this.param) {
        if (k !== 'page' && k !== 'count' && k!== 'termId') {
          this.param[k] = ''
        }
      }
      this.times = [];
      this.param.page = 0;
      this.param.refundStatus = 'ALL';
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.chargeback_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
</style>