import request from '@/utils/request'

export function getOrderList(params) {
  return request({
    url: '/order_list',
    params
  }, true)
}

export function getTermList(data) {
  return request({
    url: '/term_list',
    params: data
  })
}

export function refundOrder(data) {
  return request({
    url: '/refund',
    params: data
  })
}