import request from '@/utils/request'

export function getrefundList(params) {
  return request({
    url: '/refund_list',
    params
  }, true)
}

export function getTermList(data) {
  return request({
    url: '/term_list',
    params: data
  })
}