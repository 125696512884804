var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "a-course-create" }, [
    _c(
      "div",
      { staticClass: "a-basic-info", staticStyle: { "margin-top": "24px" } },
      [
        _c("sub-title", {
          staticClass: "margin-bottom-30",
          staticStyle: { "padding-top": "24px" },
          attrs: { title: "基本信息" }
        }),
        _vm._v(" "),
        _c(
          "el-form",
          { attrs: { model: _vm.form, "label-width": "120px" } },
          [
            _vm.edit
              ? [
                  _c("el-form-item", { attrs: { label: "课程来源" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.isOutsourcing ? "外采" : "自研"))
                    ])
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "归属学期" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.form.termId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "termId", $$v)
                      },
                      expression: "form.termId"
                    }
                  },
                  _vm._l(_vm.termList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "课程类型" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.form.lecture,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "lecture", $$v)
                      },
                      expression: "form.lecture"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "标准课程", value: false }
                    }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { label: "讲座", value: true } })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "课程名称" } },
              [
                _c("el-input", {
                  staticClass: "a-input",
                  attrs: {
                    type: "text",
                    maxlength: "20",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.form.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "课程一级类目" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    on: { change: _vm.onChangeCate },
                    model: {
                      value: _vm.form.firstCateId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "firstCateId", $$v)
                      },
                      expression: "form.firstCateId"
                    }
                  },
                  _vm._l(_vm.firstCateList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "课程二级类目" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.form.cateId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cateId", $$v)
                      },
                      expression: "form.cateId"
                    }
                  },
                  _vm._l(_vm.cateList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "用户群组" } },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: "1" },
                    model: {
                      value: _vm.wxType,
                      callback: function($$v) {
                        _vm.wxType = $$v
                      },
                      expression: "wxType"
                    }
                  },
                  [
                    _vm._v("\n          企业微信群\n          "),
                    _c("el-input", {
                      staticClass: "b-input margin-left",
                      attrs: { type: "text" },
                      model: {
                        value: _vm.form.chatId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "chatId", $$v)
                        },
                        expression: "form.chatId"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "margin-top-10" },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "2" },
                        model: {
                          value: _vm.wxType,
                          callback: function($$v) {
                            _vm.wxType = $$v
                          },
                          expression: "wxType"
                        }
                      },
                      [
                        _vm._v("\n            个人微信群\n            "),
                        _c(
                          "el-upload",
                          {
                            staticClass: "margin-left display-i-b",
                            attrs: {
                              action: _vm.uploadUrl,
                              data: _vm.dataObj,
                              "on-change": _vm.onBeforeUploadWx,
                              "on-success": _vm.onSuccessUploadWx,
                              "show-file-list": false,
                              "file-list": _vm.fileListWx
                            }
                          },
                          [
                            _c(
                              "el-image",
                              {
                                staticClass: "a-pic-preview absolute-center",
                                attrs: {
                                  src: _vm.form.chatScancode,
                                  lazy: true,
                                  fit: "contain"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-picture-outline"
                                    })
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "上课时间" } }, [
              _c(
                "div",
                { staticClass: "flex align-center" },
                [
                  _c("span", { staticClass: "text-666" }, [_vm._v("每")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "70px", "margin-left": "8px" },
                      attrs: {
                        placeholder: "请选择",
                        disabled: _vm.isOutsourcing
                      },
                      model: {
                        value: _vm.form.weekDay,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "weekDay", $$v)
                        },
                        expression: "form.weekDay"
                      }
                    },
                    _vm._l(_vm.weekDay, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-time-picker", {
                    staticStyle: { width: "316px", "margin-left": "16px" },
                    attrs: {
                      "is-range": "",
                      type: "datetimerange",
                      "range-separator": "~",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      disabled: _vm.isOutsourcing
                    },
                    model: {
                      value: _vm.form.time1,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "time1", $$v)
                      },
                      expression: "form.time1"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "班主任" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "190px" },
                    attrs: { placeholder: "请选择", clearable: "" },
                    model: {
                      value: _vm.form.classAdminId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "classAdminId", $$v)
                      },
                      expression: "form.classAdminId"
                    }
                  },
                  _vm._l(_vm.classTearchList, function(item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.nickName, value: item.id }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.form.courseNameOrigin
              ? _c(
                  "el-form-item",
                  { attrs: { label: "课程老师" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "108px" },
                      attrs: { type: "text", disabled: "" },
                      model: {
                        value: _vm.form.teacher.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form.teacher, "name", $$v)
                        },
                        expression: "form.teacher.name"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "el-form-item",
                  { attrs: { label: "课程老师" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "190px" },
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.form.teacherId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "teacherId", $$v)
                          },
                          expression: "form.teacherId"
                        }
                      },
                      _vm._l(_vm.teachList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
            _vm._v(" "),
            this.priceList.length
              ? _c(
                  "el-form-item",
                  { staticClass: "text-666", attrs: { label: "课程价格" } },
                  [
                    _vm.priceList.length
                      ? [
                          _vm._l(_vm.priceList, function(item) {
                            return [
                              _c(
                                "div",
                                {
                                  key: item.priceId,
                                  staticClass: "price_list"
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                  _vm._v(" "),
                                  _c("el-input", {
                                    staticStyle: { width: "108px" },
                                    attrs: { type: "number" },
                                    model: {
                                      value: item.value,
                                      callback: function($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _c(
                  "el-form-item",
                  { staticClass: "text-666", attrs: { label: "课程价格" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "108px" },
                      attrs: { type: "number" },
                      model: {
                        value: _vm.form.price,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "price", $$v)
                        },
                        expression: "form.price"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-left": "6px" } }, [
                      _vm._v("元")
                    ]),
                    _vm._v(" "),
                    _vm.form.courseNameOrigin
                      ? _c("span", { staticStyle: { "margin-left": "20px" } }, [
                          _vm._v("标准价格：" + _vm._s(_vm.form.priceOrigin))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.roles.includes("VIP_PERMISSION")
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "60px",
                              display: "inline-block"
                            }
                          },
                          [
                            _c("span", [_vm._v("会员价格")]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticStyle: { width: "108px" },
                              attrs: { type: "number" },
                              model: {
                                value: _vm.form.vipPrice,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "vipPrice", $$v)
                                },
                                expression: "form.vipPrice"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "6px" } },
                              [_vm._v("元")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "text-666", attrs: { label: "报名人数上限" } },
              [
                _c("el-input", {
                  staticStyle: { width: "108px" },
                  attrs: { type: "number" },
                  model: {
                    value: _vm.form.maxSignCount,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "maxSignCount", $$v)
                    },
                    expression: "form.maxSignCount"
                  }
                }),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "6px" } }, [
                  _vm._v("人")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "课程图片" } },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      action: _vm.uploadUrl,
                      data: _vm.dataObj,
                      "on-success": _vm.handleSuccess,
                      "on-remove": _vm.handleRemove,
                      "on-change": _vm.handleChangePic,
                      "file-list": _vm.fileList,
                      "before-upload": _vm.beforeUpload,
                      "show-file-list": false,
                      "list-type": "picture",
                      drag: ""
                    },
                    model: {
                      value: _vm.form.cover,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cover", $$v)
                      },
                      expression: "form.cover"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          { staticStyle: { width: "120px", height: "36px" } },
                          [_vm._v("上传图片")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "text-999 text-12",
                            staticStyle: { "margin-left": "8px" }
                          },
                          [_vm._v("建议上传横版图片")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-image",
                      {
                        staticClass: "a-pic-preview absolute-center",
                        attrs: { src: _vm.form.cover, fit: "contain" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error"
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "课程介绍" } },
              [
                _c("el-input", {
                  staticStyle: { width: "376px", "min-height": "140px" },
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入内容",
                    maxlength: "500",
                    "show-word-limit": "",
                    disabled: _vm.isInnerPage
                  },
                  model: {
                    value: _vm.form.introduce,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "introduce", $$v)
                    },
                    expression: "form.introduce"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      action: _vm.uploadUrl,
                      data: _vm.dataObj,
                      "on-success": _vm.handleSuccess1,
                      "on-remove": _vm.handleRemove,
                      "on-change": _vm.handleChangePic,
                      "file-list": _vm.fileList,
                      "before-upload": _vm.beforeUpload,
                      "show-file-list": false,
                      "list-type": "picture",
                      disabled: _vm.isInnerPage,
                      drag: ""
                    },
                    model: {
                      value: _vm.form.introducePic,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "introducePic", $$v)
                      },
                      expression: "form.introducePic"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "120px", height: "36px" },
                            attrs: { disabled: _vm.isInnerPage }
                          },
                          [_vm._v("上传图片")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-image",
                      {
                        staticClass: "a-pic-preview absolute-center",
                        attrs: { src: _vm.form.introducePic, fit: "contain" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error"
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticClass: "padding-bottom-30", attrs: { label: "公开课" } },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: 0, disabled: _vm.isInnerPage },
                    model: {
                      value: _vm.form.courseType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "courseType", $$v)
                      },
                      expression: "form.courseType"
                    }
                  },
                  [_vm._v("公开直播")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: 1, disabled: _vm.isInnerPage },
                    model: {
                      value: _vm.form.courseType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "courseType", $$v)
                      },
                      expression: "form.courseType"
                    }
                  },
                  [_vm._v("私密直播")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "a-sub-course",
        staticStyle: { "margin-top": "16px", "padding-bottom": "28px" }
      },
      [
        _c("sub-title", {
          staticClass: "margin-bottom-30",
          staticStyle: { "padding-top": "24px" },
          attrs: { title: "子课程信息" }
        }),
        _vm._v(" "),
        _c(
          "el-form",
          { staticClass: "text-left", attrs: { "label-width": "80px" } },
          [
            _vm._l(_vm.form.lessons, function(lessonItem, index) {
              return _c(
                "el-form-item",
                {
                  key: lessonItem.id,
                  attrs: { label: "课节" + (index + 1), value: lessonItem }
                },
                [
                  _c(
                    "div",
                    { staticClass: "a-sub-couse-item relative" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex align-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "text-666 margin-right-10" },
                            [_vm._v("子课程名称")]
                          ),
                          _vm._v(" "),
                          _c("el-input", {
                            staticStyle: { width: "345px" },
                            attrs: {
                              type: "text",
                              maxlength: "50",
                              "show-word-limit": "",
                              disabled: _vm.isOutsourcing
                            },
                            model: {
                              value: lessonItem.name,
                              callback: function($$v) {
                                _vm.$set(lessonItem, "name", $$v)
                              },
                              expression: "lessonItem.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex align-center",
                          staticStyle: { "margin-top": "24px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-666",
                              staticStyle: { "margin-right": "24px" }
                            },
                            [_vm._v("课程时间")]
                          ),
                          _vm._v(" "),
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": "~",
                              "start-placeholder": "开始时间",
                              "end-placeholder": "结束时间",
                              format: "yyyy-MM-dd HH:mm",
                              "value-format": "yyyy-MM-dd HH:mm",
                              disabled: _vm.isOutsourcing
                            },
                            model: {
                              value: lessonItem.time2,
                              callback: function($$v) {
                                _vm.$set(lessonItem, "time2", $$v)
                              },
                              expression: "lessonItem.time2"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "flex align-center",
                          staticStyle: { "margin-top": "24px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-666",
                              staticStyle: { "margin-right": "24px" }
                            },
                            [_vm._v("直播类型")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1, disabled: _vm.isOutsourcing },
                              model: {
                                value: lessonItem.liveType,
                                callback: function($$v) {
                                  _vm.$set(lessonItem, "liveType", $$v)
                                },
                                expression: "lessonItem.liveType"
                              }
                            },
                            [_vm._v("推流直播")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0, disabled: _vm.isOutsourcing },
                              model: {
                                value: lessonItem.liveType,
                                callback: function($$v) {
                                  _vm.$set(lessonItem, "liveType", $$v)
                                },
                                expression: "lessonItem.liveType"
                              }
                            },
                            [_vm._v("手机直播")]
                          ),
                          _vm._v(" "),
                          lessonItem.id
                            ? _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: 2,
                                    disabled: _vm.isOutsourcing
                                  },
                                  model: {
                                    value: lessonItem.liveType,
                                    callback: function($$v) {
                                      _vm.$set(lessonItem, "liveType", $$v)
                                    },
                                    expression: "lessonItem.liveType"
                                  }
                                },
                                [_vm._v("本地上传")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 3, disabled: _vm.isOutsourcing },
                              model: {
                                value: lessonItem.liveType,
                                callback: function($$v) {
                                  _vm.$set(lessonItem, "liveType", $$v)
                                },
                                expression: "lessonItem.liveType"
                              }
                            },
                            [_vm._v("互动教室")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.form.courseType && _vm.form.price > 0,
                              expression: "!form.courseType && form.price > 0"
                            }
                          ],
                          staticClass: "flex align-center",
                          staticStyle: { "margin-top": "24px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-666",
                              staticStyle: { "margin-right": "24px" }
                            },
                            [_vm._v("是否免费")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: false,
                                disabled: _vm.isOutsourcing
                              },
                              model: {
                                value: lessonItem.free,
                                callback: function($$v) {
                                  _vm.$set(lessonItem, "free", $$v)
                                },
                                expression: "lessonItem.free"
                              }
                            },
                            [_vm._v("收费")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: true,
                                disabled: _vm.isOutsourcing
                              },
                              model: {
                                value: lessonItem.free,
                                callback: function($$v) {
                                  _vm.$set(lessonItem, "free", $$v)
                                },
                                expression: "lessonItem.free"
                              }
                            },
                            [_vm._v("免费")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.edit
                        ? _c(
                            "div",
                            {
                              staticClass: "flex align-center",
                              staticStyle: { "margin-top": "24px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-666",
                                  staticStyle: { "margin-right": "24px" }
                                },
                                [_vm._v("房间号")]
                              ),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(lessonItem.liveRoomId))])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.edit && lessonItem.liveType == 3
                        ? _c(
                            "div",
                            {
                              staticClass: "flex align-center",
                              staticStyle: { "margin-top": "24px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-666",
                                  staticStyle: { "margin-right": "24px" }
                                },
                                [_vm._v("互动教室")]
                              ),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(_vm.interactiveRoomId))])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.edit && lessonItem.liveType == 3
                        ? _c(
                            "div",
                            {
                              staticClass: "flex align-center",
                              staticStyle: { "margin-top": "24px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-666",
                                  staticStyle: { "margin-right": "24px" }
                                },
                                [_vm._v("互动链接")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "max-width": "400px" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.interactiveRoomUrl
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.interactiveRoomUrl))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:copy",
                                          value: _vm.interactiveRoomUrl,
                                          expression: "interactiveRoomUrl",
                                          arg: "copy"
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:success",
                                          value: _vm.onCopyOk,
                                          expression: "onCopyOk",
                                          arg: "success"
                                        },
                                        {
                                          name: "clipboard",
                                          rawName: "v-clipboard:error",
                                          value: _vm.onError,
                                          expression: "onError",
                                          arg: "error"
                                        }
                                      ],
                                      staticClass: "copy-btn",
                                      staticStyle: {
                                        color: "red",
                                        "margin-left": "10px",
                                        cursor: "pointer"
                                      }
                                    },
                                    [_vm._v("复制")]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      lessonItem.liveType == 1 || lessonItem.liveType == 3
                        ? _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: { "margin-top": "24px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-666",
                                  staticStyle: { "margin-right": "24px" }
                                },
                                [_vm._v("视频文件")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "video_view" },
                                [
                                  lessonItem.videoUrl &&
                                  lessonItem.videoUrl.liveUrls &&
                                  lessonItem.videoUrl.liveUrls.length
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "flex flex-wrap" },
                                          [
                                            _vm._l(
                                              lessonItem.videoUrl.liveUrls,
                                              function(item, index) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      key: item,
                                                      staticClass: "flex",
                                                      staticStyle: {
                                                        "flex-direction":
                                                          "column"
                                                      }
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticClass:
                                                          "video_img",
                                                        attrs: {
                                                          src: require("@/assets/video.jpg"),
                                                          alt: ""
                                                        }
                                                      }),
                                                      _vm._v(
                                                        "\n                      视频" +
                                                          _vm._s(index + 1) +
                                                          "\n                      "
                                                      ),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "primary",
                                                            plain: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.tapDownloadVideo(
                                                                lessonItem.name,
                                                                index + 1,
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("下载视频")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      lessonItem.liveType == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: { "margin-top": "24px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-666",
                                  staticStyle: { "margin-right": "24px" }
                                },
                                [_vm._v("视频文件")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "video_view" },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "345px" },
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.isOutsourcing
                                    },
                                    model: {
                                      value: lessonItem.url,
                                      callback: function($$v) {
                                        _vm.$set(lessonItem, "url", $$v)
                                      },
                                      expression: "lessonItem.url"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.uploadTaskIds[String(lessonItem.id)]
                                    ? _c(
                                        "div",
                                        { staticClass: "uploading" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "video_text" },
                                            [_vm._v("上传中...")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-progress", {
                                            attrs: {
                                              percentage:
                                                _vm.videoPercents[
                                                  String(lessonItem.id)
                                                ]
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex mt10" },
                                            [
                                              _vm.pauseUpload
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        plain: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.tapKeepOnVideo(
                                                            lessonItem.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("继续上传")]
                                                  )
                                                : _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                        plain: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.tapPauseVideo(
                                                            lessonItem.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("暂停上传")]
                                                  ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    plain: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.tapCancelVideo(
                                                        lessonItem.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("取消上传")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : lessonItem.url
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "flex flex-wrap" },
                                          [
                                            _c("img", {
                                              staticClass: "video_img",
                                              attrs: {
                                                src: require("@/assets/video.jpg"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "video_text" }, [
                                          _vm._v(
                                            "提示：如果需要编辑直播视频，需要将全部片段下载，合成一个完整视频后再上传，用以替换原来的视频片段组。"
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "video_btns" },
                                          [
                                            _c("input", {
                                              staticClass: "upload_input",
                                              attrs: {
                                                type: "file",
                                                accept: "video/mp4"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.onUploadVideo(
                                                    $event,
                                                    lessonItem
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.tapUpdateVideo(
                                                      lessonItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("重新上传")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.tapDelVideo(
                                                      lessonItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("删除视频")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  plain: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.tapDownloadVideo(
                                                      lessonItem.name,
                                                      1,
                                                      lessonItem.url
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("下载视频")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "flex align-end relative"
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "el-icon-plus upload_icon"
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass: "upload_input",
                                            attrs: {
                                              type: "file",
                                              accept: "video/mp4"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.onUploadVideo(
                                                  $event,
                                                  lessonItem
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "upload_text" },
                                            [_vm._v("请上传mp4格式的视频文件")]
                                          )
                                        ]
                                      )
                                ],
                                2
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isOutsourcing
                        ? _c("svg-icon", {
                            staticClass: "a-close-btn clickable",
                            staticStyle: { width: "22px", height: "22px" },
                            attrs: { "icon-class": "close" },
                            on: {
                              click: function($event) {
                                return _vm.lessonDel(index, lessonItem.id)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                !_vm.isOutsourcing
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { width: "118px", height: "32px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.addTime()
                          }
                        }
                      },
                      [_vm._v("添加子课程")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "text-999",
                    staticStyle: { "margin-left": "6px" }
                  },
                  [
                    _vm._v(
                      "注：课程时间是计划时间，实际直播中，课程教师将不受此时间限制"
                    )
                  ]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { margin: "34px 0" } },
      [
        !_vm.edit
          ? _c(
              "el-button",
              {
                staticClass: "a-button",
                attrs: { type: "primary" },
                on: { click: _vm.courseCreate }
              },
              [_vm._v("创建")]
            )
          : _c(
              "el-button",
              {
                staticClass: "a-button",
                attrs: { type: "primary" },
                on: { click: _vm.courseUpdate }
              },
              [_vm._v("修改")]
            ),
        _vm._v(" "),
        _c(
          "el-button",
          { staticClass: "a-button", on: { click: _vm.backPage } },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }