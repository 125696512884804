<template>
  <div class="a-course-create">
    <!-- <div class="a-basic-info" style="margin-top: 24px;"> -->
      <!-- <sub-title title="教师信息" class="margin-bottom-30" style="padding-top: 24px;" /> -->
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <div class="half_row">
          <el-form-item label="教师姓名" prop="name">
            <el-input
              v-model="form.name"
              type="text"
              maxlength="20"
              show-word-limit
              class="a-input"
            />
          </el-form-item>
          <el-form-item label="担任科目">
            <el-input
              v-model="form.courseSubject"
              type="text"
              class="a-input"
            />
          </el-form-item>
        </div>
        <div class="half_row">
          <el-form-item label="身份证号">
            <el-input
              v-model="form.peopleId"
              type="text"
              class="a-input"
            />
          </el-form-item>
          <el-form-item label="毕业院校">
            <el-input
              v-model="form.graduateSchool"
              type="text"
              class="a-input"
            />
          </el-form-item>
        </div>
        <div class="half_row">
          <el-form-item label="教师手机号">
            <el-input
              v-model="form.phone"
              type="text"
              class="a-input"
            />
          </el-form-item>
          <el-form-item label="工作单位">
            <el-input
              v-model="form.organization"
              type="text"
              class="a-input"
            />
          </el-form-item>
        </div>
        <el-form-item label="教师微信号" prop="wxNo">
          <el-input
            v-model="form.wxNo"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="教师照片">
          <div class="flex">
            <el-upload
              v-model="form.body"
              class="upload-demo"
              :action="uploadUrl"
              :data="dataObj"
              :on-success="handleSuccess"
              :on-change="handleChangePic"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :show-file-list="false"
              list-type="picture"
              drag
            >
              <div>
                <el-button style="width: 120px; height: 36px;">上传图片</el-button>
                <span class="text-999 text-12" style="margin-left: 8px;">建议上传竖版半身照，推荐尺寸342*448px</span>
              </div>
              <!-- <div class="a-pic-preview" :style="{backgroundImage: `url(${form.body})`}" /> -->
              <el-image
                class="a-pic-preview"
                :src="form.body"
                fit="contain"
              />
            </el-upload>
            <div class="margin-right-20" style="margin-left: 130px; font-weight: 600;">教师头像</div>
            <el-upload
              v-model="form.head"
              class="upload-demo"
              :action="uploadUrl"
              :data="dataObj"
              :on-success="handleSuccess1"
              :on-change="handleChangePic"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :show-file-list="false"
              list-type="picture"
              drag
            >
              <div>
                <el-button style="width: 120px; height: 36px;">上传图片</el-button>
                <span class="text-999 text-12" style="margin-left: 8px;">请上传剪裁好的圆形照片</span>
              </div>
              <!-- <div class="a-pic-preview-avatar round" :style="{backgroundImage: `url(${form.head})`}" /> -->
              <el-image
                class="a-pic-preview-avatar round"
                :src="form.head"
                fit="contain"
              />
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="教师标签">
          <el-input
            v-model="form.tag"
            type="text"
            maxlength="10"
            show-word-limit
            class="a-input"
          />
          <span class="text-999 text-12" style="margin-left: 6px;">一句话简短介绍教师，例如“北师大文学院副教授”</span>
        </el-form-item>
        <el-form-item label="详细介绍">
          <el-input
            v-model="form.introduce"
            type="textarea"
            placeholder="请输入详细介绍"
            maxlength="500"
            show-word-limit
            style="width: 376px; min-height: 140px;"
          />
        </el-form-item>
        <el-form-item style="padding: 34px 0;">
          <el-button v-if="!edit" type="primary" class="a-button" @click="teacherCreate">创建</el-button>
          <el-button v-else type="primary" class="a-button" @click="teacherUpdate">编辑</el-button>
          <el-button class="a-button" @click="backPage">取消</el-button>
        </el-form-item>
      </el-form>
    <!-- </div> -->
  </div>
</template>

<script>
import SubTitle from '@/components/SubTitle/index'
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'
import { teacherCreate, teacherGet, teacherUpdate } from '@/api/course-manager'
export default {
  name: 'TeacherCreate',
  components: {
    SubTitle
  },
  props: {
    teacherId: Number || Object
  },
  data() {
    return {
      uploadUrl: getEnv()[1] + 'file/upload/img',
      dataObj: { token: getToken() },
      fileList: [],
      maxSize: false,
      form: {
        body: '',
        head: ''
      },
      edit: false,
      rules: {
        name: [
          { required: true, trigger: 'submit', message: '请填写教师姓名' }
        ],
        wxNo: [
          { required: true, trigger: 'submit', message: '请填写微信号' }
        ]
      }
    }
  },
  watch: {
    teacherId (val) {
      if(val) {
        this.edit = true
        this.teacherGet()
      } else {
        for(let k in this.form) {
          this.$set(this.form, k, '')
        }
      }
    },
    show (val) {
      if(val && !this.termId) {
        for(let k in this.form) {
          this.$set(this.form, k, '')
        }
      }
    }
  },
  mounted() {
    // this.form.schoolId = Number(this.$route.query.schoolId)
    if (this.teacherId) {
      this.edit = true
      this.teacherGet()
    }
  },
  methods: {
    teacherGet() {
      teacherGet({ id: this.teacherId }).then(res => {
        this.form = res.result
      })
    },

    teacherCreate() {
      this.$refs.form.validate(valid => {
        if (valid) {
          teacherCreate(this.form).then(res => {
            this.$message.success('保存成功')
            this.backPage(true)
          })
        }
      })
    },

    teacherUpdate() {
      this.$refs.form.validate(valid => {
        if (valid) {
          teacherUpdate(this.form).then(res => {
            this.$message.success('保存成功')
            this.backPage(true)
          })
        }
      })
    },

    handleSuccess(response) {
      this.form.body = response.result.url
    },

    handleSuccess1(response) {
      this.form.head = response.result.url
    },

    // 上传图片前检测
    beforeUpload(file) {
      const maxSize = 500
      if (file.type === 'video/mp4') {
        this.$message.error('请上传正确图片格式')
        return false
      }
      if (file.size > maxSize * 1024) {
        this.$message.error('上传图片大小不能超过500KB')
        return false
      }
    },

    handleChangePic(file, fileList) {
      if (fileList.length > 1 && file.size < 500 * 1024) {
        fileList.splice(0, 1)
      }
    },

    backPage(status) {
      // this.$router.go(-1)
      this.$emit('emitCancel', status)
    }
  }
}
</script>

<style lang="scss" scoped>
  .a-course-create {
    margin: 0 24px;
  }

  .a-basic-info {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0 24px;
  }

  .half_row{
    width: 100%;
    display: flex;
    .el-form-item{
      width: 569px;
    }
  }

  .a-input {
    width: 200px;
  }

  .a-pic-preview {
    width: 240px;
    height: 140px;
    background: #F7F7F7;
    border: 1px solid #E7E7E7;
    margin-top: 16px;
  }

  .a-close-btn {
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .a-button {
    width: 132px;
    height: 32px;
  }

  .a-pic-preview-avatar {
    width: 90px;
    height: 90px;
    background: #F7F7F7;
    border: 1px solid #E7E7E7;
    margin-top: 16px;
  }

  /deep/ .el-upload-dragger {
    border: none !important;
    text-align: left !important;
    width: auto !important;
    height: auto !important;
  }

  /deep/ .el-textarea__inner {
    min-height: 140px !important;
  }

  /deep/ .el-form-item__label {
    text-align: left !important;
    width: 100px !important;
  }
</style>
