import request from '@/utils/request'

export function getShareList(params) {
  return request({
    url: '/shared_course_list_school',
    params
  }, true)
}

export function courseShare(data) {
  return request({
    url: '/shared_course',
    params: {},
    data
  }, true)
}

export function courseShareUpdate(data) {
  return request({
    url: '/shared_course_update',
    params: {},
    data
  }, true)
}

export function delCourseShare(data) {
  return request({
    url: '/shared_course_delete',
    params: {},
    data
  }, true)
}

export function getCanCourses(params) {
  return request({
    url: '/can_share_courses',
    params
  }, true)
}

export function getCanSchools(params) {
  return request({
    url: '/can_share_to_schools',
    params
  }, true)
}