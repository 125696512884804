/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @param {number} val
 * @param {string} type
 * @returns {number}
 */
export function transMoney(val, type = '*') {
  if (type === '*') {
    return Math.round(val * 100)
  } else if (type === '/') {
    return parseFloat((val / 100).toFixed(2))
  }
}

/**
 * @param {string} val
 * @returns {string}
 */
export function filterEmoji(val) {
  const ranges = [
    '\ud83c[\udf00-\udfff]',
    '\ud83d[\udc00-\ude4f]',
    '\ud83d[\ude80-\udeff]'
  ]
  return val.replace(new RegExp(ranges.join('|'), 'g'), '')
}

/**
 * @param {number} val
 * @param {number} max
 * @returns {number}
 */
export function getMax(val, max) {
  return val > max ? max : val
}

export function timeTransform(time, detail = true) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  return d.getFullYear() + '-' +
    (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1) + '-' +
    (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) + ' ' +
    (detail ? ((d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + ':' +
      (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) + ':' +
      (d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds())) : '')
}

export function getUrlParams(url) {
  if (url.indexOf('?') === -1) {
    return {}
  }
  const params = url.slice(url.indexOf('?') + 1).split('&')
  const result = {}
  params.forEach(item => {
    result[item.split('=')[0]] = item.split('=')[1]
  })
  return result
}

export function timeToBm(time) {
  return Math.round(time.getTime() / 1000)
}

//下载文件 
export function openDownload(url, saveName) {
  if (typeof url == 'object' && url instanceof Blob) {
    url = URL.createObjectURL(url); // 创建blob地址
  }
  var aLink = document.createElement('a');
  aLink.href = url;
  aLink.target = '_blank';
  aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  var event;
  if (window.MouseEvent) event = new MouseEvent('click');
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }
  aLink.dispatchEvent(event);
}

export function getEnv() {
  return ['pro', process.env.VUE_APP_BASE_API]

  // const href = window.location.href
  // if (href.indexOf('localhost') > -1 || href.indexOf('192.168.100') !== -1) {
  //   return ['dev', 'http://localhost/']
  // } else if (href.indexOf('test.muketang') !== -1) {
  //   return ['dev', 'https://test.muketang.com/']
  // } else if (href.indexOf('partner2') !== -1) {
  //   return ['stage', 'https://course.muketang.com/']
  // } else {
  //   return ['pro', 'https://course.muketang.com/']
  // }
}

export function getUploadEnv() {
  return ['pro', process.env.VUE_APP_BASE_API]
  // const href = window.location.href
  // if (href.indexOf('localhost') > -1 || href.indexOf('192.168.100') !== -1) {
  //   return ['dev', 'https://txmtest.zidouchat.com/']
  // } else if (href.indexOf('partner2') !== -1) {
  //   return ['stage', 'https://txmtest.zidouchat.com/']
  // } else {
  //   return ['pro', 'https://txm.zidouchat.com/']
  // }
}
