import request from '@/utils/request'

export function getWhiteList(params) {
  return request({
    url: '/school/whitelist/group/list',
    params: params,
    type: 'get'
  }, true)
}

export function getWhiteInfo(params) {
  return request({
    url: '/school/whitelist/group/get',
    params: params,
    type: 'get'
  }, true)
}

export function createWhiteList(data) {
  return request({
    url: '/whitelist_create',
    params: {},
    data
  }, true)
}

export function statusWhiteList(params) {
  return request({
    url: '/whitelist_enable',
    params
  }, true)
}

export function updateWhiteList(data) {
  return request({
    url: '/whitelist_update',
    params: {},
    data
  }, true)
}

export function getWhiteListDetail(params) {
  return request({
    url: '/whitelist_detail',
    params
  }, true)
}

export function statusWhiteListDetail(params) {
  return request({
    url: '/whitelist_item_enable',
    params
  }, true)
}

export function getTermList(params) {
  return request({
    url: '/term_list',
    params
  })
}

export function getCourseList(params) {
  return request({
    url: '/simple_course_list',
    params
  })
}

export function addStudentWhiteList(data) {
  return request({
    url: '/whitelist_add_student',
    params: {},
    data
  }, true)
}