exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#FE9905",
	"menuHover": "rgba(255,255,255,0.2)",
	"subMenuBg": "rgba(0,0,0,0)",
	"subMenuHover": "rgba(255,255,255,0.2)",
	"sideBarWidth": "200px"
};