<template>
  <div class="school_fourth_box">
    <el-form :model="statusParam" label-width="150px">
      <el-form-item label="新注册学员需审核" prop="title">
        <el-switch v-model="statusParam.studentInfoVerify" @change="onSaveVerify"></el-switch>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div class="text_right">
      <el-button type="primary" style="height: 32px;" @click="editStatus = true">编辑资料</el-button>
    </div>
    <el-form :model="createParam" :rules="rules" ref="form" label-width="120px">
      <el-form-item label="审核提示" prop="title">
        <p class="text_val" v-if="!editStatus">hhh</p>
        <el-input v-else type="textarea" :rows="6" maxlength="120" show-word-limit v-model="createParam.title"></el-input>
      </el-form-item>
    </el-form>
    <div class="bom_btn" v-if="editStatus">
      <el-button @click="editStatus = false">取 消</el-button>
      <el-button type="primary" @click="tapConfirm">确 定</el-button>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { saveSchoolVerify } from '@/api/school-info'
export default {
  props: {
    schoolInfo: Object
  },
  watch: {
    schoolInfo (val) {
      this.statusParam.studentInfoVerify = val.studentInfoVerify;
    }
  },
  data () {
    return {
      editStatus: false,
      statusParam: {
        studentInfoVerify: false
      },
      createParam: {
        title: ''
      },
      rules: {}
    }
  },
  mounted () {

  },
  methods: {
    onSaveVerify () {
      saveSchoolVerify({ ...this.statusParam, token: getToken() }).then(res => {
        this.$parent.$parent.$parent.getData()
        this.$message.success('保存成功')
        this.editStatus = false;
      })
    }
  }
}
</script>

<style lang="scss">
.school_fourth_box{
  width: 100%;
  padding-top: 40px;
  .text_right{
    text-align: right;
    margin-bottom: 10px;
  }
  .el-textarea{
    width: 500px;
  }
  .text_val{
    margin: 0;
  }
  .bom_btn{
    width: 620px;
    text-align: center;
  }
}
</style>
